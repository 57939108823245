import React, { useEffect, useState } from 'react'

import { ModeEdit } from '@mui/icons-material'
import { Grid, IconButton, Stack, Typography } from '@mui/material'
import { useSelector } from 'react-redux'

import { isCreatedOverTwentyFiveMinutes } from '../../utils'

import ShippingAddressModal from './ShippingAddressModal'

type AddressProps = {
  title: string
  status?: string
  customer: any
  address: {
    name: string
    firstName: string
    lastName: string
    address1: string
    address2: string
    city: string
    zip: string
    province: string
    country: string
    countryCode: string
    provinceCode: string
    state: string
  }
}

const Address = ({ title, address, status, customer }: AddressProps) => {
  const [addressStatus, setStatus] = useState<string>(status || '')
  const [isModalVisible, setModalVisibility] = useState<boolean>(false)
  const order = useSelector((state: any) => state.order.value)

  useEffect(() => {
    if (!address.address1 && !address.city) {
      setStatus('No address provided')
    }
  }, [])

  const handleShippingAddressModalVisibility = () => {
    setModalVisibility(!isModalVisible)
  }

  return (
    <Grid container direction='column' justifyContent='flex-start' alignItems='flex-start'>
      <ShippingAddressModal
        isShown={isModalVisible}
        onClose={handleShippingAddressModalVisibility}
        customer={customer}
        createdAt={order._createdAt}
      />
      <Grid container direction='row'>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography variant='h2' fontSize={20} fontWeight={'bold'}>
            {title}
          </Typography>
          {title === 'Shipping address' &&
          order._fulfillmentStatus !== 'FULFILLED' &&
          !isCreatedOverTwentyFiveMinutes(order._createdAt) ? (
            <IconButton onClick={handleShippingAddressModalVisibility}>
              <ModeEdit />
            </IconButton>
          ) : null}
        </Stack>
      </Grid>
      {addressStatus ? (
        <Grid>{addressStatus}</Grid>
      ) : (
        <div>
          <Grid>{address.name}</Grid>
          <Grid>{address.address1}</Grid>
          {address.address2 && <Grid>{address.address2}</Grid>}
          <Grid>
            {address.city}, {address.provinceCode} {address.zip}
          </Grid>
        </div>
      )}
    </Grid>
  )
}

Address.defaultProps = {
  address: {},
}

export default Address
