import { useState } from 'react'

import { ContentCopy } from '@mui/icons-material'
import { IconButton, Snackbar } from '@mui/material'

interface IProps {
  copyText: string
}

const CopyToClipboardButton = ({ copyText }: IProps) => {
  const [open, setOpen] = useState<boolean>(false)

  const handleClick = () => {
    setOpen(true)
    navigator.clipboard.writeText(copyText)
  }

  return (
    <>
      <IconButton onClick={handleClick} color='primary' sx={{ padding: '4px' }}>
        <ContentCopy sx={{ fontSize: '1rem', padding: '0 0 0 4px' }} />
      </IconButton>
      <Snackbar
        message='Copied to clibboard'
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={2000}
        onClose={() => setOpen(false)}
        open={open}
      />
    </>
  )
}

export default CopyToClipboardButton
