import React from 'react'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Grid, Link, List, ListItem, Stack, Typography } from '@mui/material'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import { styled } from '@mui/material/styles'

type NotesProps = {
  note: string
  additionalNotes: []
  expanded?: boolean
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}))

const NotesStacked = ({ note, additionalNotes, expanded = true }: NotesProps) => {
  const additionalNoteValue = (key: string, value: string) => {
    return key === 'CSDuplicatedOrder' || key === 'CSDuplicatedParentOrder' ? (
      <Link href={`/orders/${value.split('- ')[0]}`} target='_blank' rel='noopener noreferrer'>
        {value}
      </Link>
    ) : (
      value
    )
  }

  const AdditionalNotes = () => {
    if (additionalNotes.length > 0) {
      return (
        <>
          <Typography variant='h2' fontSize={20} fontWeight={'bold'} marginTop={'10px'}>
            Additional Notes
          </Typography>
          <AdditionalNotesStack />
        </>
      )
    } else {
      return null
    }
  }

  const AdditionalNotesStack = () => {
    return (
      <Stack sx={{ maxHeight: 800, overflow: 'auto' }} direction='row' spacing={1} flexWrap='wrap'>
        <List>
          {additionalNotes.map((additionalNote: any) => (
            <ListItem
              key={additionalNote.key}
              style={{ display: 'block', paddingLeft: '4px', paddingRight: 0 }}
            >
              <Typography
                variant='h3'
                fontSize={15}
                fontWeight={'bold'}
                sx={{ marginTop: 0, marginBottom: 0 }}
              >
                {additionalNote.key}
              </Typography>
              <Typography
                variant='body1'
                fontSize={14}
                sx={{ overflowWrap: 'anywhere', marginTop: 0, marginBottom: '4px' }}
              >
                {additionalNoteValue(additionalNote.key, additionalNote.value)}
              </Typography>
            </ListItem>
          ))}
        </List>
      </Stack>
    )
  }

  const AdditionalAccordionNotes = () => {
    if (additionalNotes.length > 0) {
      return (
        <Accordion sx={{ padding: 0, border: 'none', margin: 0 }} elevation={0}>
          <AccordionSummary
            sx={{ padding: 0, margin: 0, marginTop: 0 }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls='additional-notes-content'
            id='additional-notes-header'
          >
            <Typography variant='h2' fontSize={20} fontWeight={'bold'} marginTop={'10px'}>
              Additional Notes
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <AdditionalNotesStack />
          </AccordionDetails>
        </Accordion>
      )
    } else {
      return null
    }
  }

  const ExpandedNotes = () => {
    return (
      <>
        <Typography variant='h2' fontSize={20} fontWeight={'bold'} marginBottom={'10px'}>
          Notes
        </Typography>
        <Stack sx={{ paddingLeft: '4px' }} direction='row' spacing={1} flexWrap='wrap'>
          {note ? note : 'No notes from customer'}
        </Stack>
        <AdditionalNotes />
      </>
    )
  }

  const AccordionNotes = () => {
    return (
      <>
        <Accordion sx={{ padding: 0, border: 'none', margin: 0 }} elevation={0}>
          <AccordionSummary
            sx={{ padding: 0, margin: 0, marginTop: 0 }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls='notes-content'
            id='notes-header'
          >
            <Typography variant='h2' fontSize={20} fontWeight={'bold'} marginBottom={'10px'}>
              Notes
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <Stack sx={{ paddingLeft: '4px' }} direction='row' spacing={1} flexWrap='wrap'>
              {note ? note : 'No notes from customer'}
            </Stack>
          </AccordionDetails>
        </Accordion>
        <AdditionalAccordionNotes />
      </>
    )
  }
  return (
    <Grid
      container
      direction='column'
      sx={{
        display: 'block',
        margin: 'auto',
      }}
    >
      {expanded ? <ExpandedNotes /> : <AccordionNotes />}
    </Grid>
  )
}

NotesStacked.defaultProps = {
  note: 'No notes from customer',
  additionalNotes: null,
}

export default NotesStacked
