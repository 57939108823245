import React, { useEffect, useState } from 'react'

import { LoadingButton } from '@mui/lab'
import { Box, Button, Grid, Modal, Typography } from '@mui/material'
import { fetchAuthSession } from 'aws-amplify/auth'

import { SalesOrder } from '../../models/SalesOrder'
import { Transaction } from '../../models/Transaction'
import { currencyFormatter } from '../../utils'

const style = {
  textAlign: 'center',
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
}

type Props = {
  isShown: boolean
  onClose: any
  order: SalesOrder
  customer: any
}

const CapturePaymentModal = ({ isShown, onClose, order, customer }: Props) => {
  const [open, setOpen] = useState<boolean>(isShown)
  const [header, setHeader] = useState<string>('Capture payment')
  const [loading, setLoading] = useState<boolean>(false)
  const [state, setState] = useState<'init' | 'error' | 'success'>('init')
  const [message, setMessage] = useState<string>('')
  const [totalPayment, setTotalPayment] = useState<number>(0)

  useEffect(() => {
    setOpen(isShown)
    order._transactions.map((transaction: Transaction) => {
      if (transaction.kind === 'AUTHORIZATION') {
        setTotalPayment(transaction.amount)
      }
    })
    if (!isShown) {
      initState()
    }
  }, [isShown, message, loading])

  const initState = () => {
    setState('init')
    setHeader('Capture payment')
    setMessage(`Are you sure you want to capture ${formattedPrice(totalPayment.toString())}?`)
  }

  const formattedPrice = (price: string) => {
    return order._currencyCode !== 'USD'
      ? currencyFormatter(customer, order).format(parseFloat(price)) + ' ' + order._currencyCode
      : currencyFormatter(customer, order).format(parseFloat(price))
  }
  const capturePayment = () => {
    setLoading(true)
    const requestPayload: {
      [key: string]: any
    } = {}
    requestPayload['order_id'] = order?._id
    requestPayload['amount'] = totalPayment
    requestPayload['currency'] = order?._currencyCode
    fetchAuthSession()
      .then((session) => {
        const accesToken = session.tokens?.accessToken?.toString()
        return fetch(`${process.env.REACT_APP_AIRBOSS_DOMAIN}/api/v2/transactions/user/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accesToken}`,
          },
          body: JSON.stringify(requestPayload),
        })
      })
      .then(async (response) => {
        if (response.status === 201) {
          setLoading(false)
          onClose()
          window.location.reload()
        } else {
          const data = await response.json()
          const hasDetail = Object.prototype.hasOwnProperty.call(data, 'detail')
          const hasCurrency = Object.prototype.hasOwnProperty.call(data, 'currency')
          const hasAmount = Object.prototype.hasOwnProperty.call(data, 'amount')
          setState('error')
          setHeader('Woops')
          if (hasAmount) {
            if (Array.isArray(data.amount)) {
              setMessage(data.amount[0])
            } else {
              setMessage(data.amount)
            }
          } else if (hasCurrency) {
            if (Array.isArray(data.currency)) {
              setMessage(data.currency[0])
            } else {
              setMessage(data.currency)
            }
          } else if (hasDetail) {
            setMessage(data.detail)
          } else {
            setMessage('We were unable to capture this payment, please refresh and try again.')
          }
          setLoading(false)
        }
      })
      .catch((err: any) => {
        console.error('error', err)
        setMessage('We were unable to capture this payment, please refresh and try again.')
        setHeader('Woops')
        setState('error')
        setLoading(false)
      })
  }

  const FormRow = () => {
    return (
      <React.Fragment>
        <Grid>
          <Typography variant='body1'>{message}</Typography>
        </Grid>
        <Grid container direction='row' justifyContent='center' alignItems='center'>
          <Grid item xs={6} paddingLeft={0}>
            <Button variant='contained' color='neutral' onClick={onClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6} paddingLeft={0}>
            <LoadingButton
              type='submit'
              color='primary'
              size='medium'
              loading={loading}
              variant='contained'
              disabled={loading}
              onClick={capturePayment}
              sx={{ mt: 3, mb: 2 }}
            >
              Confirm
            </LoadingButton>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }

  const CloseRow = () => {
    return (
      <React.Fragment>
        <Grid item paddingBottom={2}>
          <Typography variant='body1'>{message}</Typography>
        </Grid>
        <Grid item>
          <Button variant='contained' color='neutral' onClick={onClose}>
            Close
          </Button>
        </Grid>
      </React.Fragment>
    )
  }

  const ConditionalRow = () => {
    if (state === 'init') {
      return <FormRow />
    } else if (state === 'success') {
      return <SuccessRow />
    } else {
      return <CloseRow />
    }
  }

  const SuccessRow = () => {
    return (
      <React.Fragment>
        <Grid>
          <Typography variant='body1' paddingBottom={2}>
            Successfully capture payment
          </Typography>
        </Grid>
        <Grid item>
          <Button variant='contained' color='neutral' onClick={onClose}>
            Close
          </Button>
        </Grid>
      </React.Fragment>
    )
  }

  return (
    <Modal
      id='capture-payment-confirmation-modal'
      open={open}
      aria-labelledby='capture-payment-modal-title'
      aria-describedby='capture-payment-modal-description'
      onClose={onClose}
    >
      <Box sx={style} id='capture-payment-confirmation-box'>
        <Grid container direction='column' justifyContent='center' alignItems='center' spacing={2}>
          <Grid item>
            <Typography variant='h2' fontSize={'1.5rem'}>
              {header}
            </Typography>
          </Grid>
          <Grid item width={'100%'}>
            <Grid container direction='column' justifyContent='center' alignItems='center'>
              <ConditionalRow />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}

export default CapturePaymentModal
