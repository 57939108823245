/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'

import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { fetchAuthSession } from 'aws-amplify/auth'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { SalesOrder } from '../../models/SalesOrder'
import { formatPrice, isCreatedOverTwentyFiveMinutes } from '../../utils'

type Props = {
  isShown: boolean
  onClose: any
  order: SalesOrder
}

const CancelledOrderModal = ({ isShown, onClose, order }: Props) => {
  const { orderName } = useParams()
  const orderId = useSelector((state: any) => state.order.id)
  const customer = useSelector((state: any) => state.order.customer)
  const [open, setOpen] = useState<boolean>(isShown)
  const [loading, setLoading] = useState<boolean>(false)
  const [header, setHeader] = useState<string>(`Cancel order ${orderName}`)
  const [message, setMessage] = useState<string>(``)
  const [state, setState] = useState<'init' | 'error' | 'success'>('init')
  const [notifyCustomer, setNotifyCustomer] = useState<boolean>(true)
  const [refund, setRefund] = useState<boolean>(true)
  const [restock, setRestock] = useState<boolean>(false)
  const [staffNote, setStaffNote] = useState<string>('')
  const [reason, setReason] = useState<string>('The customer wanted to cancel the order')
  const currencyCode = order._currencyCode
  const availableReasons = [
    { value: 'Customer changed or canceled order', key: 'CUSTOMER' },
    { value: 'Payment declined', key: 'DECLINED' },
    { value: 'Fraudulent order', key: 'FRAUD' },
    { value: 'Items unavailable', key: 'INVENTORY' },
    { value: 'Other', key: 'OTHER' },
    { value: 'Staff error', key: 'STAFF' },
  ]

  useEffect(() => {
    setOpen(isShown)
    if (!isShown) {
      initState()
      setStaffNote('')
      setReason('Customer changed or canceled order')
    }
  }, [isShown, loading, message])

  // This hook prevents ResizeObserver loop completed with undelivered notifications due to LastPass
  useEffect(() => {
    function hideError(e: any) {
      if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div',
        )
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay')
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none')
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none')
        }
      }
    }

    window.addEventListener('error', hideError)
    return () => {
      window.addEventListener('error', hideError)
    }
  }, [])

  const initState = () => {
    setState('init')
    setHeader(`Cancel order ${orderName}`)
    setMessage(`Are you sure you want to cancel order ${orderName}?`)
  }
  const setUnknownErrorState = () => {
    setState('error')
    setHeader('Woops')
    setMessage(`Something went wrong, unable to cancel order ${orderName}`)
  }

  const cancelOrder = async () => {
    setLoading(true)
    if (!isCreatedOverTwentyFiveMinutes(order._createdAt)) {
      const requestPayload: {
        [key: string]: any
      } = {}
      requestPayload['notify_customer'] = notifyCustomer
      requestPayload['refund'] = refund
      requestPayload['restock'] = restock
      requestPayload['reason'] = availableReasons.find((tmpReason) => {
        return tmpReason.value === reason
      })?.key
      requestPayload['staff_note'] = staffNote
      try {
        const { tokens } = await fetchAuthSession()
        const accessToken = tokens?.accessToken.toString()
        await fetch(
          `${process.env.REACT_APP_AIRBOSS_DOMAIN}/api/v2/order/shopify/${orderId}/cancel/`,
          {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(requestPayload),
          },
        ).then(async (response) => {
          const data = await response.json()
          const hasNonFieldErrors = Object.prototype.hasOwnProperty.call(data, 'non_field_errors')
          if (response.status !== 200) {
            if (hasNonFieldErrors) {
              if (data.non_field_errors.length > 0) {
                setMessage(data.non_field_errors[0])
              } else {
                setMessage(data.non_field_errors)
              }
            } else {
              setUnknownErrorState()
            }
            setState('error')
            setHeader('Woops')
          } else if (response.status === 200) {
            setLoading(false)
            onClose()
            window.location.reload()
          }
          setLoading(false)
        })
      } catch (err) {
        setUnknownErrorState()
        setLoading(false)
      }
    } else {
      setState('error')
      setHeader('Woops')
      setMessage('The order cannot be cancelled after the first 25 minutes ')
      setLoading(false)
    }
  }

  const orderCancellationModalStyle = (tmpWidth: number) => {
    return {
      textAlign: 'center',
      position: 'absolute',
      top: '40%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: tmpWidth,
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
    }
  }

  const CloseRow = () => {
    return (
      <div>
        <Grid item paddingBottom={2}>
          <Typography variant='body1'>{message}</Typography>
        </Grid>
        <Grid item>
          <Button variant='contained' color='neutral' onClick={onClose}>
            Close
          </Button>
        </Grid>
      </div>
    )
  }

  const SuccessRow = () => {
    return <Typography variant='body1'>successfully cancelled the order</Typography>
  }

  return (
    <Modal
      id='order-cancellation-modal'
      open={open}
      aria-labelledby='order-cancellation-modal-title'
      aria-describedby='order-cancellation-modal-description'
      onClose={onClose}
    >
      <Box
        sx={orderCancellationModalStyle(state === 'init' ? 550 : 400)}
        id='order-cancellation-box'
      >
        <Grid container direction='column' justifyContent='center' alignItems='center' spacing={2}>
          <Grid item>
            <Typography variant='h2' fontSize={'1.5rem'}>
              {header}
            </Typography>
          </Grid>
          <Grid item width={'100%'}>
            <Grid container direction='column' justifyContent='center' alignItems='center'>
              {state === 'init' ? (
                <Grid
                  container
                  direction='row'
                  justifyContent='center'
                  alignItems='flex-start'
                  spacing={2}
                  sx={{
                    margin: '15px auto',
                    flexGrow: 1,
                  }}
                  padding={'auto'}
                >
                  <Grid
                    container
                    direction='row'
                    justifyContent='flex-start'
                    alignItems='flex-start'
                  >
                    <Grid>
                      <FormControl fullWidth sx={{ mb: 4, width: '400px' }}>
                        <Typography sx={{ marginRight: '230px' }}>
                          Reason for cancellation
                        </Typography>
                        <Select
                          sx={{ textAlign: 'left' }}
                          value={reason}
                          onChange={(event: any) => setReason(event.target.value)}
                        >
                          {availableReasons.map((reason) => (
                            <MenuItem key={reason.key} value={reason.value}>
                              {reason.value}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <TextField
                      data-lpignore='true'
                      autoComplete='off'
                      type='text'
                      variant='outlined'
                      color='secondary'
                      label='Staff note'
                      fullWidth
                      value={staffNote}
                      onChange={(e) => setStaffNote(e.target.value)}
                      sx={{ mb: 4, width: '400px' }}
                    />
                    <Grid
                      container
                      direction='column'
                      justifyContent='flex-start'
                      alignItems='flex-start'
                    >
                      <FormControlLabel
                        sx={{ fontWeight: 400 }}
                        control={
                          <Checkbox
                            onChange={(event) => setRefund(event.target.checked)}
                            value={refund}
                            defaultChecked
                          />
                        }
                        label={`Refund ${formatPrice(
                          parseFloat(order._netPaymentSet) > 0
                            ? order._netPaymentSet
                            : order._currentTotalPriceSet,
                          currencyCode,
                          customer,
                        )}`}
                      />
                      <FormControlLabel
                        sx={{ fontWeight: 400 }}
                        control={
                          <Checkbox
                            onChange={(event) => setRestock(event.target.checked)}
                            value={restock}
                          />
                        }
                        label='Restock inventory'
                      />
                      <FormControlLabel
                        sx={{ fontWeight: 400 }}
                        control={
                          <Checkbox
                            onChange={(event) => setNotifyCustomer(event.target.checked)}
                            value={notifyCustomer}
                            defaultChecked
                          />
                        }
                        label='Send notification to customer'
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction='row' justifyContent='center' alignItems='center'>
                    <Grid item xs={6} paddingLeft={0}>
                      <Button variant='contained' color='neutral' onClick={onClose}>
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item xs={6} paddingLeft={0}>
                      <LoadingButton
                        type='submit'
                        color='primary'
                        size='medium'
                        loading={loading}
                        variant='contained'
                        disabled={loading}
                        sx={{ mt: 3, mb: 2 }}
                        onClick={cancelOrder}
                      >
                        Confirm
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Grid>
              ) : state === 'success' ? (
                <SuccessRow />
              ) : (
                <CloseRow />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}

export default CancelledOrderModal
