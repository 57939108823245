/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'

import { LoadingButton } from '@mui/lab'
import { Box, Button, Grid, Modal, Stack, TextField, Typography } from '@mui/material'
import { fetchAuthSession } from 'aws-amplify/auth'
import { useSelector } from 'react-redux'

import { Customer } from '../../models/Customer'
import { isCreatedOverTwentyFiveMinutes } from '../../utils'

const initHeader = 'Edit shipping address'

type Props = {
  isShown: boolean
  onClose: any
  customer: Customer
  createdAt: string
}

const ShippingAddressModal = ({ isShown, onClose, customer, createdAt }: Props) => {
  const orderId = useSelector((state: any) => state.order.id)
  const [open, setOpen] = useState<boolean>(isShown)
  const [loading, setLoading] = useState<boolean>(false)
  const [header, setHeader] = useState<string>(initHeader)
  const [message, setMessage] = useState<string>(``)
  const [state, setState] = useState<'init' | 'error' | 'success'>('init')
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [address1, setAddress1] = useState<string>('')
  const [address2, setAddress2] = useState<string>('')

  useEffect(() => {
    setOpen(isShown)
    if (!isShown) {
      initState()
      setFirstName(customer._shippingAddress.firstName || '')
      setLastName(customer._shippingAddress.lastName || '')
      setEmail(customer._email || '')
      setAddress1(customer._shippingAddress.address1 || '')
      setAddress2(customer._shippingAddress.address2 || '')
    }
  }, [isShown, loading, message])

  // This hook prevents ResizeObserver loop completed with undelivered notifications due to LastPass
  useEffect(() => {
    function hideError(e: any) {
      if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div',
        )
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay')
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none')
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none')
        }
      }
    }

    window.addEventListener('error', hideError)
    return () => {
      window.addEventListener('error', hideError)
    }
  }, [])

  const initState = () => {
    setState('init')
    setHeader(initHeader)
    setMessage(`Are you sure you want to edit this shipping address?`)
  }
  const setUnknownErrorState = () => {
    setState('error')
    setHeader('Woops')
    setMessage('Something went wrong, unable to edit this shipping address')
  }

  const updateShippingAddress = async (requestPayload: any) => {
    setLoading(true)
    if (!isCreatedOverTwentyFiveMinutes(createdAt)) {
      try {
        const { tokens } = await fetchAuthSession()
        const accessToken = tokens?.accessToken.toString()
        await fetch(`${process.env.REACT_APP_AIRBOSS_DOMAIN}/api/v2/orders/${orderId}/`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(requestPayload),
        }).then(async (response) => {
          const data = await response.json()
          const hasShippingAddress = Object.prototype.hasOwnProperty.call(data, 'shipping_address')
          const hasNonFieldErrors = Object.prototype.hasOwnProperty.call(data, 'non_field_errors')
          if (response.status !== 200) {
            if (hasShippingAddress) {
              if (data.shipping_address.length > 0) {
                setMessage(data.shipping_address[0])
              } else {
                setMessage(data.shipping_address)
              }
            } else if (hasNonFieldErrors) {
              if (data.non_field_errors.length > 0) {
                setMessage(data.non_field_errors[0])
              } else {
                setMessage(data.non_field_errors)
              }
            } else {
              setUnknownErrorState()
            }
            setState('error')
            setHeader('Woops')
          } else if (response.status === 200) {
            setLoading(false)
            onClose()
            window.location.reload()
          }
          setLoading(false)
        })
      } catch (err) {
        setUnknownErrorState()
        setLoading(false)
      }
    } else {
      setState('error')
      setHeader('Woops')
      setMessage('Shipping address cannot be modified after the first 25 minutes ')
      setLoading(false)
    }
  }

  const shippingAddressModalStyle = (tmpWidth: number) => {
    return {
      textAlign: 'center',
      position: 'absolute',
      top: '40%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: tmpWidth,
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
    }
  }

  const CloseRow = () => {
    return (
      <div>
        <Grid item paddingBottom={2}>
          <Typography variant='body1'>{message}</Typography>
        </Grid>
        <Grid item>
          <Button variant='contained' color='neutral' onClick={onClose}>
            Close
          </Button>
        </Grid>
      </div>
    )
  }

  const SuccessRow = () => {
    return <Typography variant='body1'>successfully edited</Typography>
  }

  const handleSubmit = (event: any) => {
    event.preventDefault()
    const requestPayload: {
      [key: string]: any
    } = {}
    requestPayload['email'] = email
    requestPayload['shipping_address'] = {
      first_name: firstName,
      last_name: lastName,
      address1: address1,
    }
    if (address2 != null && address2 != '') {
      requestPayload['shipping_address'].address2 = address2
    }
    updateShippingAddress(requestPayload)
  }

  return (
    <Modal
      id='shipping-address-modal'
      open={open}
      aria-labelledby='shipping-address-modal-title'
      aria-describedby='shipping-address-modal-description'
      onClose={onClose}
    >
      <Box sx={shippingAddressModalStyle(state === 'init' ? 600 : 400)} id='shipping-address-box'>
        <Grid container direction='column' justifyContent='center' alignItems='center' spacing={2}>
          <Grid item>
            <Typography variant='h2' fontSize={'1.5rem'}>
              {header}
            </Typography>
          </Grid>
          <Grid item width={'100%'}>
            <Grid container direction='column' justifyContent='center' alignItems='center'>
              {state === 'init' ? (
                <Grid
                  container
                  direction='row'
                  justifyContent='center'
                  alignItems='flex-start'
                  spacing={2}
                  sx={{
                    margin: '15px auto',
                    flexGrow: 1,
                  }}
                  padding={'auto'}
                >
                  <Grid container direction='row' justifyContent='flex-start'>
                    <form style={{ width: '540px' }} autoComplete='off' onSubmit={handleSubmit}>
                      <Stack spacing={2} direction='row' sx={{ marginBottom: 4 }}>
                        <TextField
                          data-lpignore='true'
                          autoComplete='off'
                          type='text'
                          variant='outlined'
                          color='secondary'
                          label='First Name'
                          required
                          fullWidth
                          defaultValue={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                        <TextField
                          data-lpignore='true'
                          autoComplete='off'
                          type='text'
                          variant='outlined'
                          color='secondary'
                          label='Last Name'
                          fullWidth
                          required
                          defaultValue={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </Stack>
                      <TextField
                        data-lpignore='true'
                        autoComplete='off'
                        type='email'
                        variant='outlined'
                        color='secondary'
                        label='Email'
                        fullWidth
                        required
                        sx={{ mb: 4 }}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <TextField
                        data-lpignore='true'
                        autoComplete='off'
                        type='text'
                        variant='outlined'
                        color='secondary'
                        label='Address 1'
                        fullWidth
                        required
                        sx={{ mb: 4 }}
                        defaultValue={address1}
                        onChange={(e) => setAddress1(e.target.value)}
                      />
                      <TextField
                        data-lpignore='true'
                        autoComplete='off'
                        type='text'
                        variant='outlined'
                        color='secondary'
                        label='Address 2'
                        fullWidth
                        sx={{ mb: 4 }}
                        defaultValue={address2}
                        onChange={(e) => setAddress2(e.target.value)}
                      />
                      <Grid container direction='row' justifyContent='center' alignItems='center'>
                        <Grid item xs={6} paddingLeft={0}>
                          <Button variant='contained' color='neutral' onClick={onClose}>
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item xs={6} paddingLeft={0}>
                          <LoadingButton
                            type='submit'
                            color='primary'
                            size='medium'
                            loading={loading}
                            variant='contained'
                            sx={{ mt: 3, mb: 2 }}
                          >
                            Confirm
                          </LoadingButton>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                </Grid>
              ) : state === 'success' ? (
                <SuccessRow />
              ) : (
                <CloseRow />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}

export default ShippingAddressModal
