import React, { useState } from 'react'

import { KeyboardArrowRight, KeyboardArrowUp } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItem,
  Typography,
} from '@mui/material'

import { Transaction } from '../../models/Transaction'

type TransactionAccordionProps = {
  transaction: Transaction
  text2: string
}

const TransactionAccordion = ({ transaction, text2 }: TransactionAccordionProps) => {
  const [isOpened, setOpen] = useState<boolean>(false)
  return (
    <Accordion
      elevation={0}
      sx={{
        '&:before': { maxHeight: '20px' },
        maxHeight: !isOpened ? '20px' : 'auto',
        marginTop: !isOpened ? '-20px' : 'none',
      }}
      onChange={() => {
        setOpen(!isOpened)
      }}
    >
      <AccordionSummary
        expandIcon={isOpened ? <KeyboardArrowUp /> : <KeyboardArrowRight />}
        aria-controls='panel1-content'
        id='panel1-header'
        sx={{ width: 'fit-content', padding: 0, marginLeft: '15px' }}
      >
        <Typography
          sx={{
            fontWeight: 400,
            color: transaction.status.toLocaleLowerCase() !== 'success' ? 'red' : '',
            textTransform: 'capitalize',
          }}
        >
          {transaction.kind.toLocaleLowerCase() === 'refund' &&
          transaction.status.toLocaleLowerCase() !== 'success'
            ? `${text2} (${transaction.status.toLocaleLowerCase()})`
            : `${text2}`}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ marginTop: '-20px' }}>
        <Box sx={{ width: '100%', maxWidth: 400 }}>
          <List>
            {transaction.name && (
              <div>
                <ListItem
                  style={{
                    padding: '0px 0px  0px 4px',
                    width: '200px',
                  }}
                >
                  <Typography variant='body1' fontSize={16} sx={{ marginTop: 0, marginBottom: 0 }}>
                    <strong>Name on card</strong>: {transaction.name}
                  </Typography>
                </ListItem>
                <ListItem
                  style={{
                    padding: '0px 0px  0px 4px',
                    width: '300px',
                  }}
                >
                  <Typography variant='body1' fontSize={16} sx={{ marginTop: 0, marginBottom: 0 }}>
                    <strong>{transaction.paymentMethod}</strong>: {transaction.number}
                  </Typography>
                </ListItem>
              </div>
            )}
            {transaction.acquirerReferenceNumber && (
              <ListItem
                style={{
                  padding: '0px 0px  0px 4px',
                  width: '200px',
                }}
              >
                <Typography variant='body1' fontSize={16} sx={{ marginTop: 0, marginBottom: 0 }}>
                  <strong>ARN</strong>: {transaction.acquirerReferenceNumber}
                </Typography>
              </ListItem>
            )}
            <ListItem
              style={{
                padding: '0px 0px  0px 4px',
                width: '200px',
              }}
            >
              <Typography variant='body1' fontSize={16} sx={{ marginTop: 0, marginBottom: 0 }}>
                <strong>Gateway</strong>: {transaction.formattedGateway}
              </Typography>
            </ListItem>
            <ListItem
              style={{
                padding: '0px 0px  0px 4px',
                width: '200px',
              }}
            >
              <Typography
                variant='body1'
                fontSize={16}
                sx={{ marginTop: 0, marginBottom: 0, textTransform: 'capitalize' }}
              >
                <strong>Payments Status</strong>: {transaction.status.toLocaleLowerCase()}
              </Typography>
            </ListItem>
            {transaction.message && (
              <ListItem
                style={{
                  padding: '0px 0px  0px 4px',
                  width: '200px',
                }}
              >
                <Typography
                  variant='body1'
                  fontSize={16}
                  sx={{ marginTop: 0, marginBottom: 0, textTransform: 'capitalize' }}
                >
                  <strong>Message</strong>: {transaction.message}
                </Typography>
              </ListItem>
            )}
            <ListItem
              style={{
                padding: '0px 0px  0px 4px',
                width: '200px',
              }}
            >
              <Typography
                variant='body1'
                fontSize={16}
                sx={{ marginTop: 0, marginBottom: 0, textTransform: 'capitalize' }}
              >
                <strong>Type</strong>: {transaction.kind.toLocaleLowerCase()}
              </Typography>
            </ListItem>
            {transaction.authorizationCode && (
              <ListItem
                style={{
                  padding: '0px 0px  0px 4px',
                  width: '350px',
                }}
              >
                <Typography variant='body1' fontSize={16} sx={{ marginTop: 0, marginBottom: 0 }}>
                  <strong>Authorization</strong>: {transaction.authorizationCode}
                </Typography>
              </ListItem>
            )}
            <ListItem
              style={{
                padding: '0px 0px  0px 4px',
                width: '250px',
              }}
            >
              <Typography variant='body1' fontSize={16} sx={{ marginTop: 0, marginBottom: 0 }}>
                <strong>Payment ID</strong>: {transaction.paymentId}
              </Typography>
            </ListItem>
            <ListItem
              style={{
                padding: '0px 0px  0px 4px',
                width: '250px',
              }}
            >
              <Typography variant='body1' fontSize={16} sx={{ marginTop: 0, marginBottom: 0 }}>
                <strong>Created at</strong>:{' '}
                {transaction.createdAt
                  ? new Date(Date.parse(transaction.createdAt)).toLocaleTimeString('en-US', {
                      timeZone: 'America/Los_Angeles',
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                    })
                  : null}
              </Typography>
            </ListItem>
            {transaction.lineItems &&
              transaction.lineItems.map((lineItem: any, index: number) => (
                <ListItem
                  style={{
                    padding: '0px 0px  0px 4px',
                    width: '300px',
                  }}
                  key={lineItem}
                >
                  <Typography variant='body1' fontSize={16} sx={{ marginTop: 0, marginBottom: 0 }}>
                    {index === 0 ? <strong>Line items: </strong> : null} {lineItem}
                  </Typography>
                </ListItem>
              ))}
          </List>
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

export default TransactionAccordion
