import React from 'react'

import { Chip } from '@mui/material'

type BadgeProps = {
  colorStyle: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
  status: string
}

const GenericBadge = ({ status, colorStyle }: BadgeProps) => {
  return (
    <Chip
      color={colorStyle}
      label={status}
      size='small'
      sx={{
        padding: '0 5px',
      }}
    />
  )
}

GenericBadge.defaultProps = {
  colorStyle: 'default',
}

export default GenericBadge
