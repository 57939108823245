/* eslint-disable camelcase */
import React, { useState, useEffect, useRef } from 'react'

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { SalesItem } from '../../../models/SalesItem'
import { SalesOrder } from '../../../models/SalesOrder'
import { Transaction } from '../../../models/Transaction'
import { formatPrice, getTransactionLabel, isCreatedOverThreeMonths } from '../../../utils'
import Loading from '../../base/Loading'
import CustomerData from '../CustomerData'
import NotesStacked from '../NotesStacked'
import OrderActions from '../OrderActions'
import OrderItems from '../OrderItems'
import OrderMetaData from '../OrderMetaData'
import TagsStacked from '../TagsStacked'

import * as Constants from './constants'
import PercentOptions from './PercentOptions'
import RefundCard from './RefundCard'
import RefundOptions from './RefundOptions'
import RefundOrderModal from './RefundOrderModal'

const OrderRefundForm = () => {
  const { orderName } = useParams()
  const navigate = useNavigate()
  const [isRefundedOrderModalVisible, setRefundedOrderModalVisibility] = useState<boolean>(false)
  const [showLoading, setLoading] = useState<boolean>(true)
  const [showPercentSelect, setPercentSelectVisible] = useState<boolean>(false)
  const [selected, setSelected] = useState<boolean>(false)
  const [selectedUnfulfilledOrderItems, setSelectedUnfulfilledOrderItems] = useState<boolean>(false)
  const [selectedFulfilledOrderItems, setSelectedFulfilledOrderItems] = useState<boolean[]>([])
  const [notify, setNotify] = useState<boolean>(true)
  const [returnFee, setReturnFee] = useState<boolean>(false)
  const [shippingFee, setShippingFee] = useState<boolean>(false)
  const order = useSelector((state: any) => state.order.value)
  const customer = useSelector((state: any) => state.order.customer)
  const fulfilledOrderItems = useSelector((state: any) => state.order.fulfilledOrderItems)
  const unfulfilledOrderItems = useSelector((state: any) => state.order.unfulfilledOrderItems)
  const nameList = useSelector((state: any) => state.order.nameList)
  const trackingList = useSelector((state: any) => state.order.trackingList)
  const tmpOrderName = useSelector((state: any) => state.order.name)
  const [salesOrder, setSalesOrder] = useState<SalesOrder | null>(order)
  const [totalOfItems, setTotalOfItems] = useState<number>(0)
  const [subTotalItemPrice, setSubTotalItemPrice] = useState<number>(0)
  const [taxPrice, setTaxPrice] = useState<number>(0)
  const [percent, setPercent] = useState<number>(0)
  const [refundName, setRefund] = useState<string>('')
  const [tmpTransactions, setTransactions] = useState<Transaction[]>([])
  const tmpRefundValue = useRef(0)

  const countryCode =
    customer._shippingAddress.countryCode || customer._billingAddress.countryCode || null
  const currencyCode = order._currencyCode

  // Set the item quantity based on the unselected/selected all checkbox and set the state for the order object
  useEffect(() => {
    const tmpSelectedFulfilledOrderItems: boolean[] = []
    fulfilledOrderItems.map((object: SalesItem[]) => {
      object.map((itemsList: SalesItem) => {
        selected
          ? (itemsList.currentShopifyQuantity = itemsList.initialQuantity)
          : (itemsList.currentShopifyQuantity = 0)
      })
      tmpSelectedFulfilledOrderItems.push(false)
    })
    setSelectedFulfilledOrderItems([...tmpSelectedFulfilledOrderItems])
    unfulfilledOrderItems.map((object: SalesItem[]) => {
      object.map((itemsList: SalesItem) => {
        selected ? (itemsList.quantity = itemsList.initialQuantity) : (itemsList.quantity = 0)
      })
    })
    setSalesOrder(order)
    setLoading(false)
    const tmpArr: Transaction[] = []
    order._transactions
      .filter((transaction: Transaction) => {
        return (
          transaction.status === 'SUCCESS' &&
          (transaction.kind === 'SALE' || transaction.kind === 'CAPTURE')
        )
      })
      .map((transaction: Transaction) => {
        tmpArr.push(transaction)
      })
    setTransactions([...tmpArr])
  }, [])

  useEffect(() => {
    if (
      tmpOrderName !== orderName ||
      order._financialStatus === 'REFUNDED' ||
      (isCreatedOverThreeMonths(order._createdAt) && order._fulfillmentStatus === 'UNFULFILLED')
    ) {
      navigate(`/orders/${orderName}/`)
    }
  }, [location.pathname])

  useEffect(() => {
    disableButton()
    enableReturnFee()
  }, [refundName, tmpTransactions])

  const handleRefundOrderModalVisibility = () => {
    setRefundedOrderModalVisibility(!isRefundedOrderModalVisible)
  }

  const handleItemQuantitySelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelected(event.target.checked)
    setSelectedUnfulfilledOrderItems(event.target.checked)
    const tmpSelectedFulfilledOrderItems: boolean[] = [...selectedFulfilledOrderItems]
    fulfilledOrderItems.map((object: SalesItem[], idx: number) => {
      object.map((itemsList: SalesItem) => {
        event.target.checked
          ? (itemsList.currentShopifyQuantity =
              itemsList.returnQuantity > 0 ||
              itemsList.refundableQuantity > itemsList.initialQuantity
                ? itemsList.initialQuantity - itemsList.returnQuantity
                : itemsList.refundableQuantity)
          : (itemsList.currentShopifyQuantity = 0)
      })
      tmpSelectedFulfilledOrderItems[idx] = event.target.checked
    })
    setSelectedFulfilledOrderItems([...tmpSelectedFulfilledOrderItems])
    unfulfilledOrderItems.map((object: SalesItem[]) => {
      object.map((itemsList: SalesItem) => {
        event.target.checked
          ? (itemsList.quantity =
              itemsList.refundableQuantity < itemsList.initialQuantity
                ? itemsList.refundableQuantity
                : itemsList.initialQuantity)
          : (itemsList.quantity = 0)
      })
    })
  }

  const handleUnfulfilledItemQuantitySelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedUnfulfilledOrderItems(event.target.checked)
    unfulfilledOrderItems.map((object: SalesItem[]) => {
      object.map((itemsList: SalesItem) => {
        event.target.checked
          ? (itemsList.quantity =
              itemsList.refundableQuantity < itemsList.initialQuantity
                ? itemsList.refundableQuantity
                : itemsList.initialQuantity)
          : (itemsList.quantity = 0)
      })
    })
  }

  const handleFulfilledItemQuantitySelection = (
    event: React.ChangeEvent<HTMLInputElement>,
    idx: number,
  ) => {
    const tmpSelectedFulfilledOrderItems: boolean[] = [...selectedFulfilledOrderItems]
    fulfilledOrderItems[idx].map((itemsList: SalesItem) => {
      event.target.checked
        ? (itemsList.currentShopifyQuantity =
            itemsList.returnQuantity > 0 || itemsList.refundableQuantity > itemsList.initialQuantity
              ? itemsList.initialQuantity - itemsList.returnQuantity
              : itemsList.refundableQuantity)
        : (itemsList.currentShopifyQuantity = 0)
    })
    tmpSelectedFulfilledOrderItems[idx] = event.target.checked
    setSelectedFulfilledOrderItems([...tmpSelectedFulfilledOrderItems])
  }

  const disableButton = () => {
    return (
      refundName === '' ||
      getRefundAmount() === 0 ||
      (percent === 0 && refundName === Constants.CS_CUSTOMERCOUPON) ||
      (refundName === Constants.CS_PRICEADJUST &&
        getRefundAmount() > getRefundTotal(subTotalItemPrice) * 0.7) ||
      isInvalidInput()
    )
  }

  const isInvalidInput = () => {
    let overlimit = false
    tmpTransactions.some((transaction: Transaction) => {
      if (
        transaction.tmpAmount >
        Math.round((transaction.amount - transaction.refundableAmount) * 100) / 100
      ) {
        overlimit = true
      }
    })
    return overlimit
  }

  const isItemSelected = () => {
    let isSelectedItems = true
    fulfilledOrderItems.map((items: SalesItem[]) => {
      if (items.length > 0) {
        items.map((element: SalesItem) => {
          if (element.currentShopifyQuantity < element.initialQuantity) {
            isSelectedItems = false
          }
        })
      }
    })
    unfulfilledOrderItems.map((items: SalesItem[]) => {
      if (items.length > 0) {
        items.map((element: SalesItem) => {
          if (element.quantity < element.initialQuantity) {
            isSelectedItems = false
          }
        })
      }
    })
    return isSelectedItems
  }

  const getSelectedItems = () => {
    const tmpOrderItems: any[] = []
    fulfilledOrderItems.map((items: SalesItem[]) => {
      if (items.length > 0) {
        items.map((element: SalesItem) => {
          if (element.currentShopifyQuantity > 0) {
            tmpOrderItems.push({
              line_item_id: element.id,
              quantity: element.currentShopifyQuantity,
              restock_type: 'no_restock',
              srcImage: element.srcImage,
              sku: element.sku,
              price:
                parseFloat(element.discountedPrice).toFixed(2) !== '0.00'
                  ? parseFloat(element.tmpPrice) -
                    parseFloat(element.discountedPrice) /
                      (element.initialQuantity + element.nonFulfillableQuantity)
                  : element.tmpPrice,
              title: element.title,
              variantTitle: element.variantTitle,
            })
          }
        })
      }
    })
    unfulfilledOrderItems.map((items: SalesItem[]) => {
      if (items.length > 0) {
        items.map((element: SalesItem) => {
          if (element.quantity > 0) {
            tmpOrderItems.push({
              line_item_id: element.id,
              quantity: element.quantity,
              restock_type: 'no_restock',
              srcImage: element.srcImage,
              sku: element.sku,
              price:
                parseFloat(element.discountedPrice).toFixed(2) !== '0.00'
                  ? parseFloat(element.tmpPrice) -
                    parseFloat(element.discountedPrice) /
                      (element.initialQuantity + element.nonFulfillableQuantity)
                  : element.tmpPrice,
              title: element.title,
              variantTitle: element.variantTitle,
            })
          }
        })
      }
    })
    return tmpOrderItems
  }

  const getTotalOfItems = () => {
    let totalOftems = 0
    fulfilledOrderItems.map((items: SalesItem[]) => {
      if (items.length > 0) {
        items.map((element: SalesItem) => {
          totalOftems += element.initialQuantity
        })
      }
    })
    unfulfilledOrderItems.map((items: SalesItem[]) => {
      if (items.length > 0) {
        items.map((element: SalesItem) => {
          totalOftems += element.initialQuantity
        })
      }
    })
    return totalOftems
  }

  const getSubTotalPrice = (subTotalItem: number) => {
    if (refundName === Constants.CS_SHIPPING || refundName === Constants.CS_TAXES) {
      subTotalItem = 0
    } else if (isItemSelected() && refundName === Constants.CS_INVENTORY) {
      subTotalItem = order._currentSubtotalPriceSet
    }
    return subTotalItem.toString()
  }

  const getShippingPrice = () => {
    if (
      (shippingFee && enableShippingFee()) ||
      refundName === Constants.CS_SHIPPING ||
      refundName === Constants.CS_FRAUD ||
      (isItemSelected() && refundName == Constants.CS_ORDERCANCEL) ||
      (isItemSelected() && refundName === Constants.CS_INVENTORY) ||
      (isItemSelected() && refundName == Constants.CS_DAMAGED) ||
      (isItemSelected() && refundName == Constants.CS_LOSTPACKAGE) ||
      (isItemSelected() && refundName == Constants.CS_RTS)
    ) {
      return order._totalShippingPriceSet
    } else {
      return 0
    }
  }

  const getDiscountPrice = (total: number) => {
    let discountPrice = 0
    if (
      percent > 0 &&
      (refundName === Constants.CS_CUSTOMERCOUPON || refundName === Constants.CS_DAMAGED)
    ) {
      discountPrice = (total * percent) / 100
    } else if (refundName === Constants.CS_EMPLOYEECOUPON) {
      discountPrice = (total * 50) / 100
    }
    return discountPrice
  }

  const getTaxesPrice = () => {
    if (refundName === Constants.CS_SHIPPING) {
      let tmpTaxLines = 0
      if (order._shippingLine !== null) {
        order._shippingLine._taxLines.map((taxLine: any) => {
          tmpTaxLines += parseFloat(taxLine.priceSet)
        })
      }
      return tmpTaxLines
    } else if (
      refundName === Constants.CS_TAXES ||
      (isItemSelected() && refundName == Constants.CS_ORDERCANCEL) ||
      (isItemSelected() && refundName === Constants.CS_INVENTORY) ||
      (isItemSelected() && refundName == Constants.CS_DAMAGED) ||
      (isItemSelected() && refundName == Constants.CS_LOSTPACKAGE) ||
      (isItemSelected() && refundName == Constants.CS_RTS)
    ) {
      return order._currentTotalTaxSet
    } else {
      return taxPrice
    }
  }

  const getTotalOfSelectedItems = (totalOfItems: number) => {
    if (refundName === Constants.CS_TAXES || refundName === Constants.CS_SHIPPING) {
      totalOfItems = 0
    } else if (isItemSelected() && refundName === Constants.CS_INVENTORY) {
      totalOfItems = getTotalOfItems()
    }
    return totalOfItems
  }

  const getRefundTotal = (refundTotal: number) => {
    let tmpRefundTotal: number = 0
    if (
      percent > 0 &&
      (refundName === Constants.CS_CUSTOMERCOUPON || refundName === Constants.CS_DAMAGED)
    ) {
      tmpRefundTotal = getDiscountPrice(refundTotal + taxPrice)
    } else if (refundName === Constants.CS_TAXES) {
      tmpRefundTotal = order._currentTotalTaxSet
    } else if (refundName === Constants.CS_SHIPPING) {
      let tmpTaxLines = 0
      if (order._shippingLine !== null) {
        order._shippingLine._taxLines.map((taxLine: any) => {
          tmpTaxLines += parseFloat(taxLine.priceSet)
        })
      }
      tmpRefundTotal = parseFloat(order._totalShippingPriceSet) + tmpTaxLines
    } else if (
      (isItemSelected() && refundName == Constants.CS_ORDERCANCEL) ||
      (isItemSelected() && refundName === Constants.CS_INVENTORY) ||
      (isItemSelected() && refundName == Constants.CS_DAMAGED) ||
      (isItemSelected() && refundName == Constants.CS_LOSTPACKAGE) ||
      (isItemSelected() && refundName == Constants.CS_RTS)
    ) {
      tmpRefundTotal =
        parseFloat(order._netPaymentSet) > 0 ? order._netPaymentSet : order._currentTotalPriceSet
    } else if (refundName === Constants.CS_EMPLOYEECOUPON) {
      tmpRefundTotal = getDiscountPrice(refundTotal + taxPrice)
    } else if (refundName === Constants.CS_RETURNFEE) {
      tmpRefundTotal = Constants.RETURN_FEE
    } else {
      tmpRefundTotal = refundTotal + getTaxesPrice()
    }
    if (returnFee) {
      tmpRefundTotal -= Constants.RETURN_FEE
    }
    if (shippingFee && enableShippingFee()) {
      let tmpTaxLines = 0
      if (order._shippingLine !== null) {
        order._shippingLine._taxLines.map((taxLine: any) => {
          tmpTaxLines += parseFloat(taxLine.priceSet)
        })
      }
      tmpRefundTotal += parseFloat(order._totalShippingPriceSet) + tmpTaxLines
    }
    // This condition uses net payments to limit the amount refunded in paid orders.
    //  Avoid refund limits in authorized orders.
    if (
      parseFloat(order._netPaymentSet) < tmpRefundTotal &&
      order._financialStatus !== 'AUTHORIZED'
    ) {
      tmpRefundTotal =
        parseFloat(order._netPaymentSet) > 0 ? order._netPaymentSet : order._currentTotalPriceSet
    }
    tmpRefundValue.current = Math.round(tmpRefundTotal * 100) / 100
    return tmpRefundValue.current
  }

  const getItemTax = (taxRates: number[], tmpPrice: number, quantity: number) => {
    let taxPrice: number = 0
    taxRates.map((taxRate: number) => {
      taxPrice += Math.round(taxRate * tmpPrice * quantity * 100) / 100
    })
    return Math.round(taxPrice * 100) / 100
  }

  const handleItemQuantity = () => {
    let tmpTotalOfItems = 0
    let tmpSubTotalItem = 0
    let tmpTaxPrice = 0
    fulfilledOrderItems.map((object: []) => {
      object.map((itemsList: SalesItem) => {
        const price =
          parseFloat(itemsList.discountedPrice).toFixed(2) !== '0.00'
            ? parseFloat(itemsList.tmpPrice) -
              parseFloat(itemsList.discountedPrice) /
                (itemsList.initialQuantity + itemsList.nonFulfillableQuantity)
            : parseFloat(itemsList.tmpPrice)
        const tmpPrice = price === -Infinity || price === Infinity ? 0 : price
        tmpTotalOfItems += itemsList.currentShopifyQuantity
        tmpSubTotalItem += itemsList.currentShopifyQuantity * tmpPrice
        tmpTaxPrice += getItemTax(itemsList.taxRates, tmpPrice, itemsList.currentShopifyQuantity)
      })
    })
    unfulfilledOrderItems.map((object: []) => {
      object.map((itemsList: SalesItem) => {
        const price =
          parseFloat(itemsList.discountedPrice).toFixed(2) !== '0.00'
            ? parseFloat(itemsList.tmpPrice) -
              parseFloat(itemsList.discountedPrice) /
                (itemsList.initialQuantity + itemsList.nonFulfillableQuantity)
            : parseFloat(itemsList.tmpPrice)
        const tmpPrice = price === -Infinity ? 0 : price
        tmpTotalOfItems += itemsList.quantity
        tmpSubTotalItem += itemsList.quantity * tmpPrice
        tmpTaxPrice += getItemTax(itemsList.taxRates, tmpPrice, itemsList.quantity)
      })
    })
    setTaxPrice(tmpTaxPrice)
    setSubTotalItemPrice(tmpSubTotalItem)
    setTotalOfItems(tmpTotalOfItems)
  }

  const handleRefundTypeChange = (refundType: string) => {
    if (refundType === Constants.CS_CUSTOMERCOUPON || refundType == Constants.CS_DAMAGED) {
      setPercentSelectVisible(true)
    } else {
      setPercentSelectVisible(false)
    }
    setRefund(refundType)
  }

  const helperRefundAvailableText = (maxRefundInput: number) => {
    return formatPrice(maxRefundInput.toString(), currencyCode, customer) + ' available for refund'
  }

  const getRefundAmount = () => {
    let tmpAmount = 0
    tmpTransactions.map((transaction: any) => {
      if (!isNaN(+transaction.tmpAmount) && transaction.tmpAmount !== '') {
        tmpAmount += parseFloat(transaction.tmpAmount)
      }
    })
    return tmpAmount
  }

  const hasBeenReturned = (tags: string) => {
    return tags.includes('narvar_return')
  }

  const hasReturnFee = (rmaRequests: []) => {
    let checked = false
    rmaRequests.map((rmaRequest: any) => {
      if (rmaRequest.restocking_fee !== '0.00') {
        checked = true
      }
    })
    return checked
  }

  const enableReturnFee = () => {
    if (
      hasBeenReturned(order._tags) &&
      hasReturnFee(order._rmaRequests) &&
      (refundName === Constants.CS_DELAYEDREFUND ||
        refundName === Constants.CS_SELFPOSTAGE ||
        refundName === Constants.CS_COMBINEDRETURN ||
        refundName === Constants.CS_EXCEPTIONS)
    ) {
      return true
    }
    return false
  }

  const updateFieldChanged = (event: any, idx: number) => {
    const floatRegExp = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$')
    const value = event.target.value
    const newArr = [...tmpTransactions]
    if (value === '' || floatRegExp.test(value)) {
      newArr[idx].tmpAmount = value
    } else {
      newArr[idx].tmpAmount = 0
    }
    setTransactions(newArr)
  }

  const enableShippingFee = () => {
    if (
      refundName === Constants.CS_WRONGITEM ||
      refundName === Constants.CS_DAMAGED ||
      refundName === Constants.CS_MISSINGITEM
    ) {
      return true
    }
    return false
  }

  const getCurrencySymbol = () => {
    return (0)
      .toLocaleString(`en-${countryCode ? countryCode : null}`, {
        style: 'currency',
        currency: currencyCode,
        currencyDisplay: 'narrowSymbol',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, '')
      .trim()
  }

  const UnfulfilledItems = ({ unfulfilledItems }: any) => {
    return (
      <div>
        {unfulfilledItems.map((unfulfilledOrderItem: any, index: React.Key | null | undefined) => (
          <Grid key={index}>
            <OrderItems
              orderItems={unfulfilledOrderItem}
              status={'Unfulfilled'}
              canDuplicateOrder={true}
              selectedItems={setSelected}
              order={salesOrder}
              customer={customer}
              handleItemQuantity={handleItemQuantity}
              omsLoading={false}
              omsErrorStatus={true}
            />
          </Grid>
        ))}
      </div>
    )
  }

  return (
    <Box
      sx={{
        p: 4,
        marginLeft: '-32px',
        paddingBottom: '120px',
      }}
    >
      {showLoading && <Loading />}
      <RefundOrderModal
        isShown={isRefundedOrderModalVisible}
        onClose={handleRefundOrderModalVisibility}
        orderItems={getSelectedItems()}
        subTotalPrice={getSubTotalPrice(subTotalItemPrice)}
        shippingPrice={getShippingPrice()}
        taxPrice={getTaxesPrice()}
        totalOfItems={getTotalOfSelectedItems(totalOfItems)}
        refundName={refundName}
        refundTotal={getRefundAmount()}
        notify={notify}
        transactions={tmpTransactions}
      />
      {!showLoading && salesOrder && (
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='flex-start'
          columns={12}
          spacing={2}
          sx={{
            margin: 'auto',
            maxWidth: 1400,
            flexGrow: 1,
          }}
        >
          <Grid container alignItems='stretch' direction='column' justifyContent='flex-end'>
            <Grid display='flex' alignItems='flex-end' justifyContent='flex-end'>
              <OrderActions />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={8}>
            <Paper
              sx={{
                p: 2,
                margin: 'auto',
                flexGrow: 1,
              }}
            >
              <OrderMetaData order={salesOrder} />
              <Grid
                container
                direction='column'
                justifyContent='flex-end'
                alignItems='flex-end'
                spacing={2}
              >
                <FormControlLabel
                  control={<Checkbox checked={selected} onChange={handleItemQuantitySelection} />}
                  label={selected ? 'Deselect All' : 'Select All'}
                  labelPlacement='end'
                />
              </Grid>
            </Paper>
            {unfulfilledOrderItems.length > 0 && (
              <Paper
                sx={{
                  paddingBottom: '10px',
                  margin: '20px 0px 12px ',
                }}
              >
                <Grid
                  container
                  direction='column'
                  justifyContent='flex-end'
                  alignItems='flex-end'
                  spacing={2}
                  sx={{ p: 2 }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedUnfulfilledOrderItems}
                        onChange={handleUnfulfilledItemQuantitySelection}
                      />
                    }
                    label={selectedUnfulfilledOrderItems ? 'Deselect All' : 'Select All'}
                    labelPlacement='end'
                  />
                </Grid>
                <UnfulfilledItems unfulfilledItems={unfulfilledOrderItems} />
              </Paper>
            )}
            {fulfilledOrderItems.length > 0 &&
              fulfilledOrderItems.map((fulfilledOrderItem: any, index: any) => (
                <Paper
                  sx={{
                    paddingBottom: '10px',
                    margin: '20px 0px 12px ',
                  }}
                  key={index}
                >
                  <Grid
                    container
                    direction='column'
                    justifyContent='flex-end'
                    alignItems='flex-end'
                    spacing={2}
                    sx={{ p: 2 }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedFulfilledOrderItems[index]}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            handleFulfilledItemQuantitySelection(event, index)
                          }
                        />
                      }
                      label={selectedFulfilledOrderItems[index] ? 'Deselect All' : 'Select All'}
                      labelPlacement='end'
                    />
                  </Grid>
                  <OrderItems
                    trackingInfo={trackingList[index]}
                    orderItems={fulfilledOrderItem}
                    status={'Fulfilled'}
                    extraStatus={nameList[index]}
                    canDuplicateOrder={true}
                    selectedItems={setSelected}
                    order={salesOrder}
                    customer={customer}
                    handleItemQuantity={handleItemQuantity}
                  />
                </Paper>
              ))}
            <Paper
              sx={{
                paddingBottom: '10px',
                margin: '20px 0px 12px ',
              }}
            >
              <Grid container direction='row' justifyContent='flex-end' alignItems='flex-end'>
                <RefundCard
                  totalOfItems={getTotalOfSelectedItems(totalOfItems)}
                  subTotalPrice={getSubTotalPrice(subTotalItemPrice)}
                  order={salesOrder}
                  customer={customer}
                  shippingPrice={getShippingPrice()}
                  taxPrice={getTaxesPrice()}
                  refundTotal={getRefundTotal(subTotalItemPrice)}
                  setTransactions={setTransactions}
                  tmpTransactions={tmpTransactions}
                />
                {enableShippingFee() && (
                  <Grid
                    container
                    direction='row'
                    justifyContent='flex-start'
                    alignItems='flex-start'
                    paddingLeft={3.5}
                  >
                    <Grid item xs={8}>
                      <FormControlLabel
                        sx={{ fontWeight: 400 }}
                        control={
                          <Checkbox
                            checked={shippingFee}
                            onChange={(event) => setShippingFee(event.target.checked)}
                          />
                        }
                        label={
                          <Typography>
                            Add shipping fee (
                            <strong>
                              {formatPrice(order._totalShippingPriceSet, currencyCode, customer)}
                            </strong>
                            )
                          </Typography>
                        }
                      />
                    </Grid>
                  </Grid>
                )}
                {enableReturnFee() && (
                  <Grid
                    container
                    direction='row'
                    justifyContent='flex-start'
                    alignItems='flex-start'
                    paddingLeft={3.5}
                  >
                    <Grid item xs={8}>
                      <FormControlLabel
                        sx={{ fontWeight: 400 }}
                        control={
                          <Checkbox onChange={(event) => setReturnFee(event.target.checked)} />
                        }
                        label={
                          <Typography>
                            Deduct return fee (
                            <strong>{formatPrice('6', currencyCode, customer)}</strong>)
                          </Typography>
                        }
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid
                  container
                  direction='column'
                  justifyContent='flex-end'
                  alignItems='flex-end'
                  sx={{ padding: '0 30px' }}
                >
                  <Grid item xs={3}>
                    <Typography sx={{ fontWeight: 400, paddingBottom: '16px' }}>
                      <strong>Refund total</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography sx={{ fontWeight: 400 }}>{''}</Typography>
                  </Grid>
                  {tmpTransactions.map((transaction: Transaction, idx: number) => (
                    <Grid item xs={4} justifyContent='flex-end' key={transaction.id}>
                      <Typography>{getTransactionLabel(transaction)}</Typography>
                      <TextField
                        id='refund-input'
                        name={`transactions[${idx}]`}
                        sx={{
                          width: 'auto',
                          float: 'right',
                          alignItems: 'flex-end',
                          input: {
                            textAlign: 'right',
                          },
                        }}
                        inputMode='numeric'
                        size='small'
                        value={transaction.tmpAmount}
                        onChange={(e) => updateFieldChanged(e, idx)}
                        onKeyDown={(event) => {
                          const key = window.event ? event.keyCode : event.which
                          // Allow only backspace, delete, left arrow, right arrow and period
                          if (
                            event.keyCode == 8 ||
                            event.keyCode == 46 ||
                            event.keyCode == 37 ||
                            event.keyCode == 39 ||
                            event.keyCode === 190
                          ) {
                            return true
                            // Make sure it's a number
                          } else if (key < 48 || key > 57) {
                            event.preventDefault()
                            return false
                          }
                        }}
                        variant='standard'
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            fontSize: '1.25rem',
                            fontWeight: 700,
                          },
                        }}
                        InputProps={{
                          inputProps: {
                            min: 0,
                            max: transaction.amount - transaction.refundableAmount,
                            step: '0.01',
                          },
                          style: {
                            width: 'auto',
                            maxWidth: '200px',
                            marginTop: '24px',
                            textAlign: 'right',
                          },
                          startAdornment: (
                            <InputAdornment position='start'>
                              <Typography sx={{ paddingBottom: '6px' }}>
                                {getCurrencySymbol()}
                              </Typography>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position='end'>
                              <Typography sx={{ paddingBottom: '4px' }}>
                                {currencyCode !== 'USD' ? ' ' + currencyCode : ''}
                              </Typography>
                            </InputAdornment>
                          ),
                        }}
                        helperText={helperRefundAvailableText(
                          transaction.amount - transaction.refundableAmount,
                        )}
                      />
                    </Grid>
                  ))}
                </Grid>
                <Grid
                  container
                  direction='row'
                  justifyContent='flex-start'
                  alignItems='flex-start'
                  paddingLeft={3.5}
                >
                  <Grid item xs={8}>
                    <FormControlLabel
                      sx={{ fontWeight: 400 }}
                      control={
                        <Checkbox
                          onChange={(event) => setNotify(event.target.checked)}
                          defaultChecked
                        />
                      }
                      label='Send notification to customer'
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction='row'
                  justifyContent='flex-end'
                  alignItems='flex-end'
                  paddingLeft={3}
                  paddingRight={3}
                  paddingBottom={3}
                >
                  {showPercentSelect ? (
                    <PercentOptions setPercent={setPercent} refundName={refundName} />
                  ) : null}
                  <RefundOptions
                    fulfillmentStatus={order._fulfillmentStatus}
                    setNameRefund={handleRefundTypeChange}
                  />
                  <Grid item>
                    <Button
                      type='submit'
                      color='primary'
                      size='large'
                      variant='contained'
                      disabled={disableButton()}
                      onClick={handleRefundOrderModalVisibility}
                    >
                      Refund
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={4}>
            <Paper
              sx={{
                p: 2,
                margin: 'auto',
                flexGrow: 1,
              }}
            >
              <CustomerData customer={customer} />
              <TagsStacked tags={salesOrder._tags} />
            </Paper>
            <Paper
              sx={{
                p: 2,
                margin: '10px auto',
                flexGrow: 1,
              }}
            >
              <NotesStacked
                note={salesOrder?._note}
                additionalNotes={salesOrder._additionalNotes}
                expanded={false}
              />
            </Paper>
          </Grid>
        </Grid>
      )}
    </Box>
  )
}

export default OrderRefundForm
