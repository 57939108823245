import React from 'react'

import { Link, Typography } from '@mui/material'

const TrackingInfo = (trackingInfo: any) => {
  let trackingDetails = trackingInfo
  if (Object.hasOwn(trackingInfo, 'trackingInfo')) {
    trackingDetails = trackingInfo.trackingInfo
  }
  if (trackingDetails) {
    return (
      <div style={{ paddingLeft: '30px' }}>
        {trackingDetails.company ? (
          <Typography sx={{ color: 'gray' }} variant='h3' fontSize={'1rem'}>
            {trackingDetails.company}
          </Typography>
        ) : null}
        {trackingDetails.number ? (
          <Link
            href={trackingDetails.url}
            target='_blank'
            rel='noopener noreferrer'
            variant='body1'
            fontSize={'1rem'}
            textTransform={'none'}
          >
            {trackingDetails.number}
          </Link>
        ) : null}
      </div>
    )
  } else {
    return null
  }
}

export default TrackingInfo
