import * as React from 'react'

import { CircularProgress, Grid } from '@mui/material'

export default function Loading() {
  return (
    <Grid
      container
      direction='column'
      justifyContent='center'
      alignItems='center'
      padding={10}
      margin='auto'
    >
      <CircularProgress />
    </Grid>
  )
}
