/* eslint-disable camelcase */

const formFields = {
  signIn: {
    username: {
      placeholder: 'Enter your email',
      isRequired: true,
    },
  },
  signUp: {
    password: {
      label: 'Password:',
      placeholder: 'Enter your Password:',
      isRequired: false,
      order: 2,
    },
    confirm_password: {
      label: 'Confirm Password:',
      order: 1,
    },
  },
  forceNewPassword: {
    password: {
      placeholder: 'Enter your Password',
    },
  },
  forgotPassword: {
    username: {
      placeholder: 'Enter your email',
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      placeholder: 'Enter your Confirmation Code',
      label: 'Confirmation Code from Email',
      isRequired: true,
    },
    confirm_password: {
      placeholder: 'Enter your Password Please',
    },
  },
  confirmSignIn: {
    confirmation_code: {
      label: 'Authenticator Code',
      placeholder: 'Enter your Confirmation Code',
      isRequired: true,
    },
  },
  confirmVerifyUser: {
    confirmation_code: {
      label: 'Confirmation Code from Email',
      placeholder: 'Enter your Confirmation Code',
      isRequired: true,
    },
  },
  confirmSignUp: {
    confirmation_code: {
      label: 'Confirmation Code from Email',
      placeholder: 'Enter your Confirmation Code',
      isRequired: true,
    },
  },
}

export default formFields
