import React, { FC, ReactElement } from 'react'

import { Box, Container, Grid, Paper, Typography } from '@mui/material'

import Copyright from './Copyright'

export const Footer: FC = (): ReactElement => {
  return (
    <Paper
      sx={{
        position: 'relative',
        bottom: 0,
        width: '100%',
        border: 'none',
        paddingBottom: '10px',
        boxShadow: 0,
      }}
      component='footer'
      square
      variant='outlined'
    >
      <Container maxWidth='lg'>
        <Box
          sx={{
            flexGrow: 1,
            justifyContent: 'center',
            display: 'flex',
            my: 1,
          }}
        >
          <Copyright />
        </Box>
      </Container>
      <Grid item sx={{ textAlign: 'center' }}>
        <Typography variant='body2' component='div'>
          <strong>Version: {process.env.REACT_APP_APOLLO_VERSION}</strong>
        </Typography>
      </Grid>
    </Paper>
  )
}

export default Footer
