import React from 'react'

import { Info } from '@mui/icons-material'
import { Box, CircularProgress, Grid, Tooltip } from '@mui/material'

const StatusInfoLoadingIcon = () => {
  return (
    <Grid paddingLeft={'10px'}>
      <Tooltip title='Fetching more details on status' placement='right-start'>
        <Box
          sx={{
            m: 0,
            position: 'relative',
          }}
        >
          <Info sx={{ color: '#DCDCDC' }} fontSize={'small'} />
          <CircularProgress
            size={16}
            sx={{
              position: 'absolute',
              top: 2,
              left: 2,
              zIndex: 1,
            }}
          />
        </Box>
      </Tooltip>
    </Grid>
  )
}

export default StatusInfoLoadingIcon
