import React, { useEffect } from 'react'

import { Divider, Grid, List, ListItem, Typography } from '@mui/material'

import { Customer } from '../../../models/Customer'
import { SalesOrder } from '../../../models/SalesOrder'
import { Transaction } from '../../../models/Transaction'
import { formatPrice } from '../../../utils'
import { style } from '../PaymentCard'

type RefundCardProps = {
  totalOfItems: number
  subTotalPrice: string
  order: SalesOrder
  customer: Customer
  shippingPrice: string
  refundTotal: number
  taxPrice: string
  setTransactions: any
  tmpTransactions: Transaction[]
}

const RefundCard = ({
  totalOfItems,
  subTotalPrice,
  order,
  customer,
  shippingPrice,
  refundTotal,
  taxPrice,
  setTransactions,
  tmpTransactions,
}: RefundCardProps) => {
  const currencyCode = order._currencyCode

  useEffect(() => {
    let tmpValue = 0
    tmpTransactions
      .filter((transaction: Transaction) => {
        return transaction.amount - transaction.refundableAmount > 0
      })
      .map((transaction: Transaction, index: number) => {
        transaction.tmpAmount = 0
        if (index === 0) {
          tmpValue =
            Math.round(refundTotal * 100) / 100 -
            (transaction.amount - transaction.refundableAmount)
        }
        if (
          ((index === 0 && transaction.amount - transaction.refundableAmount > tmpValue) ||
            transaction.amount - transaction.refundableAmount <= tmpValue) &&
          tmpValue > 0
        ) {
          transaction.tmpAmount =
            Math.round((transaction.amount - transaction.refundableAmount) * 100) / 100
          if (index !== 0) {
            tmpValue -= Math.round(transaction.tmpAmount * 100) / 100
          }
        } else if (
          index !== 0 &&
          transaction.amount - transaction.refundableAmount > tmpValue &&
          tmpValue > 0
        ) {
          transaction.tmpAmount = Math.round(tmpValue * 100) / 100
          tmpValue = 0
        } else if (
          (index === 0 && transaction.amount - transaction.refundableAmount === refundTotal) ||
          (transaction.amount - transaction.refundableAmount > tmpValue && tmpValue < 0)
        ) {
          transaction.tmpAmount = Math.round(refundTotal * 100) / 100
          tmpValue = 0
        }
      })
    setTransactions([...tmpTransactions])
  }, [refundTotal])

  const formattedPrice = (price: string) => {
    return formatPrice(price, currencyCode, customer)
  }
  const getTotalOfItems = (totalOfItems: number) => {
    return totalOfItems > 1 ? totalOfItems + ' items' : totalOfItems + ' item'
  }

  const TotalLineItemPrice = ({ text1, text2, text3, text4 = '', fontWeight = 400 }: any) => {
    return (
      <ListItem>
        <Grid item xs={3}>
          <Typography sx={{ fontWeight: fontWeight }}>
            {text1}
            {text4 !== '' ? <strong> {`(${text4})`}</strong> : ''}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography sx={{ fontWeight: fontWeight }}>{text2}</Typography>
        </Grid>
        <Grid item xs={4} justifyContent='flex-end'>
          <Typography textAlign='right' sx={{ fontWeight: fontWeight }}>
            {text3}
          </Typography>
        </Grid>
      </ListItem>
    )
  }

  return (
    <Grid
      container
      direction='row'
      justifyContent='flex-start'
      alignItems='flex-start'
      sx={{ padding: '10px 16px' }}
    >
      <Grid
        sx={{ paddingLeft: '16px' }}
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='flex-start'
      >
        <Grid item xs={12}>
          <Grid container direction='row' justifyContent='flex-start'>
            <Typography variant='h2' fontSize={'1.25rem'} fontWeight={'bold'} paddingTop={'1rem'}>
              Refund Details
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='flex-start'
        spacing={2}
        sx={{
          margin: '15px auto',
          maxWidth: 1400,
          flexGrow: 1,
        }}
        paddingLeft={1}
        paddingRight={1}
        paddingBottom={1}
      >
        <List sx={style}>
          <TotalLineItemPrice
            text1={'Item subtotal'}
            text2={getTotalOfItems(totalOfItems)}
            text3={formattedPrice(subTotalPrice)}
          />
          <TotalLineItemPrice
            text1={`Shipping`}
            text3={formattedPrice(shippingPrice)}
            text4={formattedPrice(order._totalShippingPriceSet)}
          />
          <TotalLineItemPrice
            text1={'Tax'}
            text3={formattedPrice(taxPrice)}
            text4={formattedPrice(order._currentTotalTaxSet)}
          />
          <TotalLineItemPrice
            text1={'Refund amount'}
            text3={formattedPrice(refundTotal.toString())}
          />
          <Divider component='li' />
        </List>
      </Grid>
    </Grid>
  )
}

export default RefundCard
