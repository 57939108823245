import { createTheme } from '@mui/material/styles'

const ITEM_HEIGHT = 26
const ITEM_PADDING_TOP = 8

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          padding: '10px 16px 6px 16px',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        fontWeight: 700,
        textTransform: 'uppercase',
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.rounded === true && {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 150,
          }),
        }),
      },
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#ab8f80',
      dark: '#62544a',
    },
    secondary: {
      main: '#42e540',
    },
    neutral: {
      main: '#a0a2a4',
      contrastText: '#fff',
      light: '#babcbf',
      dark: '#87888a',
    },
  },
  typography: {
    fontFamily: 'T-Star Pro',
  },
})

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary']
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    neutral?: PaletteOptions['primary']
  }
}

// @babel-ignore-comment-in-output Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true
  }
}

export default theme
