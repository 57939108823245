/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'

import { Check, ExpandMore, Info } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Step,
  StepConnector,
  StepIconProps,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
  stepConnectorClasses,
  styled,
} from '@mui/material'

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#ab8f80',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#ab8f80',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}))

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }: any) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: '#ab8f80',
    }),
    '& .QontoStepIcon-completedIcon': {
      color: '#ab8f80',
      zIndex: 1,
      fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  }),
)

type GridHeaderProps = {
  omsStatus: string
  quantity: string
  fulfillmentInfo?: string
}

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className='QontoStepIcon-completedIcon' />
      ) : (
        <div className='QontoStepIcon-circle' />
      )}
    </QontoStepIconRoot>
  )
}

function GridHeader(props: GridHeaderProps) {
  let header = props.omsStatus
  if (props.quantity) {
    header = header + ' (' + props.quantity + ')'
  }
  if (props.fulfillmentInfo) {
    header = header + ' - ' + props.fulfillmentInfo
  }

  return (
    <Typography variant='h2' fontSize={'1.25rem'} fontWeight={'700'} lineHeight={'1.2'}>
      {header}
    </Typography>
  )
}

const CaptionTimestamp = ({ element }: any) => {
  return (
    <Typography variant='caption'>
      {element.createdAt
        ? new Date(Date.parse(element.createdAt)).toLocaleTimeString('en-US', {
            timeZone: 'America/Los_Angeles',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          }) + ' PST'
        : null}
    </Typography>
  )
}

interface StepInfo {
  index: number
  createdAt: string
  isActive: boolean
}

type StepName =
  | 'In Distribution'
  | 'In Picking'
  | 'Pick Completed'
  | 'PickPack Update Complete'
  | 'Shipped'

type FulfillmentStatusProps = {
  status: string
  shipUpdates: any[]
  quantity: string
  shipMemo: any[]
}

const FulfillmentStatus = ({ status, shipUpdates, quantity, shipMemo }: FulfillmentStatusProps) => {
  const shipMemoId = shipMemo[0] ? shipMemo[0].id : null
  const shipMemoMemoStatus = shipMemo[0] ? shipMemo[0].status : null

  const stepMap = new Map<StepName, StepInfo>()
    .set('In Distribution', {
      index: 0,
      createdAt: '',
      isActive: false,
    })
    .set('In Picking', {
      index: 1,
      createdAt: '',
      isActive: false,
    })
    .set('Pick Completed', {
      index: 2,
      createdAt: '',
      isActive: false,
    })
    .set('PickPack Update Complete', {
      index: 3,
      createdAt: '',
      isActive: false,
    })
    .set('Shipped', {
      index: 4,
      createdAt: '',
      isActive: false,
    })

  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [steps, setSteps] = useState<any>([])

  useEffect(() => {
    setActiveSteps()
  }, [shipUpdates || shipMemo])

  const setActiveSteps = () => {
    if (shipUpdates.length == 0 || shipMemo[0] == null) {
      setSteps([])
    }
    if (shipUpdates.length > 0 && shipMemo.length > 0) {
      const upsUpdatesByShipMemo = shipUpdates[0][shipMemo[0].id]
      let activeIndex = 0
      upsUpdatesByShipMemo.map((shipStep: any) => {
        const step = stepMap.get(shipStep.ship_status)
        if (step) {
          step.createdAt = shipStep.created_at ? shipStep.created_at : ''
          if (step.createdAt) {
            step.isActive = true
            activeIndex = step.index > activeIndex ? step.index : activeIndex
          }
        }
        // set to next step
        activeIndex = activeIndex == 4 ? activeIndex : activeIndex + 1
        setActiveIndex(activeIndex)
      })
    }
    setSteps(Array.from(stepMap).map(([name, value]) => ({ name, value })))
  }

  return (
    <Grid>
      {!shipMemoId && status === 'Completed' ? (
        <Grid
          sx={{ padding: '20px' }}
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='flex-start'
        >
          <GridHeader omsStatus={'Unfulfilled'} quantity={quantity} />
          <Grid paddingLeft={'10px'}>
            <Tooltip title='Pending Shopify Cancellation/Refund' placement='right-start'>
              <Info fontSize={'small'} color={'primary'} />
            </Tooltip>
          </Grid>
        </Grid>
      ) : (
        <Accordion elevation={0} sx={{ '&:before': { height: '0px' } }}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls='panel1-content'
            id='panel1-header'
          >
            <GridHeader
              omsStatus={
                shipMemoMemoStatus ? shipMemoMemoStatus.replace(/([A-Z])/g, ' $1').trim() : status
              }
              quantity={quantity}
              fulfillmentInfo={shipMemoId}
            />
          </AccordionSummary>
          {shipMemoId ? (
            <AccordionDetails>
              <Stepper alternativeLabel activeStep={activeIndex} connector={<QontoConnector />}>
                {steps.map((element: any) => (
                  <Step key={element.name}>
                    <StepLabel StepIconComponent={QontoStepIcon}>
                      <Box>
                        <Typography variant='body2'>{element.name}</Typography>
                        <CaptionTimestamp element={element.value} />
                      </Box>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </AccordionDetails>
          ) : (
            <AccordionDetails>
              <Box
                sx={{
                  textAlign: 'center',
                }}
              >
                <Typography variant='body1'>No ship memo associated with item(s)</Typography>
              </Box>
            </AccordionDetails>
          )}
        </Accordion>
      )}
    </Grid>
  )
}

export default FulfillmentStatus
