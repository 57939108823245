import React, { useState, useRef, useEffect } from 'react'

import { MoreHoriz } from '@mui/icons-material'
import Button from '@mui/material/Button'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import Stack from '@mui/material/Stack'

import ReturnModal from '../ReturnModal'

interface IReturnMoreProps {
  returnItems: []
  rmaNumber: string
}

const ReturnMoreMenu = ({ returnItems, rmaNumber }: IReturnMoreProps) => {
  const [open, setOpen] = useState(false)
  const [isReturnedOrderModalVisible, setReturnedOrderModalVisibility] = useState<boolean>(false)
  const [actionStatus, setActionStatus] = useState<string>('')
  const anchorRef = useRef<HTMLButtonElement>(null)

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      // eslint-disable-next-line
      anchorRef.current!.focus()
    }

    prevOpen.current = open
  }, [open])

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleReturnOrderModalVisibility = (action: string) => {
    setActionStatus(action)
    setReturnedOrderModalVisibility(!isReturnedOrderModalVisible)
  }

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    } else if (event.key === 'Escape') {
      setOpen(false)
    }
  }

  const getItemsTax = () => {
    let taxPrice: number = 0
    returnItems.forEach((item: any) => {
      taxPrice += item.unit_tax_shop * item.quantity
    })
    return parseFloat(taxPrice.toFixed(2))
  }

  const getRefundAmount = () => {
    let tmpSubTotalItem = 0
    returnItems.forEach((item: any) => {
      tmpSubTotalItem += item.unit_price * item.quantity
    })
    tmpSubTotalItem += getItemsTax()
    return tmpSubTotalItem
  }

  return (
    <Stack direction='row' spacing={2}>
      <ReturnModal
        isShown={isReturnedOrderModalVisible}
        onClose={handleReturnOrderModalVisibility}
        orderItems={returnItems}
        rmaNumber={rmaNumber}
        actionStatus={actionStatus}
        amount={getRefundAmount()}
        taxPrice={getItemsTax()}
      />
      <div>
        <Button
          ref={anchorRef}
          id={`composition-button-${rmaNumber}`}
          aria-controls={open ? `composition-menu-${rmaNumber}` : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup='true'
          size='small'
          onClick={handleToggle}
        >
          <MoreHoriz />
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement='top-end'
          transition
          disablePortal
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id={`composition-menu-${rmaNumber}`}
                    aria-labelledby={`composition-button-${rmaNumber}`}
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem onClick={() => handleReturnOrderModalVisibility('approved')}>
                      Approve
                    </MenuItem>
                    <MenuItem onClick={() => handleReturnOrderModalVisibility('rejected')}>
                      Reject
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleReturnOrderModalVisibility('delivered_to_retailer')}
                    >
                      Delivered To Retailer
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleReturnOrderModalVisibility('on_its_way_to_retailer')}
                    >
                      On Its Way To Retailer
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleReturnOrderModalVisibility('received_by_retailer')}
                    >
                      Received By Retailer
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleReturnOrderModalVisibility('resolve_manually_without_automation')
                      }
                    >
                      Resolve Manually
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
  )
}

export default ReturnMoreMenu
