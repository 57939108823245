import React, { useEffect, useState } from 'react'

import { Box, Button, Grid, List, ListItem, Modal, Typography } from '@mui/material'

import { Customer } from '../../models/Customer'
import { SalesOrder } from '../../models/SalesOrder'
import { currencyFormatter } from '../../utils'

type Props = {
  isShown: boolean
  onClose: any
  order: SalesOrder
  customer: Customer
}
const style = {
  textAlign: 'center',
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
}
const styleList = {
  p: 0,
  width: '100%',
}
const TaxRatesModal = ({ isShown, onClose, order, customer }: Props) => {
  const [open, setOpen] = useState<boolean>(isShown)
  useEffect(() => {
    setOpen(isShown)
  }, [isShown])
  function CloseRow() {
    return (
      <React.Fragment>
        <Grid item>
          <Button variant='contained' color='primary' onClick={onClose}>
            Close
          </Button>
        </Grid>
      </React.Fragment>
    )
  }
  return (
    <Modal
      id='tax-rates-confirmation-modal'
      open={open}
      aria-labelledby='tax-rates-modal-title'
      aria-describedby='tax-rates-modal-description'
      onClose={onClose}
    >
      <Box sx={style} id='tax-rates-confirmation-box'>
        <Grid container direction='column' justifyContent='center' alignItems='center'>
          <Grid item>
            <Typography variant='h2' fontSize={'1.5rem'}>
              Tax rates
            </Typography>
          </Grid>
          <List sx={styleList}>
            {order._taxLines.map((taxRate: any) => (
              <ListItem key={taxRate.title}>
                <Grid container direction='row' justifyContent='space-between' alignItems='center'>
                  <Grid item xs={10}>
                    <Typography
                      variant='body1'
                      fontSize={15}
                      sx={{ marginTop: 0, marginBottom: 0 }}
                    >
                      {taxRate.title}{' '}
                      {taxRate.ratePercentage !== null ? '(' + taxRate.ratePercentage + '%)' : ''}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant='body1' fontSize={14}>
                      {currencyFormatter(customer, order).format(taxRate.price)}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            ))}
            <ListItem key={'Total Tax'}>
              <Grid container direction='row' justifyContent='space-between' alignItems='center'>
                <Grid item xs={10}>
                  <Typography
                    variant='body1'
                    fontWeight={700}
                    sx={{ marginTop: 0, marginBottom: 0 }}
                  >
                    Total Tax
                  </Typography>
                </Grid>
                <Grid item justifyContent='flex-end'>
                  <Typography variant='body1' fontWeight={700}>
                    {currencyFormatter(customer, order).format(
                      parseFloat(order._currentTotalTaxSet),
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
          </List>
          <Grid container direction='column' justifyContent='center' alignItems='center'>
            <CloseRow />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}
export default TaxRatesModal
