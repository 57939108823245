import React, { useState } from 'react'

import SearchIcon from '@mui/icons-material/Search'
import { IconButton, InputBase, ThemeProvider, createTheme } from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}))

const theme = createTheme({
  components: {
    // Name of the component
    MuiIconButton: {
      styleOverrides: {
        // Name of the slot
        root: ({ theme }) => ({
          padding: theme.spacing(0, 2),
          height: '100%',
          position: 'absolute',
          cursor: 'pointer',
          zIndex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
        }),
      },
    },
  },
})

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  input: {
    '&::placeholder': {
      opacity: 0.7,
    },
  },
  color: 'inherit',
  fontWeight: 'bold',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}))

const OrderSearch = () => {
  const navigate = useNavigate()
  const [value, setValue] = useState<any>(null)

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      navigate(`/orders/${event.target.value}`)
    }
  }

  return (
    <Search>
      <ThemeProvider theme={theme}>
        <IconButton onClick={() => navigate(`/orders/${value}`)}>
          <SearchIcon />
        </IconButton>
      </ThemeProvider>
      <StyledInputBase
        autoComplete='off'
        placeholder='Search'
        inputProps={{
          'aria-label': 'search',
        }}
        id='search'
        onKeyDown={handleKeyDown}
        onChange={(event) => {
          setValue(event.target.value)
        }}
      />
    </Search>
  )
}

export default OrderSearch
