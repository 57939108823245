/* eslint-disable camelcase */
import React from 'react'

import { AssignmentReturnSharp } from '@mui/icons-material'
import { Chip } from '@mui/material'

type ReturnStatusBadgeProps = {
  status: string
}

interface IReturnStatusMap {
  [key: string]: {
    header: string
    level:
      | 'info'
      | 'default'
      | 'primary'
      | 'secondary'
      | 'error'
      | 'success'
      | 'warning'
      | undefined
    variant: 'outlined' | undefined
  }
}
export const returnStatusMap: IReturnStatusMap = {
  approved: {
    header: 'Approved',
    level: 'success',
    variant: undefined,
  },
  cancelled_by_retailer: {
    header: 'Cancelled by Retailer',
    level: 'warning',
    variant: undefined,
  },
  cancelled_by_user: {
    header: 'Cancelled by User',
    level: 'warning',
    variant: undefined,
  },
  delivered_to_retailer: {
    header: 'Delivered',
    level: 'success',
    variant: undefined,
  },
  exception: {
    header: 'Exception',
    level: 'error',
    variant: undefined,
  },
  initiated: {
    header: 'Initiated',
    level: 'info',
    variant: undefined,
  },
  on_its_way_to_retailer: {
    header: 'On Its Way',
    level: 'info',
    variant: undefined,
  },
  out_of_stock_exception: {
    header: 'Out of Stock Exception',
    level: 'error',
    variant: undefined,
  },
  received_by_retailer: {
    header: 'Received',
    level: 'success',
    variant: undefined,
  },
  rejected: {
    header: 'Rejected',
    level: 'warning',
    variant: undefined,
  },
  resolve_manually_without_automation: {
    header: 'Resolved Manually',
    level: 'success',
    variant: undefined,
  },
  return_in_progress: {
    header: 'Return in progress',
    level: 'info',
    variant: 'outlined',
  },
  cancelled_return: {
    header: 'Cancelled return',
    level: 'info',
    variant: 'outlined',
  },
  returned: {
    header: 'Returned',
    level: 'info',
    variant: 'outlined',
  },
}

const ReturnStatusBadge = ({ status }: ReturnStatusBadgeProps) => {
  let returnStatus: string = status
  let level: any = 'default'
  let variant: any = undefined
  try {
    returnStatus = returnStatusMap[status].header
    level = returnStatusMap[status].level
    variant = returnStatusMap[status].variant
  } catch (error) {
    console.warn(error)
  }

  return (
    <Chip
      color={level}
      label={returnStatus}
      icon={
        returnStatus === 'Return in progress' ||
        returnStatus === 'Returned' ||
        returnStatus === 'Cancelled return' ? (
          <AssignmentReturnSharp />
        ) : undefined
      }
      variant={variant}
      size='small'
      sx={{
        padding: '0 5px',
      }}
    />
  )
}

ReturnStatusBadge.defaultProps = {
  status: 'initiated',
}

export default ReturnStatusBadge
