/* eslint-disable camelcase */
export class Address {
  public name: string = ''
  public firstName: string = ''
  public lastName: string = ''
  public address1: string = ''
  public address2: string = ''
  public city: string = ''
  public country: string = ''
  public province: string = ''
  public provinceCode: string = ''
  public countryCode: string = ''
  public zip: string = ''
  public phone: string = ''

  constructor(
    name: string,
    firstName: string,
    lastName: string,
    address1: string,
    address2: string,
    city: string,
    country: string,
    province: string,
    provinceCode: string,
    countryCode: string,
    zip: string,
    phone: string,
  ) {
    this.name = name
    this.firstName = firstName
    this.lastName = lastName
    this.address1 = address1
    this.address2 = address2
    this.city = city
    this.country = country
    this.province = province
    this.provinceCode = provinceCode
    this.countryCode = countryCode
    this.zip = zip
    this.phone = phone
  }
}
type AddressDict = {
  name: string
  address1: string
  city: string
  country: string
  province: string
  province_code: string
  country_code: string
  zip: string
  phone: string
  [key: string]: any // 👈️ variable key
}
export const getAddressRequestDict = (address: AddressDict) => {
  const addressDict: AddressDict = {
    name: address.name,
    address1: address.address1,
    city: address.city,
    country: address.country,
    province: address.province,
    province_code: address.provinceCode,
    country_code: address.countryCode,
    zip: address.zip,
    phone: address.phone,
  }
  if (address.address2 != null && address.address2 != '') {
    addressDict.address2 = address.address2
  }
  return addressDict
}
