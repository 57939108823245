import React from 'react'

import { Loyalty, VerifiedUser, NoAccounts } from '@mui/icons-material'
import { Chip } from '@mui/material'

type CustomerStatusBadgeProps = {
  status: string
  type: string
}

const CustomerStatusBadge = ({ status, type }: CustomerStatusBadgeProps) => {
  return (
    <Chip
      label={status}
      icon={
        type === 'Loyalty' ? (
          <Loyalty
            style={{
              color: status === 'ONYX' ? '#EDEEF2' : status === 'MARBLE' ? '#2C2827' : 'none',
            }}
          />
        ) : type === 'IncompleteRMA' ? (
          <NoAccounts />
        ) : (
          <VerifiedUser />
        )
      }
      variant='outlined'
      size='small'
      sx={{
        padding: '0 5px',
        backgroundColor:
          status === 'ONYX'
            ? '#2C2827'
            : status === 'MARBLE'
            ? '#EDEEF2'
            : status === 'Trust'
            ? '#C5DFBF'
            : '#9C6169',
        borderColor:
          status === 'Trust' ? '#C5DFBF' : status === 'IncompleteRMA' ? '#9C6169' : 'none',
        color: status === 'ONYX' ? '#EDEEF2' : status === 'MARBLE' ? '#2C2827' : '#212121',
      }}
    />
  )
}

export default CustomerStatusBadge
