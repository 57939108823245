import React, { useEffect, useState } from 'react'

import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import { Chip } from '@mui/material'

type ShippingBadgeProps = {
  fulfillmentStatus: string
}

const ShippingBadge = ({ fulfillmentStatus }: ShippingBadgeProps) => {
  const [status, setStatus] = useState<string>('Unfulfilled')
  const [colorStyle, setColorStyle] = useState<
    'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
  >('warning')

  useEffect(() => {
    if (fulfillmentStatus == 'fulfilled' || fulfillmentStatus == 'FULFILLED') {
      setColorStyle('primary')
      setStatus('Fulfilled')
    } else if (fulfillmentStatus == 'partial' || fulfillmentStatus == 'PARTIALLY_FULFILLED') {
      setColorStyle('warning')
      setStatus('Partially fulfilled')
    } else if (fulfillmentStatus == 'restocked' || fulfillmentStatus == 'RESTOCKED') {
      setColorStyle('default')
      setStatus('Restocked')
    } else {
      setColorStyle('default')
      setStatus('Unfulfilled')
    }
  }, [])

  return (
    <Chip
      color={colorStyle || 'default'}
      label={status}
      icon={<LocalShippingIcon />}
      variant='outlined'
      size='small'
      sx={{
        padding: '0 5px',
      }}
    />
  )
}

ShippingBadge.defaultProps = {
  fulfillmentStatus: '',
}

export default ShippingBadge
