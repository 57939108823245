import React, { useEffect, useState } from 'react'

import { Grid, Stack, Typography } from '@mui/material'
import { useParams } from 'react-router'

import { SalesOrder } from '../../models/SalesOrder'
import ShippingBadge from '../fulfillment/ShippingBadge'
import ReturnStatusBadge from '../returns/ReturnStatusBadge'

import GenericBadge from './GenericBadge'
import PaymentBadge from './PaymentBadge'

type OrderMetaDataProps = {
  order: SalesOrder
}

const OrderMetaData = ({ order }: OrderMetaDataProps) => {
  const { orderName } = useParams()
  const [orderDate, setOrderDate] = useState<string>('')

  useEffect(() => {
    setOrderDate(order._createdAt)
  }, [])

  const convertStringToDate = (date: string, timeZone: string) => {
    return new Date(date)
      .toLocaleString('en-US', {
        timeZone: timeZone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })
      .toString()
  }

  const getReturnStatus = (status: string) => {
    if (status.includes('cancelled') || status.includes('rejected')) {
      status = 'cancelled_return'
    } else if (status.includes('resolve') || status.includes('delivered')) {
      status = 'returned'
    } else {
      status = 'return_in_progress'
    }
    return status
  }

  return (
    <Grid
      item
      xs={12}
      sx={{
        padding: 2,
      }}
    >
      <Grid container direction='row' justifyContent='space-between' alignItems='flex-start'>
        <Grid item xs={6}>
          <Grid container direction='column' justifyContent='flex-start' alignItems='flex-start'>
            <Typography variant='h1' fontSize={'1.5rem'}>
              <strong>Order:</strong> {orderName}
            </Typography>
            <Typography variant='body1'>
              {convertStringToDate(orderDate, 'America/Los_Angeles')}
            </Typography>
            <Typography variant='body1'>{order.appName}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container direction='row' justifyContent='flex-end' alignItems='flex-start'>
            <Stack direction='row' spacing={1}>
              {order.cancelledAt ? <GenericBadge status='Canceled' /> : null}
              <ShippingBadge fulfillmentStatus={order._fulfillmentStatus} />
              <PaymentBadge financialStatus={order._financialStatus} />
              {order._rmaRequests.length > 0 && (
                <ReturnStatusBadge
                  status={getReturnStatus(order._rmaRequests[0].current_return_status)}
                />
              )}
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default OrderMetaData
