import React from 'react'

import { ErrorOutline } from '@mui/icons-material'
import { Grid, Tooltip } from '@mui/material'

const StatusInfoErrorIcon = () => {
  return (
    <Grid paddingLeft={'10px'}>
      <Tooltip title='Unable to fetch OMS Status, please try again later' placement='right-start'>
        <ErrorOutline color='warning' fontSize='small' />
      </Tooltip>
    </Grid>
  )
}

export default StatusInfoErrorIcon
