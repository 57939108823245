import React, { useEffect, useState } from 'react'

import PaidIcon from '@mui/icons-material/Paid'
import { Chip } from '@mui/material'

type PaymentBadgeProps = {
  financialStatus: string
}

const PaymentBadge = ({ financialStatus }: PaymentBadgeProps) => {
  const [status, setStatus] = useState<string>('Authorized')
  const [colorStyle, setColorStyle] = useState<
    'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
  >('default')

  useEffect(() => {
    if (financialStatus.toLowerCase() == 'authorized') {
      setColorStyle('warning')
      setStatus('Authorized')
    } else if (financialStatus.toLowerCase() == 'paid') {
      setColorStyle('primary')
      setStatus('Paid')
    } else if (financialStatus.toLowerCase() == 'partially_paid') {
      setColorStyle('secondary')
      setStatus('Partially Paid')
    } else if (financialStatus.toLowerCase() == 'partially_refunded') {
      setColorStyle('secondary')
      setStatus('Partially Refunded')
    } else if (financialStatus.toLowerCase() == 'pending') {
      setColorStyle('secondary')
      setStatus('Pending')
    } else if (financialStatus.toLowerCase() == 'refunded') {
      setColorStyle('primary')
      setStatus('Refunded')
    } else if (financialStatus.toLowerCase() == 'voided') {
      setColorStyle('default')
      setStatus('Voided')
    }
  }, [])

  return (
    <Chip
      color={colorStyle}
      label={status}
      icon={<PaidIcon />}
      variant='outlined'
      size='small'
      sx={{
        padding: '0 5px',
      }}
    />
  )
}

PaymentBadge.defaultProps = {
  financialStatus: '',
}

export default PaymentBadge
