import moment from 'moment'

import { Customer } from './models/Customer'
import { SalesOrder } from './models/SalesOrder'
import { Transaction } from './models/Transaction'

export const currencyFormatter = (customer: Customer, order: SalesOrder) => {
  // TODO: Move to order model
  return new Intl.NumberFormat(
    `en-${
      customer._shippingAddress.countryCode
        ? customer._shippingAddress.countryCode
        : null || customer._billingAddress.countryCode
        ? customer._billingAddress.countryCode
        : null
    }`,
    {
      style: 'currency',
      currency: order._currencyCode,
      currencyDisplay: 'narrowSymbol',
    },
  )
}

export const formatPriceWithCurrency = (countryCode: string | null, currencyCode: string) => {
  return new Intl.NumberFormat(`en-${countryCode ? countryCode : null}`, {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: 'narrowSymbol',
  })
}

export const formatPrice = (price: string, currencyCode: string, customer: Customer) => {
  const countryCode = customer._shippingAddress.countryCode
    ? customer._shippingAddress.countryCode
    : null || customer._billingAddress.countryCode
    ? customer._billingAddress.countryCode
    : null
  return currencyCode !== 'USD'
    ? formatPriceWithCurrency(countryCode, currencyCode).format(parseFloat(price)) +
        ' ' +
        currencyCode
    : formatPriceWithCurrency(countryCode, currencyCode).format(parseFloat(price))
}

export const formatDate = (timestamp: string, timezone: string = 'America/Los_Angeles') => {
  return new Date(Date.parse(timestamp)).toLocaleDateString('en-US', {
    timeZone: timezone,
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })
}

export const formatDateTime = (date: string, timeZone: string) => {
  return new Date(date)
    .toLocaleString('en-US', {
      timeZone: timeZone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    })
    .toString()
}

export const isCreatedOverThreeMonths = (createdAt: string) => {
  const date = moment(createdAt).utcOffset('America/Los_Angeles').format('MM-DD-YYYY')
  const threeMonthsAgo = moment()
    .utcOffset('America/Los_Angeles')
    .subtract(3, 'months')
    .format('MM-DD-YYYY')
  return date < threeMonthsAgo
}

export const isCreatedOverTwentyFiveMinutes = (createdAt: string) => {
  const date = moment(createdAt).utcOffset('America/Los_Angeles').toDate()
  const thirtyMinutesAgo = moment()
    .utcOffset('America/Los_Angeles')
    .subtract(25, 'minutes')
    .toDate()
  return date < thirtyMinutesAgo
}

export const isCreatedOverOneYear = (createdAt: string) => {
  const date = moment(createdAt).utcOffset('America/Los_Angeles').format('YYYY')
  const oneYearAgo = moment().utcOffset('America/Los_Angeles').subtract(1, 'years').format('YYYY')
  return date < oneYearAgo
}

export const getTransactionLabel = (transaction: Transaction) => {
  const displayMethod = transaction.paymentMethod || transaction.formattedGateway
  const isGiftCard = transaction.formattedGateway === 'Gift Card'
  const lastFourDigits = transaction.lastFour !== null ? `( ****${transaction.lastFour} )` : ''
  // Get and display last four digits of payment method
  const cardNumber =
    transaction.paymentMethod && transaction.number ? `(${transaction.number.slice(-8)})` : ''
  const displayNumber = isGiftCard ? lastFourDigits : cardNumber
  return `${displayMethod} ${displayNumber}`.trim()
}
