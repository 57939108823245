/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'

import { useAuthenticator } from '@aws-amplify/ui-react'
import { AccountCircle } from '@mui/icons-material'
import MenuIcon from '@mui/icons-material/Menu'
import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Toolbar,
  Typography,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'

import Logo from '../Logo'
import OrderSearch from '../orders/OrderSearch'

const pages = ['Orders']
const settings = ['Logout']

const NavBar = () => {
  const { signOut } = useAuthenticator()
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null)
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)
  const username: string | null = localStorage.getItem('username')

  const navigate = useNavigate()

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(null)
    if (event.currentTarget.innerText === 'Logout') {
      // setAuthentication(false)
      // logout()
      // This is from higher level component that wraps the core app
      // <button onClick={signOut}>Sign out</button>
      localStorage.removeItem('username')
      signOut()
    } else if (event.currentTarget.innerText === 'Profile') {
      navigate('/profile')
    }
    setAnchorElUser(null)
  }

  return (
    <AppBar
      position='static'
      sx={{
        position: 'sticky',
        top: 0,
        width: '100%',
        zIndex: 2,
      }}
    >
      <Container maxWidth='xl'>
        <Toolbar disableGutters>
          <Logo paddingRight={'2vh'} color={'white'} />
          <Typography
            variant='h6'
            noWrap
            component='a'
            href='/'
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              // fontFamily: 'monospace',
              fontWeight: 700,
              // letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Apollo
          </Typography>

          <OrderSearch />

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleOpenNavMenu}
              color='inherit'
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign='center' data-testid={page}>
                    {page}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ ml: 1, my: 2, color: 'white', display: 'block', fontSize: 16 }}
              >
                {page}
              </Button>
            ))}
          </Box>
          <Typography
            variant='h2'
            noWrap
            sx={{
              display: { xs: 'none', md: 'flex' },
              fontSize: 16,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            {username}
          </Typography>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title='Open settings'>
              <IconButton
                onClick={handleOpenUserMenu}
                sx={{ p: 0, color: 'inherit' }}
                className='icon-button'
                aria-label='account'
                size='large'
                data-testid='icon-button'
              >
                <AccountCircle className='person-icon' sx={{ fontSize: 40 }} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id='menu-appbar'
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign='center'>{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default NavBar
