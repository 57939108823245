export class Transaction {
  public id: string = ''
  public parentId: string = ''
  public authorizationExpiresAt: string = ''
  public paymentId: string = ''
  public kind: string = ''
  public name: string = ''
  public tmpAmount: number = 0
  public amount: number = 0
  public refundableAmount: number = 0
  public number: string = ''
  public paymentMethod: string = ''
  public lastFour: string = ''
  public formattedGateway: string = ''
  public gateway: string = ''
  public status: string = ''
  public authorizationCode: string = ''
  public createdAt: string = ''
  public note: string = ''
  public message: string = ''
  public acquirerReferenceNumber: string = ''
  public lineItems: any = []

  get getId(): string {
    return this.id
  }

  set setId(newId: string) {
    this.id = newId
  }

  get getParentId(): string {
    return this.parentId
  }

  set setParentId(newParentId: string) {
    this.parentId = newParentId
  }

  get getPaymentId(): string {
    return this.paymentId
  }

  set setPaymentId(newPaymentId: string) {
    this.paymentId = newPaymentId
  }

  get getKind(): string {
    return this.kind
  }

  set setKind(newKind: string) {
    this.kind = newKind
  }

  get getName(): string {
    return this.name
  }

  set setName(newName: string) {
    this.name = newName
  }

  get getAmount(): number {
    return this.amount
  }

  set setAmount(newAmount: number) {
    this.amount = newAmount
  }

  get getRefundableAmount(): number {
    return this.refundableAmount
  }

  set setRefundableAmount(newRefundableAmount: number) {
    this.refundableAmount = newRefundableAmount
  }

  get getTmpAmount(): number {
    return this.tmpAmount
  }

  set setTmpAmount(newTmpAmount: number) {
    this.tmpAmount = newTmpAmount
  }

  get getNumber(): string {
    return this.number
  }

  set setNumber(newNumber: string) {
    this.number = newNumber
  }

  get getPaymentMethod(): string {
    return this.paymentMethod
  }

  set setPaymentMethod(newPaymentMethod: string) {
    this.paymentMethod = newPaymentMethod
  }

  get getLastFour(): string {
    return this.lastFour
  }

  set setLastFour(newLastFour: string) {
    this.lastFour = newLastFour
  }

  get getGateway(): string {
    return this.gateway
  }

  set setGateway(newGateway: string) {
    this.gateway = newGateway
  }

  get getFormattedGateway(): string {
    return this.formattedGateway
  }

  set setFormattedGateway(newFormattedGateway: string) {
    this.formattedGateway = newFormattedGateway
  }

  get getStatus(): string {
    return this.status
  }

  set setStatus(newStatus: string) {
    this.status = newStatus
  }

  get getAuthorizationExpiresAt(): string {
    return this.authorizationExpiresAt
  }

  set setAuthorizationExpiresAt(newAuthorizationExpiresAt: string) {
    this.authorizationExpiresAt = newAuthorizationExpiresAt
  }

  get getAuthorizationCode(): string {
    return this.authorizationCode
  }

  set setAuthorizationCode(newAuthorizationCode: string) {
    this.authorizationCode = newAuthorizationCode
  }

  get getCreatedAt(): string {
    return this.createdAt
  }

  set setCreatedAt(newCreatedAt: string) {
    this.createdAt = newCreatedAt
  }

  get getNote(): string {
    return this.note
  }

  set setNote(newNote: string) {
    this.note = newNote
  }

  get getMessage(): string {
    return this.message
  }

  set setMessage(newMessage: string) {
    this.message = newMessage
  }

  get getAcquirerReferenceNumber(): string {
    return this.acquirerReferenceNumber
  }

  set setAcquirerReferenceNumber(newAcquirerReferenceNumber: string) {
    this.acquirerReferenceNumber = newAcquirerReferenceNumber
  }

  get getLineItems(): any {
    return this.lineItems
  }

  set setLineItems(newLineItems: any) {
    this.lineItems = newLineItems
  }
}
