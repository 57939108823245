import React, { useState } from 'react'

import { ModeEdit } from '@mui/icons-material'
import { Grid, Typography, Stack, Chip, IconButton } from '@mui/material'
import { useSelector } from 'react-redux'

import { isCreatedOverOneYear } from '../../utils'

import OrderTagsModal from './OrderTagsModal'

const tagStyle = {
  borderRadius: '4px !important',
  marginLeft: '0 !important',
  marginBottom: '8px !important',
  marginRight: '8px !important',
  fontSize: '1rem',
}

type TagsProps = {
  tags: string[]
}

const TagsStacked = ({ tags }: TagsProps) => {
  const [isCustomerTagsModalVisible, setCustomerTagsModalVisibility] = useState<boolean>(false)
  const order = useSelector((state: any) => state.order.value)
  const duplicateOrderMode = window.location.pathname.includes('duplicate')
  const refundMode = window.location.pathname.includes('refund')

  const handleCustomerTagsModalVisibility = () => {
    setCustomerTagsModalVisibility(!isCustomerTagsModalVisible)
  }

  return (
    <Grid
      container
      direction='column'
      sx={{
        marginTop: 3,
      }}
    >
      <OrderTagsModal
        isShown={isCustomerTagsModalVisible}
        onClose={handleCustomerTagsModalVisibility}
        order={order}
      />
      <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start'>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography variant='h2' fontSize={20} fontWeight={'bold'} marginBottom={2}>
            Tags
          </Typography>
          {!isCreatedOverOneYear(order._createdAt) && !duplicateOrderMode && !refundMode && (
            <IconButton
              onClick={handleCustomerTagsModalVisibility}
              sx={{ padding: '0px 0px 6px 0px' }}
            >
              <ModeEdit />
            </IconButton>
          )}
        </Stack>
      </Grid>
      <Stack direction='row' spacing={1} flexWrap='wrap'>
        {tags.map((tag) => (
          <Chip key={tag} label={tag} color='primary' sx={tagStyle} />
        ))}
      </Stack>
    </Grid>
  )
}

TagsStacked.defaultProps = {
  tags: [],
}

export default TagsStacked
