/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react'

import { Box, Grid, Paper, Typography } from '@mui/material'
import { fetchAuthSession } from 'aws-amplify/auth'
import { useDispatch } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'

import { Address } from '../../models/Address'
import { Customer } from '../../models/Customer'
import { SalesItem } from '../../models/SalesItem'
import { SalesOrder } from '../../models/SalesOrder'
import { ShippingLine } from '../../models/ShippingLine'
import { Transaction } from '../../models/Transaction'
import { orderAdded, orderReset } from '../../store/orderSlice'
import Loading from '../base/Loading'
import ReturnCard from '../returns/ReturnCard'

import * as Constants from './constants'
import CustomerData from './CustomerData'
import NotesStacked from './NotesStacked'
import OrderActions from './OrderActions'
import OrderEvents from './OrderEvents'
import OrderItems from './OrderItems'
import OrderMetaData from './OrderMetaData'
import PaymentCard from './PaymentCard'
import TagsStacked from './TagsStacked'

const OrderDetails = () => {
  const location = useLocation()
  const { orderName } = useParams()
  const [customer, setCustomer] = useState<Customer | null>(null)
  const [salesOrder, setSalesOrder] = useState<SalesOrder>()
  const [canDuplicateOrder, setCanDuplicate] = useState(false)
  const [showError, toggleError] = useState<string | null>(null)
  const [showLoading, setLoading] = useState(true)
  const [omsStatusLoading, setOMSStatusLoading] = useState(true)
  const [showOMSErrorStatus, setOMSErrorStatus] = useState(false)
  const [showUPSErrorStatus, setUPSErrorStatus] = useState(false)
  const [unfulfilledOrderItems, setUnfulfilledOrderItems] = useState<any[]>([])
  const [removedOrderItems, setRemovedOrderItems] = useState<any[]>([])
  const [fulfilledOrderItems, setFulfilledOrderItems] = useState<SalesItem[]>([])
  const [unfulfilledOrderItems1, setUnfulfilledOrderItems1] = useState<any[]>([])
  const [trackingList, setTrackingList] = useState([])
  const [nameList, setNameList] = useState([])
  const [cursorEvents, setCursorEvents] = useState<string>('')
  const dispatch = useDispatch()
  const order = new SalesOrder()
  let extraStatus = ''
  const _customer = new Customer()
  let itemsList: SalesItem[] = []
  // Store line items
  let itemsListMap: any = {}
  // Store fulfillment line items
  let fulfillmentItemsMap: any = {}
  let tmpUnfulfilledOrderItems: any = []
  let multipleFulfilledItem: any = []
  let tmpNameList: any = []
  let trackingInfo: any = []
  let tmpData: any
  let tmpShipMemos: any = []
  const returnedOrderItems: any = []
  const returnedOrder: any = []

  useEffect(() => {
    window.scrollTo(0, 0)
    fetchOrderDetails()
    dispatch(orderReset({}))
  }, [location.pathname])

  const storeLineItems = (dataResponse: any) => {
    for (let i = 0; i < dataResponse.length; i++) {
      const salesItem = new SalesItem()
      const data: any = dataResponse[i].node
      salesItem.id = parseInt(data.id.replace('gid://shopify/LineItem/', ''))
      salesItem.refundableQuantity = data.refundableQuantity
      salesItem.srcImage = data.image?.url
      salesItem.nonFulfillableQuantity = data.nonFulfillableQuantity
      itemsListMap = { ...itemsListMap, [salesItem.id]: salesItem }
    }
  }

  const storeFulfillmentLineItems = (dataResponse: any) => {
    tmpData = dataResponse
    dataResponse = dataResponse.fulfillmentLineItems.edges
    if (tmpData.status === 'SUCCESS') {
      for (let i = 0; i < dataResponse.length; i++) {
        const salesItem = new SalesItem()
        const data: any = dataResponse[i].node.lineItem
        salesItem.id = parseInt(data.id.replace('gid://shopify/LineItem/', ''))
        salesItem.currentShopifyQuantity = dataResponse[i].node.quantity
        fulfillmentItemsMap = { ...fulfillmentItemsMap, [salesItem.id]: salesItem }
      }
    }
  }

  const storeItemsResponse = (dataResponse: any) => {
    itemsList = []
    let tmpTrackingInfo: any = null
    if (order.status === 'Fulfilled') {
      tmpData = dataResponse
      dataResponse = dataResponse.fulfillmentLineItems.edges
    }
    for (let i = 0; i < dataResponse.length; i++) {
      const salesItem = new SalesItem()
      let data: any = null
      if (order.status === 'Unfulfilled' || extraStatus === 'Removed') {
        data = dataResponse[i].node
      } else if (order.status === 'Fulfilled' && tmpData.status === 'SUCCESS') {
        data = dataResponse[i].node.lineItem
        salesItem.currentShopifyQuantity = dataResponse[i].node.quantity
        salesItem.initialQuantity = dataResponse[i].node.quantity
        salesItem.nonFulfillableQuantity =
          itemsListMap[
            parseInt(data.id.replace('gid://shopify/LineItem/', ''))
          ].nonFulfillableQuantity
        if (returnedOrderItems.length > 0) {
          const index = returnedOrderItems.findIndex(
            (item: any) => item.id === parseInt(data.id.replace('gid://shopify/LineItem/', '')),
          )
          if (index > -1) {
            salesItem.currentShopifyQuantity -= returnedOrderItems[index].quantity
            salesItem.returnQuantity += returnedOrderItems[index].quantity
          }
        }
      }
      if (
        order.status === 'Unfulfilled' ||
        extraStatus === 'Removed' ||
        (order.status === 'Fulfilled' && tmpData.status === 'SUCCESS')
      ) {
        salesItem.id = parseInt(data.id.replace('gid://shopify/LineItem/', ''))
        salesItem.title = data.title || data.name
        if (salesItem.title.toLowerCase().includes('gift card')) {
          salesItem.isGiftCard = true
        }
        salesItem.sku = data.sku
        let productId = data.product?.id
        if (productId) {
          productId = parseInt(productId.replace('gid://shopify/Product/', ''))
        }
        salesItem.productId = productId
        let variantId = data.variant?.id
        let inventoryQuantity = 0
        if (variantId) {
          variantId = parseInt(variantId.replace('gid://shopify/ProductVariant/', ''))
          inventoryQuantity = data.variant.inventoryQuantity
        }
        salesItem.variantId = variantId
        salesItem.variantTitle = data.variant?.title
        salesItem.tmpPrice = data.originalUnitPriceSet.presentmentMoney.amount
        if (data.discountAllocations.length > 0) {
          let tmpDiscountedPrice = 0
          data.discountAllocations.map((discountAllocation: any) => {
            tmpDiscountedPrice += parseFloat(
              discountAllocation.allocatedAmountSet.presentmentMoney.amount,
            )
          })
          salesItem.discountedPrice = tmpDiscountedPrice.toString()
        }
        const fulfilledQuantity =
          JSON.stringify(fulfillmentItemsMap[salesItem.id]) !== undefined
            ? fulfillmentItemsMap[salesItem.id].currentShopifyQuantity
            : 0
        salesItem.quantity = data.currentQuantity - fulfilledQuantity
        if (order.status === 'Unfulfilled' || extraStatus === 'Removed') {
          salesItem.initialQuantity = data.unfulfilledQuantity
        }
        if (data.taxLines.length > 0) {
          const tmpTaxRates: number[] = []
          data.taxLines.map((tax: any) => {
            if (Number(tax.priceSet.shopMoney.amount).toFixed(2) !== '0.00') {
              tmpTaxRates.push(tax.rate)
            }
          })
          salesItem.taxRates = tmpTaxRates
        }
        if (order.status === 'Unfulfilled' || extraStatus === 'Removed') {
          salesItem.currentShopifyQuantity = data.quantity
          salesItem.nonFulfillableQuantity = data.nonFulfillableQuantity
        }
        salesItem.srcImage = data.image?.url
        salesItem.quantityInventory = inventoryQuantity
        salesItem.refundableQuantity = itemsListMap[salesItem.id].refundableQuantity
        itemsList.push(salesItem)
      }
    }
    if (order.status === 'Fulfilled' && tmpData.status === 'SUCCESS') {
      if (tmpData.trackingInfo[0]) {
        tmpTrackingInfo = {
          number: tmpData.trackingInfo[0].number,
          company: tmpData.trackingInfo[0].company,
          url: tmpData.trackingInfo[0].url,
        }
      } else {
        tmpTrackingInfo = { number: '', company: '' }
      }
      trackingInfo = [...trackingInfo, tmpTrackingInfo]
      tmpNameList = [...tmpNameList, tmpData.name]
    }
    if (order.status === 'Unfulfilled' || extraStatus === 'Removed') {
      const tmpRemovedItems: SalesItem[] = []
      itemsList.map((element: any) => {
        if (element.nonFulfillableQuantity > 0) {
          const tmpSalesItem = new SalesItem()
          tmpSalesItem.id = element.id
          tmpSalesItem.title = element.title
          tmpSalesItem.sku = element.sku
          tmpSalesItem.productId = element.productId
          tmpSalesItem.variantId = element.variantId
          tmpSalesItem.variantTitle = element.variantTitle
          tmpSalesItem.price = element.price
          tmpSalesItem.tmpPrice = element.tmpPrice
          tmpSalesItem.srcImage = element.srcImage
          tmpSalesItem.quantity = element.nonFulfillableQuantity
          tmpSalesItem.quantityInventory = element.quantityInventory
          tmpSalesItem.initialQuantity = element.initialQuantity
          tmpSalesItem.isGiftCard = element.isGiftCard
          tmpRemovedItems.push(tmpSalesItem)
        }
      })
      setRemovedOrderItems(tmpRemovedItems)
      extraStatus = ''
    }
    if (order.status === 'Unfulfilled') {
      tmpUnfulfilledOrderItems = [itemsList]
      setUnfulfilledOrderItems([itemsList])
    } else if (order.status === 'Fulfilled' && tmpData.status === 'SUCCESS') {
      multipleFulfilledItem = [...multipleFulfilledItem, itemsList]
      setFulfilledOrderItems(multipleFulfilledItem.reverse())
      setNameList(tmpNameList.reverse())
      if (trackingInfo) {
        setTrackingList(trackingInfo.reverse())
      }
    }
  }

  const storeReturnLineItems = (dataResponse: any) => {
    const tmpList: SalesItem[] = []
    for (let i = 0; i < dataResponse.length; i++) {
      const data = dataResponse[i].node.fulfillmentLineItem.lineItem
      let variantId = data.variant?.id
      let productId = data.product?.id
      let inventoryQuantity = 0
      let tmpDiscountedPrice = 0
      let tmpTaxRates: number[] = []
      if (variantId) {
        variantId = parseInt(variantId.replace('gid://shopify/ProductVariant/', ''))
        inventoryQuantity = data.variant.inventoryQuantity
      }
      if (productId) {
        productId = parseInt(productId.replace('gid://shopify/Product/', ''))
      }
      if (data.taxLines.length > 0) {
        tmpTaxRates = []
        data.taxLines.map((tax: any) => {
          if (Number(tax.priceSet.shopMoney.amount).toFixed(2) !== '0.00') {
            tmpTaxRates.push(tax.rate)
          }
        })
      }
      if (data.discountAllocations.length > 0) {
        tmpDiscountedPrice = 0
        data.discountAllocations.map((discountAllocation: any) => {
          tmpDiscountedPrice += parseFloat(
            discountAllocation.allocatedAmountSet.presentmentMoney.amount,
          )
        })
      }
      const tmpSalesItem = new SalesItem()
      tmpSalesItem.returnId = parseInt(
        dataResponse[i].node.id.replace('gid://shopify/ReturnLineItem/', ''),
      )
      tmpSalesItem.id = parseInt(data.id.replace('gid://shopify/LineItem/', ''))
      tmpSalesItem.title = data.title
      tmpSalesItem.sku = data.sku
      tmpSalesItem.productId = productId
      tmpSalesItem.variantId = variantId
      tmpSalesItem.variantTitle = data.variant.title
      tmpSalesItem.tmpPrice = data.originalUnitPriceSet.presentmentMoney.amount
      tmpSalesItem.discountedPrice = tmpDiscountedPrice.toString()
      tmpSalesItem.srcImage =
        itemsListMap[parseInt(data.id.replace('gid://shopify/LineItem/', ''))].srcImage
      tmpSalesItem.quantity = dataResponse[i].node.quantity
      tmpSalesItem.quantityInventory = inventoryQuantity
      tmpSalesItem.initialQuantity = dataResponse[i].node.quantity
      tmpSalesItem.taxRates = tmpTaxRates
      tmpSalesItem.refundableQuantity =
        itemsListMap[parseInt(data.id.replace('gid://shopify/LineItem/', ''))].refundableQuantity
      tmpList.push(tmpSalesItem)
    }
    return tmpList
  }

  const storeReturnItemsResponse = (dataResponse: any, rmaRequests: any) => {
    const tmpRefunds: any[] = []
    for (let i = 0; i < dataResponse.length; i++) {
      if (returnedOrder.length < dataResponse.length) {
        returnedOrder.push({
          id: parseInt(dataResponse[i].node.id.replace('gid://shopify/Return/', '')),
          status: dataResponse[i].node.status,
          rmaNumber:
            Array.isArray(rmaRequests) && rmaRequests.length !== 0 ? rmaRequests[i].rma_number : '',
          lineItems: storeReturnLineItems(dataResponse[i].node.returnLineItems.edges),
        })
      }
      const refunds = dataResponse[i].node.refunds.nodes
      storeReturnLineItemsResponse(dataResponse[i].node.returnLineItems.edges)
      if (refunds.length > 0) {
        for (let i = 0; i < refunds.length; i++) {
          const tmpRefund = refunds[i]
          tmpRefunds.push({
            id: parseInt(tmpRefund.id.replace('gid://shopify/Refund/', '')),
            totalRefunded: tmpRefund.totalRefunded.amount,
          })
        }
      }
    }
    order.returns = returnedOrder
    order.refunds.map((refund: any) => {
      tmpRefunds.map((refund1: any) => {
        if (refund.note === null && refund.totalRefundedSet === refund1.totalRefunded) {
          refund.note = `Return ${refund1.id}`
        }
      })
    })
  }

  const storeReturnLineItemsResponse = (dataResponse: any) => {
    for (let i = 0; i < dataResponse.length; i++) {
      const data = dataResponse[i].node.fulfillmentLineItem.lineItem
      let index = -1
      if (returnedOrderItems.length > 0) {
        index = returnedOrderItems.findIndex(
          (item: any) =>
            item.returnId ===
            parseInt(dataResponse[i].node.id.replace('gid://shopify/ReturnLineItem/', '')),
        )
        if (index > -1) {
          returnedOrderItems[index].quantity += dataResponse[i].node.quantity
        }
      }
      if (returnedOrderItems.length === 0 || index <= -1) {
        const tmpSalesItem = new SalesItem()
        tmpSalesItem.id = parseInt(data.id.replace('gid://shopify/LineItem/', ''))
        tmpSalesItem.quantity = dataResponse[i].node.quantity
        returnedOrderItems.push(tmpSalesItem)
      }
    }
  }

  const groupDataBy = (data: any, attribute: string) => {
    const tmpData = data.reduce((result: any, currentValue: any) => {
      ;(result[currentValue[attribute]] = result[currentValue[attribute]] || []).push(currentValue)
      return result
    }, {})
    return Object.entries(tmpData).map(([key, value]) => ({ [key]: value }))
  }

  const storeOrderDetails = (tmpOrder: any) => {
    order.id = parseInt(tmpOrder.id.replace('gid://shopify/Order/', ''))
    order.country = tmpOrder.shipping_address?.country
    order.createdAt = tmpOrder.created_at
    order.cancelledAt = tmpOrder.cancelled_at
    order.statusPageUrl = tmpOrder.statusPageUrl
    if (tmpOrder.financial_status === 'AUTHORIZED') {
      order.authorizationExpiresAt = tmpOrder.transactions[0].authorizationExpiresAt
    }
    const tmpTransactions: any[] = []
    if (tmpOrder.refunds.length > 0) {
      const tmpRefunds: any = []
      tmpOrder.refunds.map((refund: any) => {
        const tmpRefundLineItems: any = []
        refund.refundLineItems.nodes.map((item: any) => {
          tmpRefundLineItems.push(item.lineItem.name)
        })
        refund.transactions.nodes.map((transaction: any) => {
          tmpRefunds.push({
            note: refund.note,
            totalRefundedSet: refund.totalRefundedSet.presentmentMoney.amount,
            idTransaction: transaction.id,
            refundLineItems: tmpRefundLineItems,
          })
        })
      })
      order.refunds = tmpRefunds
    }
    storeLineItems(tmpOrder.line_items)
    if (tmpOrder.returns.edges.length > 0) {
      storeReturnItemsResponse(tmpOrder.returns.edges, tmpOrder.rmaRequests)
    }
    if (tmpOrder.transactions.length > 0) {
      tmpOrder.transactions.map((transaction: any, index: number) => {
        const tmpTransaction = new Transaction()
        if (
          index + 1 < tmpOrder.transactions.length &&
          tmpOrder.transactions[index].kind === 'AUTHORIZATION' &&
          tmpOrder.transactions[index + 1].kind === 'CAPTURE'
        ) {
          return
        }
        if (transaction.paymentDetails) {
          tmpTransaction.name = transaction.paymentDetails.name
          tmpTransaction.paymentMethod = transaction.paymentMethod
          tmpTransaction.number = transaction.paymentDetails.number
          tmpTransaction.authorizationCode = transaction.authorizationCode
        }
        tmpTransaction.id = transaction.id
        tmpTransaction.parentId = transaction.parentTransaction
          ? transaction.parentTransaction.id
          : ''
        tmpTransaction.amount = transaction.amount
        tmpTransaction.formattedGateway = transaction.formattedGateway
        tmpTransaction.gateway = transaction.gateway
        tmpTransaction.status = transaction.status
        tmpTransaction.kind = transaction.kind
        if (tmpTransaction.kind === 'AUTHORIZATION') {
          tmpTransaction.authorizationExpiresAt = transaction.authorizationExpiresAt
        }
        const shopifyPaymentsSet = transaction.shopifyPaymentsSet
        if (shopifyPaymentsSet && transaction.kind === 'REFUND') {
          if (shopifyPaymentsSet.refundSet) {
            tmpTransaction.acquirerReferenceNumber =
              shopifyPaymentsSet.refundSet.acquirerReferenceNumber
          }
        }
        tmpTransaction.paymentId = transaction.paymentId
        tmpTransaction.createdAt = transaction.createdAt
        tmpTransaction.note = ''
        tmpTransaction.message = transaction.receiptJson.message
        tmpTransaction.lastFour = transaction.receiptJson.last_4
        tmpTransactions.push(tmpTransaction)
        tmpTransactions
          .filter((transaction: any) => {
            return transaction.kind === 'REFUND'
          })
          .map((transaction: any) => {
            order.refunds.map((refund: any) => {
              if (refund.idTransaction === transaction.id) {
                transaction.note = refund.note
                transaction.lineItems = refund.refundLineItems
              }
            })
          })
      })
      const tmpRefundTransactions = tmpTransactions.filter((transaction: any) => {
        return transaction.status === 'SUCCESS' && transaction.kind === 'REFUND'
      })
      tmpTransactions
        .filter((transaction: any) => {
          return (
            transaction.status === 'SUCCESS' &&
            (transaction.kind === 'SALE' || transaction.kind === 'CAPTURE')
          )
        })
        .map((transaction: Transaction) => {
          tmpRefundTransactions.map((transaction1: any) => {
            if (transaction1.parentId === transaction.id) {
              transaction.refundableAmount += parseFloat(transaction1.amount)
            }
          })
        })
      order.transactions = tmpTransactions
    }
    if (Object.prototype.hasOwnProperty.call(tmpOrder.shipping_lines, 'title')) {
      const shippingLine = new ShippingLine()
      order.shippingMethod = tmpOrder.shipping_lines.title
      shippingLine.title = tmpOrder.shipping_lines.title
      tmpOrder.shipping_lines.taxLines.map((taxLine: any) => {
        shippingLine.taxLines.push({
          title: taxLine.title,
          priceSet: taxLine.priceSet.shopMoney.amount,
        })
      })
      order.shippingLine = shippingLine
    }
    order.tags = tmpOrder.tags
    order.taxLines = tmpOrder.currentTaxLines
    order.fulfillmentStatus = tmpOrder.fulfillment_status
    order.financialStatus = tmpOrder.financial_status
    if (
      (tmpOrder.fulfillment_status === 'UNFULFILLED' ||
        tmpOrder.fulfillment_status === 'PARTIALLY_FULFILLED') &&
      tmpOrder.cancelled_at === null
    ) {
      ;(async () => {
        await fetchUPSOrderStatus()
        await fetchOMSOrderStatus(order.id)
      })()
    }
    order.note = tmpOrder.note
    order.additionalNotes = tmpOrder.additional_notes
    order.appName = tmpOrder.app
    order.totalPriceSet = tmpOrder.totalPriceSet.presentmentMoney.amount
    order.totalRefundedSet = tmpOrder.totalRefundedSet.presentmentMoney.amount
    order.totalRefundedShippingSet = tmpOrder.totalRefundedShippingSet.presentmentMoney.amount
    order.totalShippingPriceSet = tmpOrder.totalShippingPriceSet.presentmentMoney.amount
    order.totalOutstandingSet = tmpOrder.totalOutstandingSet.presentmentMoney.amount
    order.currentTotalDiscountsSet = tmpOrder.currentTotalDiscountsSet.presentmentMoney.amount
    order.currentTotalTaxSet = tmpOrder.currentTotalTaxSet.presentmentMoney.amount
    order.currentTotalPriceSet = tmpOrder.currentTotalPriceSet.presentmentMoney.amount
    order.currentSubtotalPriceSet = tmpOrder.currentSubtotalPriceSet.presentmentMoney.amount
    order.netPaymentSet = tmpOrder.netPaymentSet.presentmentMoney.amount
    order.totalWeight = tmpOrder.totalWeight
    order.currentSubtotalLineItemsQuantity = tmpOrder.currentSubtotalLineItemsQuantity
    order.discountCode = tmpOrder.discountCode
    order.currencyCode = tmpOrder.totalPriceSet.presentmentMoney.currencyCode
    let tmpEvents: any[] = []
    if (tmpOrder.events.edges.length > 0) {
      tmpOrder.events.edges.map((event: any, index: any) => {
        const date = new Date(Date.parse(event.node.createdAt)).toLocaleDateString('en-US', {
          timeZone: 'America/Los_Angeles',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })
        event.node.date = date
        event.node.index = index
        if (index === tmpOrder.events.edges.length - 1 && tmpOrder.events.pageInfo.hasNextPage) {
          setCursorEvents(event.cursor)
        }
        if (event.node.message !== 'Unknown event Order#created_with_oversell...') {
          tmpEvents.push(event.node)
        }
      })
      tmpEvents = groupDataBy(tmpEvents, 'date')
    }
    order.events = tmpEvents
    order.rmaRequests = tmpOrder.rmaRequests
    if (
      fulfilledOrderItems.length === 0 &&
      unfulfilledOrderItems.length === 0 &&
      multipleFulfilledItem.length === 0
    ) {
      if (tmpOrder.fulfillment_status === 'UNFULFILLED') {
        order.status = 'Unfulfilled'
        storeItemsResponse(tmpOrder.line_items)
      } else if (tmpOrder.fulfillment_status !== 'UNFULFILLED') {
        // This shows items removed from fulfilled orders
        extraStatus = 'Removed'
        storeItemsResponse(tmpOrder.line_items)
        order.status = 'Fulfilled'
        tmpOrder.fulfillments.forEach((fulfillmentLineItems: any) => {
          storeItemsResponse(fulfillmentLineItems)
          storeFulfillmentLineItems(fulfillmentLineItems)
        })
        if (tmpOrder.fulfillment_status === 'PARTIALLY_FULFILLED') {
          let tmpFulfillmentLineItems: any = []
          order.status = 'Unfulfilled'
          tmpOrder.fulfillments.forEach((fulfillment: any) => {
            if (fulfillment.status === 'SUCCESS') {
              tmpFulfillmentLineItems = [
                ...tmpFulfillmentLineItems,
                ...fulfillment.fulfillmentLineItems.edges,
              ]
            }
          })
          storeItemsResponse(
            tmpOrder.line_items.filter((lineItems: any) => {
              return !tmpFulfillmentLineItems.find(
                (fulfillmentLineItems: any) =>
                  lineItems.node.id === fulfillmentLineItems.node.lineItem.id &&
                  lineItems.node.unfulfilledQuantity === 0,
              )
            }),
          )
        }
      }
    }
    setSalesOrder(order)
    if (tmpOrder.app !== Constants.POS) {
      setCanDuplicate(verifyCanDuplicate(order, tmpOrder))
    }
    dispatch(
      orderAdded({
        name: orderName,
        id: order._id,
        value: order,
        customer: _customer,
        fulfilledOrderItems: multipleFulfilledItem.reverse(),
        trackingList: trackingInfo.reverse(),
        nameList: tmpNameList.reverse(),
        canDuplicateOrder:
          tmpOrder.app === Constants.POS ? false : verifyCanDuplicate(order, tmpOrder),
        unfulfilledOrderItems: tmpUnfulfilledOrderItems,
      }),
    )
  }

  const verifyCanDuplicate = (order: SalesOrder, tmpOrder: any) => {
    if (
      isAnInternationalOrder(tmpOrder.billing_address?.country) ||
      isAnInternationalOrder(tmpOrder.shipping_address?.country) ||
      isItemSoldOut() ||
      hasDuplicateTag(order.tags) ||
      isUnfulfilled(order) ||
      isNotPaid(order.financialStatus) ||
      noAddress(tmpOrder.shipping_address) ||
      noAddress(tmpOrder.billing_address)
    ) {
      return false
    } else {
      return true
    }
  }

  const isUnfulfilled = (order: any) => {
    return order._fulfillmentStatus.toLowerCase() != 'fulfilled'
  }

  const isAnInternationalOrder = (country: string) => {
    return country !== undefined && country !== 'United States'
  }

  const isNotPaid = (financialStatus: string) => {
    return financialStatus.toLowerCase() !== 'paid'
  }

  const hasDuplicateTag = (tags: string) => {
    return tags.includes('DUPLICATED') || tags.includes('CS DUPLICATED ORDER')
  }

  const noAddress = (address: any) => {
    return Object.keys(address).some(
      (el) =>
        el !== 'address2' && el !== 'phone' && (address[el] === '' || address[el] === undefined),
    )
  }

  const hasOutOfStockItems = (arr: any) => {
    let count = 0
    arr.map((element: any) => {
      if (element.quantityInventory <= 0) {
        count += 1
      }
    })
    return count === arr.length
  }

  const isItemSoldOut = () => {
    let count = 0
    let isSoldOut = false
    multipleFulfilledItem.map((items: any) => {
      if (hasOutOfStockItems(items) && items.length > 0) {
        count += 1
      }
    })
    if (multipleFulfilledItem.length === count && count > 0) {
      isSoldOut = true
    }
    return isSoldOut
  }

  const fetchUPSOrderStatus = async () => {
    const { tokens } = await fetchAuthSession()
    const accessToken = tokens?.accessToken.toString()
    try {
      const response = await fetch(
        `${process.env.REACT_APP_AIRBOSS_DOMAIN}/api/v2/fulfillment/ups/?shopify_order_name=${orderName}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      )
      const responseData = await response.json()
      if (responseData['results'].length > 0) {
        order._upsShipUpdates = groupDataBy(responseData['results'], 'ship_memo_id')
      }
    } catch (err) {
      setUPSErrorStatus(true)
    }
  }

  const fetchOrderDetails = async () => {
    const { tokens } = await fetchAuthSession()
    const accessToken = tokens?.accessToken.toString()

    try {
      const response = await fetch(
        `${process.env.REACT_APP_AIRBOSS_DOMAIN}/api/v2/order/shopify/${orderName}/`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      )
      if (response.status === 403) {
        setLoading(false)
        toggleError('You do not have permission to view this order: ' + orderName)
      } else {
        const responseData = await response.json()
        const tmpOrder = responseData.order
        _customer.email = tmpOrder.contact_email
        _customer.customer = {
          id: parseInt(tmpOrder.customer?.id.replace('gid://shopify/Customer/', '')),
          email: tmpOrder.customer?.email,
          customerId: tmpOrder.customer?.id,
          tags: tmpOrder.customer?.tags,
        }
        _customer.billingAddress = new Address(
          tmpOrder.billing_address?.name,
          tmpOrder.billing_address?.firstName,
          tmpOrder.billing_address?.lastName,
          tmpOrder.billing_address?.address1,
          tmpOrder.billing_address?.address2,
          tmpOrder.billing_address?.city,
          tmpOrder.billing_address?.country,
          tmpOrder.billing_address?.province,
          tmpOrder.billing_address?.provinceCode,
          tmpOrder.billing_address?.countryCode,
          tmpOrder.billing_address?.zip,
          tmpOrder.billing_address?.phone,
        )
        _customer.shippingAddress = new Address(
          tmpOrder.shipping_address?.name,
          tmpOrder.shipping_address?.firstName,
          tmpOrder.shipping_address?.lastName,
          tmpOrder.shipping_address?.address1,
          tmpOrder.shipping_address?.address2,
          tmpOrder.shipping_address?.city,
          tmpOrder.shipping_address?.country,
          tmpOrder.shipping_address?.province,
          tmpOrder.shipping_address?.provinceCode,
          tmpOrder.shipping_address?.countryCode,
          tmpOrder.shipping_address?.zip,
          tmpOrder.shipping_address?.phone,
        )
        _customer.name = tmpOrder.billing_address?.name || tmpOrder.customer?.displayName
        _customer.phone = tmpOrder.billing_address?.phone || tmpOrder.customer?.phone || ''
        setCustomer(_customer)
        storeOrderDetails(tmpOrder)
        setLoading(false)
      }
    } catch {
      setLoading(false)
      if (order._createdAt == '') {
        toggleError('Something went wrong, could not fetch order: ' + orderName)
      }
    }
  }

  const fetchOMSOrderStatus = async (orderId: number) => {
    const { tokens } = await fetchAuthSession()
    const accessToken = tokens?.accessToken.toString()
    try {
      const response = await fetch(
        `${process.env.REACT_APP_AIRBOSS_DOMAIN}/api/v2/order/oms/${orderId}/`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      )
      const responseData = await response.json()
      const tmpItems: any = []
      if (
        Object.keys(responseData).length > 0 &&
        !Object.prototype.hasOwnProperty.call(responseData, 'detail')
      ) {
        if (responseData.StatusName === 'Approved') {
          order._omsStatus = 'Accepted'
        } else if (responseData.StatusName === 'InReview') {
          order._omsStatus = 'In Review'
        } else if (responseData.StatusName === 'Shipping') {
          order._omsStatus = 'Delivery Pending'
        } else {
          order._omsStatus = responseData.StatusName ? responseData.StatusName : order.status
        }
        if (responseData.Items) {
          responseData.Items.map((item: any) => {
            tmpItems.push({
              ItemIdentifier: item.ItemIdentifier,
              CLU: item.ItemDetails.CLU || item.ItemDetails.ItemDescription,
            })
          })
        }
        if (responseData.ShipMemos) {
          responseData.ShipMemos.map((shipMemo: any) => {
            shipMemo.Items.map((item1: any) => {
              tmpItems.map((item2: any) => {
                if (item1.ItemIdentifier === item2.ItemIdentifier && item1.Status !== 'Rejected') {
                  tmpShipMemos = [
                    ...tmpShipMemos,
                    {
                      id: shipMemo.ShipMemoNo,
                      status: shipMemo.Status,
                      ItemIdentifier: item1.ItemIdentifier,
                      CLU: item2.CLU || item2.ItemDescription,
                      RejectedQty: item1.RejectedQty,
                      Status: item1.Status,
                      RejectReason: item1.RejectReason,
                    },
                  ]
                }
              })
            })
          })
          order._shipMemos = tmpShipMemos
        }
        const tmpShipMemos1 = groupDataBy(tmpShipMemos, 'id')
        tmpShipMemos1.map((obj: any) => {
          itemsList.map((element: any) => {
            obj[Object.entries(obj)[0][0]].map((element1: any) => {
              if (element1.CLU.toLowerCase() === element.sku.toLowerCase()) {
                element.shipMemoId = Object.entries(obj)[0][0]
                element.omsQuantity = element.quantity - element1.RejectedQty
                element.shipStatus = element1.Status
                if (element1.Status === 'Shipped' && element1.RejectReason === 'Cancelled') {
                  element.shipPartial = true
                }
              }
            })
          })
        })
        const tmpItemsList: SalesItem[] = []
        itemsList.map((tmp: SalesItem) => {
          if (tmp.shipPartial) {
            const salesItem = new SalesItem()
            salesItem.id = tmp.id + 1
            salesItem.title = tmp.title
            salesItem.sku = tmp.sku
            salesItem.productId = tmp.productId
            salesItem.variantId = tmp.variantId
            salesItem.shipMemoId = ''
            salesItem.variantTitle = tmp.variantTitle
            salesItem.price = tmp.price
            salesItem.tmpPrice = tmp.tmpPrice
            salesItem.srcImage = tmp.srcImage
            salesItem.shipStatus = ''
            salesItem.quantity = tmp.quantity - tmp.omsQuantity
            salesItem.omsQuantity = 0
            salesItem.quantityInventory = tmp.quantityInventory
            salesItem.initialQuantity = tmp.initialQuantity
            salesItem.isGiftCard = tmp.isGiftCard
            tmpItemsList.push(salesItem)
          }
        })
        let unfulfilledItemsList = itemsList.concat(tmpItemsList)
        if (order._fulfillmentStatus === 'PARTIALLY_FULFILLED') {
          unfulfilledItemsList = unfulfilledItemsList.filter(
            (x: SalesItem) => x.shipStatus != 'Shipped',
          )
        }
        setUnfulfilledOrderItems([])
        setUnfulfilledOrderItems1(groupDataBy(unfulfilledItemsList, 'shipMemoId'))
      } else {
        setOMSErrorStatus(true)
      }
      setOMSStatusLoading(false)
      setLoading(false)
    } catch (err) {
      setOMSErrorStatus(true)
      setOMSStatusLoading(false)
    }
  }

  const UnfulfilledItems = ({ unfulfilledItems, isGrouped }: any) => {
    return (
      <div>
        {unfulfilledItems.map((unfulfilledOrderItem: any, index: React.Key | null | undefined) => (
          <Grid key={index}>
            <OrderItems
              orderItems={isGrouped ? Object.values(unfulfilledOrderItem)[0] : unfulfilledOrderItem}
              status={'Unfulfilled'}
              canDuplicateOrder={canDuplicateOrder}
              order={salesOrder}
              omsLoading={omsStatusLoading}
              omsErrorStatus={showOMSErrorStatus}
              customer={customer}
            />
          </Grid>
        ))}
      </div>
    )
  }

  return (
    <Box
      sx={{
        p: 4,
        marginLeft: '-32px',
        paddingBottom: '120px',
      }}
    >
      {showLoading && <Loading />}
      {showError && (
        <Box
          sx={{
            padding: 10,
            textAlign: 'center',
          }}
        >
          <Typography variant='h1' fontSize='1.5rem' fontWeight='bold'>
            Woops
          </Typography>
          <Typography variant='body1'>{showError}</Typography>
        </Box>
      )}
      {!showLoading && salesOrder && (
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='flex-start'
          columns={12}
          spacing={2}
          sx={{
            margin: 'auto',
            maxWidth: 1400,
            flexGrow: 1,
          }}
        >
          <Grid container alignItems='stretch' direction='column' justifyContent='flex-end'>
            <Grid display='flex' alignItems='flex-end' justifyContent='flex-end'>
              <OrderActions />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={8}>
            <Paper
              sx={{
                p: 2,
                margin: 'auto',
                flexGrow: 1,
              }}
            >
              <OrderMetaData order={salesOrder} />
            </Paper>
            {salesOrder._rmaRequests.length > 0 &&
              salesOrder._rmaRequests.map((tmpReturn: any, index: number) => (
                <Paper
                  sx={{
                    paddingBottom: '10px',
                    margin: '20px 0px 12px ',
                  }}
                  key={index}
                >
                  <ReturnCard order={salesOrder} customer={customer} rmaRequest={tmpReturn} />
                </Paper>
              ))}
            {salesOrder._returns.length > 0 &&
              salesOrder._returns.map((tmpReturn: any, index: number) => (
                <Paper
                  sx={{
                    paddingBottom: '10px',
                    margin: '20px 0px 12px ',
                  }}
                  key={index}
                >
                  <OrderItems
                    orderItems={tmpReturn.lineItems}
                    status={tmpReturn.status === 'CLOSED' ? 'Returned' : 'Return in progress'}
                    order={salesOrder}
                    extraStatus={`${orderName}-R${index + 1}`}
                    customer={customer}
                  />
                </Paper>
              ))}
            {unfulfilledOrderItems.length > 0 && (
              <Paper
                sx={{
                  paddingBottom: '10px',
                  margin: '20px 0px 12px ',
                }}
              >
                <UnfulfilledItems unfulfilledItems={unfulfilledOrderItems} isGrouped={false} />
              </Paper>
            )}
            {unfulfilledOrderItems1.length > 0 && (
              <Paper
                sx={{
                  paddingBottom: '10px',
                  margin: '20px 0px 12px ',
                }}
              >
                <UnfulfilledItems unfulfilledItems={unfulfilledOrderItems1} isGrouped={true} />
              </Paper>
            )}
            {fulfilledOrderItems.length > 0 &&
              fulfilledOrderItems.map((fulfilledOrderItem: any, index) => (
                <Paper
                  sx={{
                    paddingBottom: '10px',
                    margin: '20px 0px 12px ',
                  }}
                  key={index}
                >
                  <OrderItems
                    trackingInfo={trackingList[index]}
                    canDuplicateOrder={canDuplicateOrder}
                    orderItems={fulfilledOrderItem}
                    status={'Fulfilled'}
                    extraStatus={nameList[index]}
                    order={salesOrder}
                    customer={customer}
                  />
                </Paper>
              ))}
            {removedOrderItems.length > 0 && (
              <Paper
                sx={{
                  paddingBottom: '10px',
                  margin: '20px 0px 12px ',
                }}
              >
                <OrderItems
                  indexList={removedOrderItems.length}
                  orderItems={removedOrderItems}
                  order={salesOrder}
                  status={'Removed'}
                  customer={customer}
                />
              </Paper>
            )}
            <Paper
              sx={{
                paddingBottom: '10px',
                margin: '20px 0px 12px ',
              }}
            >
              <PaymentCard order={salesOrder} customer={customer} />
            </Paper>
            <OrderEvents eventsGroupedByDay={salesOrder._events} cursorEvents={cursorEvents} />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={4}>
            <Paper
              sx={{
                p: 2,
                margin: 'auto',
                flexGrow: 1,
              }}
            >
              <CustomerData customer={customer} />
              <TagsStacked tags={salesOrder._tags} />
            </Paper>
            <Paper
              sx={{
                p: 2,
                margin: '10px auto',
                flexGrow: 1,
              }}
            >
              <NotesStacked
                note={salesOrder?._note}
                additionalNotes={salesOrder._additionalNotes}
              />
            </Paper>
          </Grid>
        </Grid>
      )}
    </Box>
  )
}

export default OrderDetails
