import React from 'react'

import { Authenticator } from '@aws-amplify/ui-react'
import { ThemeProvider } from '@mui/material/styles'
import * as Sentry from '@sentry/react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import App from './App'
import reportWebVitals from './reportWebVitals'
import { store, persistor } from './store/store'
import theme from './theme'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [Sentry.captureConsoleIntegration({ levels: ['error'] })],
  environment: process.env.REACT_APP_ENV,
  release: process.env.REACT_APP_SENTRY_RELEASE,
  attachStacktrace: true,
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <Authenticator.Provider>
            <App />
          </Authenticator.Provider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
