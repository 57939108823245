/* eslint-disable camelcase */
import React from 'react'

import { Checkroom, ExpandMore, Launch } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Link,
  List,
  Typography,
} from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'

import { formatDate, formatPrice } from '../../../utils'
import CopyToClipboard from '../../base/CopyToClipboard'
import ReturnMoreMenu from '../ReturnMoreMenu'
import ReturnStatusBadge from '../ReturnStatusBadge'

const refundMethodMap: { [key: string]: string } = {
  gift_card: 'Gift Card',
  original_payment: 'Original Payment',
}

const ReturnCard = ({ order, customer, rmaRequest }: any) => {
  // TODO: Update this to work with components, but returns are at the order level

  let totalQuantity: number = 0
  rmaRequest.payload.items.map((element: any) => {
    totalQuantity += element.quantity
  })
  let quantityLabel = 'item'
  if (totalQuantity > 1) {
    quantityLabel = 'items'
  }
  quantityLabel = totalQuantity + ' ' + quantityLabel

  const getNarvarURL = () => {
    return `https://admin.shopify.com/store/${process.env.REACT_APP_SHOPIFY_SHOP}/apps/narvar-returns/app/dashboard/details/${rmaRequest.rma_number}`
  }

  const getTrackingURL = () => {
    return `https://${process.env.REACT_APP_SHOPIFY_SHOP}.myshopify.com/apps/returns?rid=${rmaRequest.rma_number}&tracking_number=${rmaRequest.payload.package.tracking_number}`
  }

  const columns: GridColDef[] = [
    {
      field: 'Product',
      flex: 1,
      minWidth: 350,
      maxWidth: 600,
      renderCell: (params) => (
        <Grid container direction='row' justifyContent='flex-start' alignItems='center'>
          <Grid item xs={3}>
            {params.row.product_image ? (
              <img
                src={params.row.product_image}
                style={{
                  height: '100%',
                  width: '100%',
                  maxHeight: '100px',
                  maxWidth: '100px',
                  minHeight: '80px',
                  minWidth: '80px',
                }}
                alt={params.row.name}
              />
            ) : (
              <Checkroom />
            )}
          </Grid>
          <Grid item padding={'1rem'} xs={9}>
            <Typography>{params.row.name.split(' - ')[0]}</Typography>
            <Typography>{params.row.name.split(' - ')[1]}</Typography>
            <Typography>SKU: {params.row['sku']}</Typography>
            <List
              sx={{
                fontSize: '1rem',
                lineHeight: '1.5',
                paddingTop: 0,
                '& li': { padding: '0 0 0 6px' },
              }}
            >
              <Typography fontWeight={700}>Return reason:</Typography>
              <Typography paddingLeft={1} fontStyle={'italic'}>
                {params.row.original_return_reason}
                {', '}
                {params.row.child_reason}
              </Typography>
            </List>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'Quantity',
      flex: 1,
      minWidth: 200,
      align: 'right',
      renderCell: (params) => (
        <Grid container direction='row' justifyContent='flex-end' alignItems='center'>
          <Grid
            item
            paddingBottom={'22px'}
            alignContent={'flex-end'}
            justifyContent={'right'}
            textAlign={'right'}
          >
            <Typography sx={{ textDecoration: 'line-through' }}>
              {params.row.unit_price_original_presentment !== params.row.unit_price &&
                formatPrice(
                  params.row.unit_price_original_presentment,
                  order._currencyCode,
                  customer,
                )}
            </Typography>
          </Grid>
          <Grid
            item
            padding={'0.5rem'}
            alignContent={'flex-end'}
            justifyContent={'right'}
            textAlign={'right'}
          >
            <Typography>
              {formatPrice(params.row.unit_price, order._currencyCode, customer)} x{' '}
              {params.row.quantity}
            </Typography>
            <Typography variant={'caption'} fontStyle={'italic'}>
              {formatPrice(params.row.unit_tax_shop, order._currencyCode, customer)} x{' '}
              {params.row.quantity}
            </Typography>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'Total',
      flex: 1,
      align: 'right',
      renderCell: (params) => (
        <Grid alignContent={'flex-end'} justifyContent={'right'} textAlign={'right'}>
          <Typography>
            {formatPrice(params.row.total_item_price, order._currencyCode, customer)}
          </Typography>
          <Typography variant={'caption'} fontStyle={'italic'}>
            {formatPrice(params.row.refund_line_item_total_tax, order._currencyCode, customer)}
          </Typography>
        </Grid>
      ),
    },
  ]

  return (
    <Grid
      sx={{
        marginBottom: 1,
      }}
    >
      <Grid
        sx={{ padding: '20px' }}
        container
        direction='column'
        justifyContent='center'
        alignItems='stretch'
      >
        <Accordion elevation={0} sx={{ margin: 0, '&:before': { height: '0px' } }}>
          <AccordionSummary
            sx={{ padding: 0, margin: 0, marginTop: 0 }}
            expandIcon={<ExpandMore />}
            aria-controls={`return-${rmaRequest.payload.rma_number}-content`}
            id={`return-${rmaRequest.payload.rma_number}-header`}
          >
            <Typography
              variant='h2'
              fontSize={'1.25rem'}
              fontWeight={'bold'}
              minHeight={'1.5rem'}
              m={0}
            >
              Return ({quantityLabel}) -{' '}
              <small style={{ fontWeight: 'normal' }}>{rmaRequest.payload.rma_number}</small>{' '}
              <ReturnStatusBadge status={rmaRequest.current_return_status} />
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: '0 10px 0 10px' }}>
            <Grid container direction='row' justifyContent='space-between' alignItems='flex-start'>
              <Grid item>
                <Typography textTransform={'capitalize'}>
                  {rmaRequest.return_status}: {formatDate(rmaRequest.return_creation_date)}{' '}
                  <Link
                    href={getNarvarURL()}
                    target='_blank'
                    rel='noopener noreferrer'
                    textTransform={'none'}
                    color='primary'
                    sx={{ padding: '4px' }}
                  >
                    <Launch sx={{ fontSize: '1rem', padding: '0 0 0 4px' }} />
                  </Link>
                </Typography>
                <Typography>
                  Carrier: {rmaRequest.payload.package.carrier.split('_')[0].toUpperCase()} (
                  {rmaRequest.payload.package.carrier_service_method})
                </Typography>
                <Grid container>
                  <Grid item>
                    <Typography paddingRight={1}>Tracking:</Typography>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction='column'
                      justifyContent='center'
                      alignItems='flex-start'
                    >
                      <Grid item>
                        <Link
                          href={getTrackingURL()}
                          target='_blank'
                          rel='noopener noreferrer'
                          textTransform={'none'}
                        >
                          {rmaRequest.payload.package.tracking_number}
                        </Link>
                        <CopyToClipboard copyText={getTrackingURL()} />
                      </Grid>
                      <Grid item>
                        <Link
                          href={
                            rmaRequest.payload.label_url !== null
                              ? rmaRequest.payload.label_url.split('?GoogleAccessId', 1)[0]
                              : null
                          }
                          target='_blank'
                          rel='noopener noreferrer'
                          textTransform={'none'}
                        >
                          Return Label
                        </Link>
                        <CopyToClipboard
                          copyText={
                            rmaRequest.payload.label_url !== null
                              ? rmaRequest.payload.label_url.split('?GoogleAccessId', 1)[0]
                              : null
                          }
                        />
                      </Grid>
                      <Grid item>
                        <Link
                          href={
                            rmaRequest.payload.qr_code_url !== null
                              ? rmaRequest.payload.qr_code_url.split('?GoogleAccessId', 1)[0]
                              : null
                          }
                          target='_blank'
                          rel='noopener noreferrer'
                          textTransform={'none'}
                        >
                          QR Code
                        </Link>
                        <CopyToClipboard
                          copyText={
                            rmaRequest.payload.qr_code_url !== null
                              ? rmaRequest.payload.qr_code_url.split('?GoogleAccessId', 1)[0]
                              : null
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item textAlign={'right'}>
                <Typography>
                  Return Fee:{' '}
                  {formatPrice(rmaRequest.payload.restocking_fee, order._currencyCode, customer)}
                </Typography>
                <Typography>
                  Estimated Refund:{' '}
                  {formatPrice(rmaRequest.payload.estimated_refund, order._currencyCode, customer)}
                </Typography>
                <Typography variant={'caption'} fontStyle={'italic'}>
                  {refundMethodMap[rmaRequest.payload.refund_method]}
                </Typography>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <DataGrid
        sx={{
          border: 'none',
          padding: '20px',
          paddingTop: 0,
        }}
        rows={rmaRequest.payload.items}
        getRowId={(returnItems) => returnItems['idx']}
        columns={columns}
        autoHeight={true}
        hideFooter={true}
        getRowHeight={() => 'auto'}
        headerHeight={0}
      />
      <Grid
        container
        direction='row'
        justifyContent='flex-end'
        alignItems='flex-end'
        paddingBottom={0}
        paddingTop={0}
        paddingLeft={2}
        paddingRight={0}
        marginTop={'-20px'}
      >
        <Grid item>
          <ReturnMoreMenu
            returnItems={rmaRequest.payload.items}
            rmaNumber={rmaRequest.payload.rma_number}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ReturnCard
