/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'

import { Customer } from '../models/Customer'
import { SalesOrder } from '../models/SalesOrder'

const orderSlice = createSlice({
  name: 'order',
  initialState: {
    name: '',
    id: 0,
    value: new SalesOrder(),
    customer: new Customer(),
    nameList: [],
    fulfilledOrderItems: [],
    unfulfilledOrderItems: [],
    trackingList: [],
    canDuplicateOrder: false,
    netPaymentSet: '',
    currentTotalPriceSet: '',
  },
  reducers: {
    orderAdded(state: any, action) {
      state.name = action.payload.name
      state.id = action.payload.id
      state.value = action.payload.value
      state.fulfilledOrderItems = action.payload.fulfilledOrderItems
      state.trackingList = action.payload.trackingList
      state.nameList = action.payload.nameList
      state.customer = action.payload.customer
      state.canDuplicateOrder = action.payload.canDuplicateOrder
      state.unfulfilledOrderItems = action.payload.unfulfilledOrderItems
    },
    orderUpdated(state: any, action) {
      const changedVal = state
      if (action.payload.type === 'duplicate') {
        changedVal.canDuplicateOrder = action.payload.canDuplicateOrder
      } else if (action.payload.type === 'refund') {
        changedVal.netPaymentSet = action.payload.netPaymentSet
        changedVal.currentTotalPriceSet = action.payload.currentTotalPriceSet
      }
      changedVal.value = action.payload.value
      state = { ...state, changedVal }
    },
    orderReset(state, action) {
      state.name = ''
      state.id = 0
      state.value = new SalesOrder()
      state.customer = new Customer()
      state.nameList = []
      state.fulfilledOrderItems = []
      state.unfulfilledOrderItems = []
      state.trackingList = []
      state.canDuplicateOrder = false
      state.netPaymentSet = ''
      state.currentTotalPriceSet = ''
    },
  },
})

export const { orderAdded, orderUpdated, orderReset } = orderSlice.actions

export default orderSlice.reducer
