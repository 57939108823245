export const CS_COMBINEDRETURN = 'CS-COMBINEDRETURN'
export const CS_CUSTOMERCOUPON = 'CS-CUSTOMERCOUPON'
export const CS_EMPLOYEECOUPON = 'CS-EMPLOYEECOUPON'
export const CS_ESCALATIONS = 'CS-ESCALATIONS'
export const CS_EXCEPTIONS = 'CS-EXCEPTIONS'
export const CS_DAMAGED = 'CS-DAMAGED'
export const CS_DEFECTIVE = 'CS-DEFECTIVE'
export const CS_DELAYEDREFUND = 'CS-DELAYEDREFUND'
export const CS_FRAUD = 'CS-FRAUD'
export const CS_GCORDERCANCEL = 'CS-GCORDERCANCEL'
export const CS_INVENTORY = 'CS-INVENTORY'
export const CS_LOSTPACKAGE = 'CS-LOSTPACKAGE'
export const CS_MISSINGITEM = 'CS-MISSINGITEM'
export const CS_NONRETURN = 'CS-NONRETURN'
export const CS_ORDERCANCEL = 'CS-ORDERCANCEL'
export const CS_PRICEADJUST = 'CS-PRICEADJUST'
export const CS_RTS = 'CS-RTS'
export const CS_RETURNFEE = 'CS-RETURNFEE'
export const CS_SELFPOSTAGE = 'CS-SELFPOSTAGE'
export const CS_STORECREDIT = 'CS-STORECREDIT'
export const CS_SHIPPING = 'CS-SHIPPING'
export const CS_TAXES = 'CS-TAXES'
export const CS_WRONGITEM = 'CS-WRONGITEM'

export const RETURN_FEE = 6

export const fulfilledRefundTypes = [
  CS_COMBINEDRETURN,
  CS_CUSTOMERCOUPON,
  CS_DAMAGED,
  CS_DEFECTIVE,
  CS_DELAYEDREFUND,
  CS_EMPLOYEECOUPON,
  CS_ESCALATIONS,
  CS_EXCEPTIONS,
  CS_FRAUD,
  CS_GCORDERCANCEL,
  CS_INVENTORY,
  CS_LOSTPACKAGE,
  CS_MISSINGITEM,
  CS_NONRETURN,
  CS_ORDERCANCEL,
  CS_PRICEADJUST,
  CS_RETURNFEE,
  CS_RTS,
  CS_SELFPOSTAGE,
  CS_SHIPPING,
  CS_STORECREDIT,
  CS_TAXES,
  CS_WRONGITEM,
]

export const partiallyFulfilledRefundTypes = [
  CS_COMBINEDRETURN,
  CS_CUSTOMERCOUPON,
  CS_DAMAGED,
  CS_DEFECTIVE,
  CS_DELAYEDREFUND,
  CS_EMPLOYEECOUPON,
  CS_ESCALATIONS,
  CS_EXCEPTIONS,
  CS_FRAUD,
  CS_GCORDERCANCEL,
  CS_INVENTORY,
  CS_LOSTPACKAGE,
  CS_MISSINGITEM,
  CS_NONRETURN,
  CS_ORDERCANCEL,
  CS_PRICEADJUST,
  CS_RETURNFEE,
  CS_RTS,
  CS_SELFPOSTAGE,
  CS_SHIPPING,
  CS_STORECREDIT,
  CS_TAXES,
  CS_WRONGITEM,
]

export const unfulfilledRefundTypes = [
  CS_CUSTOMERCOUPON,
  CS_EMPLOYEECOUPON,
  CS_FRAUD,
  CS_GCORDERCANCEL,
  CS_INVENTORY,
  CS_ORDERCANCEL,
  CS_PRICEADJUST,
  CS_SHIPPING,
  CS_STORECREDIT,
  CS_TAXES,
]

export const refundTypesOverThreeMonths = [CS_DAMAGED]
