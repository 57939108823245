import React, { useState } from 'react'

import { ArrowBack, ArrowForward } from '@mui/icons-material'
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  timelineOppositeContentClasses,
} from '@mui/lab'
import { Grid, IconButton, Typography } from '@mui/material'
import { fetchAuthSession } from 'aws-amplify/auth'
import { useParams } from 'react-router-dom'

type OrderEventsProps = {
  eventsGroupedByDay: []
  cursorEvents: string
}

const OrderEvents = ({ eventsGroupedByDay, cursorEvents }: OrderEventsProps) => {
  const [events, setEvents] = useState<any[]>(eventsGroupedByDay)
  const [endCursor, setEndCursor] = useState<string>(cursorEvents)
  const [startCursor, setStartCursor] = useState<string>('')
  const [hasNextPage, setNextPage] = useState(cursorEvents ? true : false)
  const [hasPreviousPage, setPreviousPage] = useState(false)
  const { orderName } = useParams()

  const today = new Date()
    .toLocaleDateString('en-US', {
      timeZone: 'America/Los_Angeles',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
    .toString()

  const groupDataBy = (data: any, attribute: string) => {
    const tmpData = data.reduce((result: any, currentValue: any) => {
      ;(result[currentValue[attribute]] = result[currentValue[attribute]] || []).push(currentValue)
      return result
    }, {})
    return Object.entries(tmpData).map(([key, value]) => ({ [key]: value }))
  }

  const fetchEvents = (path: string) => {
    fetchAuthSession()
      .then((session) => {
        const accesToken = session.tokens?.accessToken?.toString()
        return fetch(
          `${process.env.REACT_APP_AIRBOSS_DOMAIN}/api/v2/order/shopify/${orderName}/events/?${path}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accesToken}`,
            },
          },
        )
      })
      .then((response) => response.json())
      .then((responseData) => {
        const events = responseData
        let tmpEvents: any[] = []
        setEndCursor(events.pageInfo.endCursor)
        setStartCursor(events.pageInfo.startCursor)
        setNextPage(events.pageInfo.hasNextPage)
        setPreviousPage(events.pageInfo.hasPreviousPage)
        if (events.edges.length > 0) {
          events.edges.map((event: any, index: any) => {
            const date = new Date(Date.parse(event.node.createdAt)).toLocaleDateString('en-US', {
              timeZone: 'America/Los_Angeles',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })
            event.node.date = date
            event.node.index = index
            tmpEvents.push(event.node)
          })
          tmpEvents = groupDataBy(tmpEvents, 'date')
          setEvents(tmpEvents)
        }
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  const handleForwardPaginationEvents = () => {
    // Make request for next data set
    fetchEvents(`next_page_events=${endCursor}`)
  }

  const handleBackwardPaginationEvents = () => {
    // Make request for next data set
    fetchEvents(`previous_page_events=${startCursor}`)
  }

  const displayTime = (date: string, timeZone: string) => {
    return new Date(date)
      .toLocaleString('en-US', {
        timeZone: timeZone,
        hour: '2-digit',
        minute: '2-digit',
      })
      .toString()
  }

  return (
    <Grid m={3}>
      <Typography variant='h2' fontSize={20} fontWeight={'bold'} marginTop={'10px'}>
        Timeline
      </Typography>
      <Timeline
        sx={{
          [`& .${timelineOppositeContentClasses.root}`]: {
            flex: 0.2,
          },
        }}
      >
        {events.map((event: any) => {
          return (
            <div key={Object.entries(event)[0][0]}>
              <Typography variant='h3' fontSize={16} marginBottom={'6px'} marginTop={'6px'}>
                {today === Object.entries(event)[0][0] ? 'Today' : Object.entries(event)[0][0]}
              </Typography>
              {event[Object.entries(event)[0][0]].map((step: any) => {
                return (
                  <div key={step.id}>
                    <TimelineItem key={step.id}>
                      <TimelineOppositeContent color='textSecondary'>
                        {displayTime(step.createdAt, 'America/Los_Angeles')}
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Typography variant='body1'>
                          {step.appTitle ? <strong>{step.appTitle}: </strong> : null}
                          {step.message.replace(/(<([^>]+)>)/gi, '')}
                        </Typography>
                      </TimelineContent>
                    </TimelineItem>
                  </div>
                )
              })}
            </div>
          )
        })}
      </Timeline>
      {cursorEvents && (
        <Grid m={2} container direction='row' justifyContent='center' alignItems='center'>
          <IconButton
            aria-label='arrow-back'
            disabled={hasPreviousPage ? false : true}
            onClick={() => handleBackwardPaginationEvents()}
          >
            <ArrowBack />
          </IconButton>
          <IconButton
            aria-label='arrow-forward'
            disabled={hasNextPage ? false : true}
            onClick={() => handleForwardPaginationEvents()}
          >
            <ArrowForward />
          </IconButton>
        </Grid>
      )}
    </Grid>
  )
}

export default OrderEvents
