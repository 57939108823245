import { createSlice } from '@reduxjs/toolkit'

const itemsListSlice = createSlice({
  name: 'itemsList',
  initialState: [],
  reducers: {
    itemsListAdded(state: any, action) {
      const existingitemsList = state.find((object: any) => object.id === action.payload.id)
      if (!existingitemsList) {
        state.push(action.payload)
      }
    },
    itemsListSelected(state: any[], action) {
      state.map((object: any) => {
        object.value.map((itemsList: any) => {
          action.payload.checked
            ? (itemsList.currentShopifyQuantity = itemsList.initialQuantity)
            : (itemsList.currentShopifyQuantity = 0)
        })
      })
      const changedVal = state
      state = [...state, changedVal]
    },
    // eslint-disable-next-line
    reset(state, action) {
      state.length = 0
    },
  },
})

export const { itemsListAdded, itemsListSelected, reset } = itemsListSlice.actions

export default itemsListSlice.reducer
