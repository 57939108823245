/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'

import { Checkroom } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Divider, Grid, List, ListItem, Modal, Typography } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { fetchAuthSession } from 'aws-amplify/auth'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { SalesItem } from '../../../models/SalesItem'
import { Transaction } from '../../../models/Transaction'
import { orderUpdated } from '../../../store/orderSlice'
import { currencyFormatter, getTransactionLabel } from '../../../utils'
import { style } from '../PaymentCard'

import * as Constants from './constants'

const initHeader = 'Refund Confirmation'

type Props = {
  isShown: boolean
  onClose: any
  orderItems: SalesItem[]
  subTotalPrice: string
  shippingPrice: string
  taxPrice: string
  totalOfItems: number
  refundName: string
  refundTotal: number
  notify: boolean
  transactions: Transaction[]
}

const RefundOrderModal = ({
  orderItems,
  isShown,
  onClose,
  subTotalPrice,
  shippingPrice,
  taxPrice,
  totalOfItems,
  refundName,
  refundTotal,
  notify,
  transactions,
}: Props) => {
  const [open, setOpen] = useState<boolean>(isShown)
  const [loading, setLoading] = useState<boolean>(false)
  const [header, setHeader] = useState<string>(initHeader)
  const [message, setMessage] = useState<string>(
    `Are you sure you want to issue a ${refundName} refund?`,
  )
  const [state, setState] = useState<'init' | 'error' | 'success'>('init')
  const order = useSelector((state: any) => state.order.value)
  const customer = useSelector((state: any) => state.order.customer)
  const dispatch = useDispatch()
  const { orderName } = useParams()
  const navigate = useNavigate()

  const style1 = (tmpWidth: number) => {
    return {
      textAlign: 'center',
      position: 'absolute',
      top: '40%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: tmpWidth,
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
    }
  }

  useEffect(() => {
    setOpen(isShown)
    if (!isShown) {
      initState()
    }
  }, [isShown, message, loading, refundName])

  const initState = () => {
    setState('init')
    setHeader(initHeader)
    setMessage(`Are you sure you want to issue a ${refundName} refund?`)
  }

  const fetchOrderDetails = () => {
    fetchAuthSession()
      .then((session) => {
        const accesToken = session.tokens?.accessToken?.toString()
        return fetch(`${process.env.REACT_APP_AIRBOSS_DOMAIN}/api/v2/order/shopify/${orderName}/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accesToken}`,
          },
        })
      })
      .then((response) => response.json())
      .then((responseData) => {
        const tmpOrder = responseData.order
        order._currentTotalPriceSet = tmpOrder.currentTotalPriceSet.presentmentMoney.amount
        order._netPaymentSet = tmpOrder.netPaymentSet.presentmentMoney.amount
        dispatch(
          orderUpdated({
            value: order,
            type: 'refund',
            netPaymentSet: tmpOrder.netPaymentSet.presentmentMoney.amount,
            currentTotalPriceSet: tmpOrder.currentTotalPriceSet.presentmentMoney.amount,
          }),
        )
      })
      .catch((err) => {
        console.error('error', err)
      })
  }

  const createRefund = () => {
    setLoading(true)
    if (refundName !== '' && refundTotal > 0) {
      const tmpTransactions: any[] = []
      if (transactions.length > 0) {
        transactions.map((transaction: any) => {
          if (transaction.tmpAmount > 0) {
            tmpTransactions.push({
              parent_id: transaction.id,
              amount: parseFloat(transaction.tmpAmount).toFixed(2).toString(),
              gateway: transaction.gateway,
              kind: 'REFUND',
            })
          }
        })
      }
      const requestPayload: {
        [key: string]: any
      } = {}
      requestPayload['amount'] = parseFloat(refundTotal.toString()).toFixed(2)
      requestPayload['currency'] = order?._currencyCode
      requestPayload['note'] = refundName
      requestPayload['order_id'] = order?._id
      requestPayload['notify'] = notify
      if (
        totalOfItems > 0 &&
        (order._shippingLine || (!order._shippingLine && refundName == Constants.CS_RTS))
      ) {
        requestPayload['refund_line_items'] = orderItems
      }
      requestPayload['transactions'] = tmpTransactions
      fetchAuthSession()
        .then((session) => {
          const accesToken = session.tokens?.accessToken?.toString()
          return fetch(`${process.env.REACT_APP_AIRBOSS_DOMAIN}/api/v2/refunds/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accesToken}`,
            },
            body: JSON.stringify(requestPayload),
          })
        })
        .then(async (response) => {
          if (response.status === 201) {
            fetchOrderDetails()
            setLoading(false)
            setState('success')
          } else {
            const data = await response.json()
            const hasNote = Object.prototype.hasOwnProperty.call(data, 'note')
            const hasAmount = Object.prototype.hasOwnProperty.call(data, 'amount')
            const hasDetail = Object.prototype.hasOwnProperty.call(data, 'detail')
            const hasRefundLineItems = Object.prototype.hasOwnProperty.call(
              data,
              'refund_line_items',
            )
            if (hasAmount) {
              if (data.amount.length > 0) {
                setMessage(data.amount[0])
              } else {
                setMessage(data.amount)
              }
            } else if (hasNote) {
              if (data.note.length > 0) {
                setMessage(data.note[0])
              } else {
                setMessage(data.note)
              }
            } else if (hasDetail) {
              setMessage(data.detail)
            } else if (hasRefundLineItems) {
              if (data.refund_line_items.length > 0) {
                setMessage(data.refund_line_items[0])
              } else {
                setMessage(data.refund_line_items)
              }
            }
            setState('error')
            setHeader('Woops')
            setLoading(false)
          }
        })
        .catch((err: any) => {
          console.error('error', err)
          setMessage('Unable to process the refund at the moment, please try again later.')
          setHeader('Woops')
          setState('error')
          setLoading(false)
        })
    }
  }

  const CloseRow = () => {
    return (
      <React.Fragment>
        <Grid item paddingBottom={2}>
          <Typography variant='body1'>{message}</Typography>
        </Grid>
        <Grid item>
          <Button variant='contained' color='neutral' onClick={onClose}>
            Close
          </Button>
        </Grid>
      </React.Fragment>
    )
  }

  const closeSuccessModal = () => {
    onClose()
    navigate(`/orders/${orderName}`)
  }

  const SuccessRow = () => {
    return (
      <React.Fragment>
        <Grid>
          <Typography variant='body1' paddingBottom={2}>
            Successfully refunded {formattedPrice(refundTotal.toString())}
          </Typography>
        </Grid>
        <Grid item>
          <Button variant='contained' color='neutral' onClick={closeSuccessModal}>
            Close
          </Button>
        </Grid>
      </React.Fragment>
    )
  }

  const getTotalOfItems = (totalOfItems: number) => {
    return totalOfItems > 1 ? totalOfItems + ' items' : totalOfItems + ' item'
  }

  const FormRow = () => {
    return (
      <React.Fragment>
        {totalOfItems > 0 && orderItems.length > 0 && (
          <div
            style={{
              width: '100%',
              overflowX: 'scroll',
              maxHeight: 400,
            }}
          >
            <DataGrid
              sx={{
                border: 'none',
                padding: '20px',
                width: '100%',
                overflowX: 'scroll',
              }}
              rows={orderItems}
              getRowId={(orderItem) => orderItem.line_item_id}
              columns={columns}
              autoHeight={true}
              hideFooter={true}
              getRowHeight={() => 'auto'}
              headerHeight={0}
            />
            <Divider style={{ width: '100%' }} />
          </div>
        )}
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='flex-start'
          spacing={2}
          sx={{
            margin: '15px auto',
            flexGrow: 1,
          }}
          paddingLeft={1}
          paddingRight={3}
          paddingBottom={1}
        >
          <List sx={style}>
            <TotalLineItemPrice
              text1={'Item subtotal'}
              text2={getTotalOfItems(totalOfItems)}
              text3={formattedPrice(subTotalPrice)}
            />
            <TotalLineItemPrice text1={'Shipping'} text3={formattedPrice(shippingPrice)} />
            <TotalLineItemPrice text1={'Tax'} text3={formattedPrice(taxPrice)} />
            {transactions.length > 0 &&
              transactions
                .filter((transaction: Transaction) => {
                  return transaction.kind !== 'REFUND' && transaction.tmpAmount > 0
                })
                .map((transaction: Transaction, idx: number) => (
                  <div key={idx}>
                    <TotalLineItemPrice
                      text1={getTransactionLabel(transaction)}
                      text3={formattedPrice(transaction.tmpAmount.toString())}
                    />
                  </div>
                ))}
            <TotalLineItemPrice
              text1={'Refund amount'}
              text3={formattedPrice(refundTotal.toString())}
            />
          </List>
          <Typography variant='body1' sx={{ fontStyle: 'italic' }}>
            {message}
          </Typography>
        </Grid>
        <Grid container direction='row' justifyContent='center' alignItems='center'>
          <Grid item xs={6} paddingLeft={0}>
            <Button variant='contained' color='neutral' onClick={onClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6} paddingLeft={0}>
            <LoadingButton
              type='submit'
              color='primary'
              size='medium'
              loading={loading}
              variant='contained'
              disabled={loading}
              sx={{ mt: 3, mb: 2 }}
              onClick={createRefund}
            >
              Confirm
            </LoadingButton>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }

  const ConditionalRow = () => {
    if (state === 'init') {
      return <FormRow />
    } else if (state === 'success') {
      return <SuccessRow />
    } else {
      return <CloseRow />
    }
  }

  const formattedPrice = (price: any) => {
    const formattedPrice = currencyFormatter(customer, order).format(price)
    return order?._currencyCode !== 'USD'
      ? formattedPrice + ' ' + order?._currencyCode
      : formattedPrice
  }

  const getCurrencySymbol = () => {
    return order?._currencyCode !== 'USD' ? ' ' + order?._currencyCode : ''
  }

  const columns: GridColDef[] = [
    {
      field: 'Product',
      flex: 1,
      minWidth: 350,
      maxWidth: 600,
      renderCell: (params) => (
        <Grid container direction='row' justifyContent='flex-start' alignItems='center'>
          <Grid item xs={3}>
            {params.row.srcImage ? (
              <img
                src={params.row.srcImage}
                style={{
                  height: '100%',
                  width: '100%',
                  maxHeight: '100px',
                  maxWidth: '100px',
                  minHeight: '80px',
                  minWidth: '80px',
                }}
                alt={params.row.variantTitle}
              />
            ) : (
              <Checkroom />
            )}
          </Grid>
          <Grid item padding={'1rem'} xs={9}>
            <Typography>{params.row.title}</Typography>
            <Typography>SKU: {params.row.sku}</Typography>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'Quantity',
      flex: 1,
      minWidth: 100,
      align: 'right',
      renderCell: (params) => (
        <Grid container direction='row' justifyContent='flex-end' alignItems='center'>
          <Grid item padding={'0.5rem'}>
            <Typography>
              {formattedPrice(params.row.price) + getCurrencySymbol() + ' x ' + params.row.quantity}
            </Typography>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'Unit price',
      flex: 1,
      align: 'right',
      renderCell: (params) => (
        <Box>
          <Typography>
            {formattedPrice(parseFloat(params.row.price) * params.row.quantity)}{' '}
          </Typography>
        </Box>
      ),
    },
  ]

  const TotalLineItemPrice = ({ text1, text2, text3, fontWeight = 400 }: any) => {
    return (
      <ListItem>
        <Grid item xs={3}>
          <Typography sx={{ fontWeight: fontWeight }}>{text1}</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography sx={{ fontWeight: fontWeight }}>{text2}</Typography>
        </Grid>
        <Grid item xs={4} justifyContent='flex-end'>
          <Typography textAlign='right' sx={{ fontWeight: fontWeight }}>
            {text3}
          </Typography>
        </Grid>
      </ListItem>
    )
  }

  return (
    <Modal
      id='refund-order-confirmation-modal'
      open={open}
      aria-labelledby='refund-order-modal-title'
      aria-describedby='refund-order-modal-description'
      onClose={onClose}
    >
      <Box sx={style1(state === 'init' ? 700 : 400)} id='refund-order-confirmation-box'>
        <Grid container direction='column' justifyContent='center' alignItems='center' spacing={2}>
          <Grid item>
            <Typography variant='h2' fontSize={'1.5rem'}>
              {header}
            </Typography>
          </Grid>
          <Grid item width={'100%'}>
            <Grid container direction='column' justifyContent='center' alignItems='center'>
              <ConditionalRow />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}

export default RefundOrderModal
