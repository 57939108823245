/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'

import { Checkroom } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Divider, Grid, Link, List, ListItem, Modal, Typography } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { fetchAuthSession } from 'aws-amplify/auth'
import { useParams } from 'react-router-dom'

import { getAddressRequestDict } from '../../models/Address'
import { getCustomerRequestDict } from '../../models/Customer'
import { SalesItem } from '../../models/SalesItem'

import { style } from './PaymentCard'

const initHeader = 'Duplicate Confirmation'

type Props = {
  customer: any
  order: any
  orderItems: SalesItem[]
  isShown: boolean
  onClose: any
  onSubmitSuccess: () => void
  shippingTitle: any
}

const DuplicateOrderModal = ({
  customer,
  order,
  orderItems,
  isShown,
  onClose,
  onSubmitSuccess,
  shippingTitle,
}: Props) => {
  const { orderName } = useParams()
  const [duplicatedOrderName, setDuplicatedOrderName] = useState<string>('')
  const [link, setLink] = useState<string>('')
  const [open, setOpen] = useState<boolean>(isShown)
  const [loading, setLoading] = useState<boolean>(false)
  const [header, setHeader] = useState<string>(initHeader)
  const [message, setMessage] = useState<string>(`Are you sure you want to duplicate ${orderName}?`)
  const [state, setState] = useState<'init' | 'error' | 'success'>('init')
  const [selectedItems, setSelectedItems] = useState<SalesItem[]>([])

  useEffect(() => {
    const tmpOrderItems: any = []
    setOpen(isShown)
    if (!isShown) {
      initState()
    }
    orderItems.map((object: any) => {
      object.map((itemsList: any) => {
        if (itemsList.currentShopifyQuantity > 0 && itemsList.quantityInventory > 0) {
          tmpOrderItems.push(itemsList)
        }
      })
    })
    setSelectedItems(tmpOrderItems)
  }, [isShown, message, loading])

  const style1 = (tmpWidth: number) => {
    return {
      textAlign: 'center',
      position: 'absolute',
      top: '40%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: tmpWidth,
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
    }
  }

  const initState = () => {
    setState('init')
    setHeader(initHeader)
    setMessage(`Are you sure you want to duplicate ${orderName}?`)
  }

  const setUnknownErrorState = () => {
    setState('error')
    setHeader('Woops')
    setMessage('Something went wrong, unable to duplicate order.')
  }

  const setUnknownErrorState1 = () => {
    setState('error')
    setHeader('Woops')
    setMessage('Something went wrong, unable to update additional notes.')
  }

  const createDuplicateOrder = () => {
    setLoading(true)
    if (order?._country === 'United States') {
      const requestPayload = {
        original_order_name: orderName,
        original_order_id: order?._id,
        contact_email: customer?._email,
        customer: getCustomerRequestDict(customer),
        line_items: selectedItems.map((lineItem) => {
          return {
            id: lineItem.id,
            sku: lineItem.sku,
            title: lineItem.title,
            quantity: lineItem.currentShopifyQuantity,
            variant_title: lineItem.variantTitle,
            price: lineItem.price,
            product_id: lineItem.productId,
            variant_id: lineItem.variantId,
          }
        }),
        billing_address: getAddressRequestDict(customer?._billingAddress),
        shipping_address: getAddressRequestDict(customer?._shippingAddress),
        currency: 'USD',
        shipping_lines: [
          {
            price: '0',
            title: shippingTitle,
            code: shippingTitle,
          },
        ],
      }
      fetchAuthSession()
        .then((session) => {
          const accesToken = session.tokens?.accessToken?.toString()
          return fetch(
            `${process.env.REACT_APP_AIRBOSS_DOMAIN}/api/v2/order/shopify/${orderName}/duplicate/`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accesToken}`,
              },
              body: JSON.stringify(requestPayload),
            },
          )
        })
        .then((response) => response.json())
        .then((data) => {
          const hasOrderProper = Object.prototype.hasOwnProperty.call(data, 'order')
          const hasOriginalOrderName = Object.prototype.hasOwnProperty.call(
            data,
            'original_order_name',
          )
          if (hasOrderProper) {
            ;(async () => {
              await updateOrderCustomAttributes(
                data.order.id,
                'CSDuplicatedParentOrder',
                `${orderName}- ${order?._id}`,
              )
              await updateOrderCustomAttributes(
                order?._id,
                'CSDuplicatedOrder',
                `${data.order.name}- ${data.order.id}`,
              )
              onSubmitSuccess()
              setDuplicatedOrderName(data.order.name)
              setLink(`${process.env.REACT_APP_ORDER_LINK_ON_SHOPIFY}${data.order.id}`)
              setState('success')
              setHeader('Success')
              setLoading(false)
            })()
          } else if (hasOriginalOrderName) {
            setState('error')
            setHeader('Error')
            setMessage(data.original_order_name[0])
            setLoading(false)
          } else {
            setUnknownErrorState()
            setLoading(false)
          }
        })
        .catch((err: any) => {
          console.log('error', err)
          setUnknownErrorState()
          setLoading(false)
        })
    } else {
      setUnknownErrorState()
    }
  }

  const updateOrderCustomAttributes = async (tmpOrderName: any, key: string, value: any) => {
    const requestPayload = {
      key: key,
      value: value,
    }
    try {
      const { tokens } = await fetchAuthSession()
      const accessToken = tokens?.accessToken.toString()

      const response = await fetch(
        `${process.env.REACT_APP_AIRBOSS_DOMAIN}/api/v2/order/shopify/${tmpOrderName}/add_note/`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(requestPayload),
        },
      )
      if (response.status !== 200) {
        setUnknownErrorState1()
      }
    } catch (err) {
      setUnknownErrorState1()
    }
  }

  const CloseRow = () => {
    return (
      <React.Fragment>
        <Grid item paddingBottom={2}>
          <Typography variant='body1'>{message}</Typography>
        </Grid>
        <Grid item>
          <Button variant='contained' color='neutral' onClick={onClose}>
            Close
          </Button>
        </Grid>
      </React.Fragment>
    )
  }

  const SuccessRow = () => {
    return (
      <React.Fragment>
        <Grid>
          <Typography variant='body1'>
            Order{' '}
            <Link href={link} target='_blank' rel='noopener noreferrer'>
              {duplicatedOrderName}
            </Link>{' '}
            successfully created
          </Typography>
        </Grid>
      </React.Fragment>
    )
  }

  const columns: GridColDef[] = [
    {
      field: 'Product',
      flex: 1,
      minWidth: 350,
      maxWidth: 600,
      renderCell: (params) => (
        <Grid container direction='row' justifyContent='flex-start' alignItems='center'>
          <Grid item xs={3}>
            {params.row.srcImage ? (
              <img
                src={params.row.srcImage}
                style={{
                  height: '100%',
                  width: '100%',
                  maxHeight: '100px',
                  maxWidth: '100px',
                  minHeight: '80px',
                  minWidth: '80px',
                }}
                alt={params.row.variantTitle}
              />
            ) : (
              <Checkroom />
            )}
          </Grid>
          <Grid item padding={'1rem'} xs={9}>
            <Typography>{params.row.title}</Typography>
            <Typography>SKU: {params.row.sku}</Typography>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'Quantity',
      flex: 1,
      minWidth: 100,
      align: 'right',
      renderCell: (params) => (
        <Grid container direction='row' justifyContent='flex-end' alignItems='center'>
          <Grid item padding={'0.5rem'}>
            <Typography>{params.row.currentShopifyQuantity}</Typography>
          </Grid>
        </Grid>
      ),
    },
  ]

  const LineDetails = ({ text1, text2, fontWeight = 400 }: any) => {
    return (
      <ListItem>
        <Grid item xs={3}>
          <Typography sx={{ fontWeight: fontWeight }}>{text1}</Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography sx={{ fontWeight: fontWeight }}>{text2}</Typography>
        </Grid>
      </ListItem>
    )
  }

  const FormRow = () => {
    return (
      <React.Fragment>
        {selectedItems.length > 0 ? (
          <div
            style={{
              width: '100%',
              overflowX: 'scroll',
              maxHeight: 400,
            }}
          >
            <DataGrid
              sx={{
                border: 'none',
                padding: '20px',
                width: '100%',
                overflowX: 'scroll',
              }}
              rows={selectedItems}
              getRowId={(orderItem) => orderItem.id}
              columns={columns}
              autoHeight={true}
              hideFooter={true}
              getRowHeight={() => 'auto'}
              headerHeight={0}
            />
            <Divider style={{ width: '100%' }} />
          </div>
        ) : (
          <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
            There are no line items. Please select items to create a duplicate order.
          </Typography>
        )}
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='flex-start'
          spacing={2}
          sx={{
            margin: '15px auto',
            flexGrow: 1,
          }}
          paddingLeft={1}
          paddingRight={3}
          paddingBottom={1}
        >
          <List sx={style}>
            <LineDetails text1={'Shipping method:'} text2={shippingTitle} />
            <ListItem>
              <Grid item xs={3}>
                <Typography sx={{ fontWeight: 400 }}>{'Shipping address:'}</Typography>
              </Grid>
              <Grid item xs={7} paddingLeft={1}>
                <Grid>{customer?._shippingAddress.address1}</Grid>
                {customer?._shippingAddress.address2 && (
                  <Grid>{customer?._shippingAddress.address2}</Grid>
                )}
                <Grid>
                  {customer?._shippingAddress.city}, {customer?._shippingAddress.provinceCode}{' '}
                  {customer?._shippingAddress.zip}
                </Grid>
              </Grid>
            </ListItem>
          </List>
          <Typography variant='body1' sx={{ fontStyle: 'italic' }}>
            {message}
          </Typography>
        </Grid>
        <Grid container direction='row' justifyContent='center' alignItems='center'>
          <Grid item xs={6} paddingLeft={0}>
            <Button variant='contained' color='neutral' onClick={onClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6} paddingLeft={0}>
            <LoadingButton
              type='submit'
              color='primary'
              size='medium'
              loading={loading}
              variant='contained'
              disabled={loading || selectedItems.length === 0}
              onClick={createDuplicateOrder}
              sx={{ mt: 3, mb: 2 }}
            >
              Confirm
            </LoadingButton>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }

  const ConditionalRow = () => {
    if (state === 'init') {
      return <FormRow />
    } else if (state === 'success') {
      return <SuccessRow />
    } else {
      return <CloseRow />
    }
  }

  return (
    <Modal
      id='duplicate-order-confirmation-modal'
      open={open}
      aria-labelledby='duplicate-order-modal-title'
      aria-describedby='duplicate-order-modal-description'
      onClose={onClose}
    >
      <Box sx={style1(state === 'init' ? 600 : 400)} id='duplicate-order-confirmation-box'>
        <Grid container direction='column' justifyContent='center' alignItems='center' spacing={2}>
          <Grid item>
            <Typography variant='h2' fontSize={'1.5rem'}>
              {header}
            </Typography>
          </Grid>
          <Grid item width={'100%'}>
            <Grid container direction='column' justifyContent='center' alignItems='center'>
              <ConditionalRow />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}

export default DuplicateOrderModal
