import React, { useEffect, useState } from 'react'

import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Modal,
  Typography,
} from '@mui/material'
import { fetchAuthSession } from 'aws-amplify/auth'

import { SalesOrder } from '../../models/SalesOrder'

const initHeader = 'Edit Return Tags'

type Props = {
  isShown: boolean
  onClose: any
  order: SalesOrder
}

const OrderTagsModal = ({ isShown, onClose, order }: Props) => {
  const [open, setOpen] = useState<boolean>(isShown)
  const [loading, setLoading] = useState<boolean>(false)
  const [header, setHeader] = useState<string>(initHeader)
  const [message, setMessage] = useState<string>(``)
  const [state, setState] = useState<'init' | 'error' | 'success'>('init')
  const [tags, setTags] = useState<string[]>([])
  const returnTags = ['csreturnfee', 'csextension', 'csoverride']

  useEffect(() => {
    setOpen(isShown)
    if (!isShown) {
      if (order?._tags) {
        setTags(
          order?._tags.filter((currentTag: string) => {
            return returnTags.find((returnTag: string) => currentTag === returnTag)
          }),
        )
      }
      initState()
    }
  }, [isShown, loading, message])

  const initState = () => {
    setState('init')
    setHeader(initHeader)
    setMessage(`Are you sure you want to edit this order's return tags?`)
  }
  const setUnknownErrorState = () => {
    setState('error')
    setHeader('Woops')
    setMessage("Something went wrong, unable to edit this order's return tags")
  }

  const updateReturnTags = () => {
    setLoading(true)
    fetchAuthSession()
      .then((session) => {
        const accesToken = session.tokens?.accessToken?.toString()
        return fetch(
          `${process.env.REACT_APP_AIRBOSS_DOMAIN}/api/v2/order/shopify/${order?._id}/tags/`,
          {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accesToken}`,
            },
            body: JSON.stringify({ tags: tags }),
          },
        )
      })
      .then((response) => {
        if (response.status !== 200) {
          setUnknownErrorState()
        } else if (response.status === 200) {
          setLoading(false)
          onClose()
          window.location.reload()
        }
        setLoading(false)
      })
      .catch((err: any) => {
        console.log('error', err)
        setUnknownErrorState()
        setLoading(false)
      })
  }

  const handleChange = (e: any) => {
    const { value, checked } = e.target
    const tmpTags = [...tags]
    if (checked) {
      setTags([...tmpTags, value])
    } else {
      setTags(tmpTags.filter((e: any) => e !== value))
    }
  }

  const orderTagModalStyle = (tmpWidth: number) => {
    return {
      textAlign: 'center',
      position: 'absolute',
      top: '40%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: tmpWidth,
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
    }
  }

  const FormRow = () => {
    return (
      <React.Fragment>
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='flex-start'
          spacing={2}
          sx={{
            margin: '15px auto',
            flexGrow: 1,
          }}
          paddingLeft={1}
          paddingRight={3}
          paddingBottom={1}
        >
          <Grid container direction='row' justifyContent='flex-start'>
            <FormControl sx={{ m: 3 }} component='fieldset' variant='standard'>
              {returnTags.map((returnTag: string) => (
                <FormControlLabel
                  key={returnTag}
                  control={<Checkbox />}
                  onChange={handleChange}
                  value={returnTag}
                  checked={tags.includes(returnTag)}
                  label={returnTag}
                />
              ))}
            </FormControl>
          </Grid>
          <Typography variant='body1' sx={{ fontStyle: 'italic' }}>
            {message}
          </Typography>
        </Grid>
        <Grid container direction='row' justifyContent='center' alignItems='center'>
          <Grid item xs={6} paddingLeft={0}>
            <Button variant='contained' color='neutral' onClick={onClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6} paddingLeft={0}>
            <LoadingButton
              type='submit'
              color='primary'
              size='medium'
              loading={loading}
              variant='contained'
              sx={{ mt: 3, mb: 2 }}
              onClick={updateReturnTags}
            >
              Confirm
            </LoadingButton>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }

  const CloseRow = () => {
    return (
      <React.Fragment>
        <Grid item paddingBottom={2}>
          <Typography variant='body1'>{message}</Typography>
        </Grid>
        <Grid item>
          <Button variant='contained' color='neutral' onClick={onClose}>
            Close
          </Button>
        </Grid>
      </React.Fragment>
    )
  }

  const SuccessRow = () => {
    return (
      <React.Fragment>
        <Grid>
          <Typography variant='body1'>successfully edited</Typography>
        </Grid>
      </React.Fragment>
    )
  }

  const ConditionalRow = () => {
    if (state === 'init') {
      return <FormRow />
    } else if (state === 'success') {
      return <SuccessRow />
    } else {
      return <CloseRow />
    }
  }

  return (
    <Modal
      id='order-tags-modal'
      open={open}
      aria-labelledby='order-tags-modal-title'
      aria-describedby='order-tags-modal-description'
      onClose={onClose}
    >
      <Box sx={orderTagModalStyle(state === 'init' ? 600 : 400)} id='order-tags-box'>
        <Grid container direction='column' justifyContent='center' alignItems='center' spacing={2}>
          <Grid item>
            <Typography variant='h2' fontSize={'1.5rem'}>
              {header}
            </Typography>
          </Grid>
          <Grid item width={'100%'}>
            <Grid container direction='column' justifyContent='center' alignItems='center'>
              <ConditionalRow />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}

export default OrderTagsModal
