// Sales Order
export class ShippingLine {
  public _title: string = ''
  public _taxLines: any = []

  get title(): string {
    return this._title
  }

  set title(newTitle: string) {
    this._title = newTitle
  }

  get taxLines(): any {
    return this._taxLines
  }

  set taxLines(newTaxLines: any) {
    this._taxLines = newTaxLines
  }
}
