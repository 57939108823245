// Sales Order
export class SalesOrder {
  public _id: number = 0
  public _country: string = ''
  public _createdAt: string = ''
  public _cancelledAt: string = ''
  public _authorizationExpiresAt: string = ''
  public _status: string = ''
  public _omsStatus: string = ''
  public _fulfillmentStatus: string = ''
  public _financialStatus: string = ''
  public _trackingNumber: string = ''
  public _tags: any = []
  public _note: string = ''
  public _additionalNotes: any = []
  public _appName: string = ''
  public _shipMemos: any = []
  public _upsShipUpdates: any = []
  public _totalPriceSet: string = ''
  public _totalOutstandingSet: string = ''
  public _totalRefundedSet: string = ''
  public _totalRefundedShippingSet: string = ''
  public _totalShippingPriceSet: string = ''
  public _currentSubtotalPriceSet: string = ''
  public _currentTotalDiscountsSet: string = ''
  public _currentTotalTaxSet: string = ''
  public _currentTotalPriceSet: string = ''
  public _netPaymentSet: string = ''
  public _shippingMethod: string = ''
  public _paymentMethod: string = ''
  public _discountCode: string = ''
  public _currencyCode: string = ''
  public _refunds: any = []
  public _totalWeight: number = 0
  public _currentSubtotalLineItemsQuantity: number = 0
  public _taxLines: any = []
  public _currencySign: any = null
  public _events: any = []
  public _transactions: any = []
  public _rmaRequests: any = []
  public _shippingLine: any = null
  public _returns: any = []
  public _statusPageUrl: string = ''

  get id(): number {
    return this._id
  }

  set id(newId: number) {
    this._id = newId
  }

  get country(): string {
    return this._country
  }

  set country(newCountry: string) {
    this._country = newCountry
  }

  get createdAt(): string {
    return this._createdAt
  }

  set createdAt(newCreatedAt: string) {
    this._createdAt = newCreatedAt
  }

  get cancelledAt(): string {
    return this._cancelledAt
  }

  set cancelledAt(newCancelledAt: string) {
    this._cancelledAt = newCancelledAt
  }

  get authorizationExpiresAt(): string {
    return this._authorizationExpiresAt
  }

  set authorizationExpiresAt(newAuthorizationExpiresAt: string) {
    this._authorizationExpiresAt = newAuthorizationExpiresAt
  }

  get status(): string {
    return this._status
  }

  set status(newStatus: string) {
    this._status = newStatus
  }

  get omsStatus(): string {
    return this._omsStatus
  }

  set omsStatus(newOMSStatus: string) {
    this._omsStatus = newOMSStatus
  }

  get tags(): any {
    return this._tags
  }

  set tags(newTags: any) {
    this._tags = newTags
  }

  set trackingNumber(newTrackingInfo: string) {
    this._trackingNumber = newTrackingInfo
  }

  get trackingNumber(): string {
    return this._trackingNumber
  }

  set fulfillmentStatus(newFulfillmentStatus: string) {
    this._fulfillmentStatus = newFulfillmentStatus
  }

  get fulfillmentStatus(): string {
    return this._fulfillmentStatus
  }

  set financialStatus(newFinancialStatus: string) {
    this._financialStatus = newFinancialStatus
  }

  get financialStatus(): string {
    return this._financialStatus
  }

  set note(newNote: string) {
    this._note = newNote
  }

  get note(): string {
    return this._note
  }

  get additionalNotes(): any {
    return this._additionalNotes
  }

  set additionalNotes(newAdditionalNotes: any) {
    this._additionalNotes = newAdditionalNotes
  }

  get appName(): string {
    return this._appName
  }

  set appName(newAppName: string) {
    this._appName = newAppName
  }

  get shipMemos(): any {
    return this._shipMemos
  }

  set shipMemos(newShipMemos: any) {
    this._shipMemos = newShipMemos
  }

  get upsShipUpdates(): any {
    return this._upsShipUpdates
  }

  set upsShipUpdates(newUpsShipUpdates: any) {
    this._upsShipUpdates = newUpsShipUpdates
  }

  get totalPriceSet(): string {
    return this._totalPriceSet
  }

  set totalPriceSet(newTotalPriceSet: string) {
    this._totalPriceSet = newTotalPriceSet
  }

  get totalOutstandingSet(): string {
    return this._totalOutstandingSet
  }

  set totalOutstandingSet(newTotalOutstandingSet: string) {
    this._totalOutstandingSet = newTotalOutstandingSet
  }

  get totalRefundedSet(): string {
    return this._totalRefundedSet
  }

  set totalRefundedSet(newTotalRefundedSet: string) {
    this._totalRefundedSet = newTotalRefundedSet
  }

  get totalRefundedShippingSet(): string {
    return this._totalRefundedShippingSet
  }

  set totalRefundedShippingSet(newTotalRefundedShippingSet: string) {
    this._totalRefundedShippingSet = newTotalRefundedShippingSet
  }

  get totalShippingPriceSet(): string {
    return this._totalShippingPriceSet
  }

  set totalShippingPriceSet(newTotalShippingPriceSet: string) {
    this._totalShippingPriceSet = newTotalShippingPriceSet
  }

  get currentTotalDiscountsSet(): string {
    return this._currentTotalDiscountsSet
  }

  set currentTotalDiscountsSet(newCurrentTotalDiscountsSet: string) {
    this._currentTotalDiscountsSet = newCurrentTotalDiscountsSet
  }

  get currentTotalTaxSet(): string {
    return this._currentTotalTaxSet
  }

  set currentTotalTaxSet(newCurrentTotalTaxSet: string) {
    this._currentTotalTaxSet = newCurrentTotalTaxSet
  }

  get currentTotalPriceSet(): string {
    return this._currentTotalPriceSet
  }

  set currentTotalPriceSet(newCurrentTotalPriceSet: string) {
    this._currentTotalPriceSet = newCurrentTotalPriceSet
  }

  get currentSubtotalPriceSet(): string {
    return this._currentSubtotalPriceSet
  }

  set currentSubtotalPriceSet(newCurrentSubtotalPriceSet: string) {
    this._currentSubtotalPriceSet = newCurrentSubtotalPriceSet
  }

  get netPaymentSet(): string {
    return this._netPaymentSet
  }

  set netPaymentSet(newNetPaymentSet: string) {
    this._netPaymentSet = newNetPaymentSet
  }

  get totalWeight(): number {
    return this._totalWeight
  }

  set totalWeight(newTotalWeight: number) {
    this._totalWeight = newTotalWeight
  }

  get currentSubtotalLineItemsQuantity(): number {
    return this._currentSubtotalLineItemsQuantity
  }

  set currentSubtotalLineItemsQuantity(newCurrentSubtotalLineItemsQuantity: number) {
    this._currentSubtotalLineItemsQuantity = newCurrentSubtotalLineItemsQuantity
  }

  get shippingMethod(): string {
    return this._shippingMethod
  }

  set shippingMethod(newShippingMethod: string) {
    this._shippingMethod = newShippingMethod
  }

  get paymentMethod(): string {
    return this._paymentMethod
  }

  set paymentMethod(newPaymentMethod: string) {
    this._paymentMethod = newPaymentMethod
  }

  get discountCode(): string {
    return this._discountCode
  }

  set discountCode(newDiscountCode: string) {
    this._discountCode = newDiscountCode
  }

  get currencyCode(): string {
    return this._currencyCode
  }

  set currencyCode(newCurrencyCode: string) {
    this._currencyCode = newCurrencyCode
  }

  get refunds(): any {
    return this._refunds
  }

  set refunds(newRefunds: any) {
    this._refunds = newRefunds
  }

  get taxLines(): any {
    return this._taxLines
  }

  set taxLines(newTaxLines: any) {
    this._taxLines = newTaxLines
  }

  get currencySign(): any {
    return this._currencySign
  }

  set currencySign(newCurrencySign: any) {
    this._currencySign = newCurrencySign
  }

  get events(): any {
    return this._events
  }

  set events(newEvents: any) {
    this._events = newEvents
  }

  get transactions(): any {
    return this._transactions
  }

  set transactions(newTransactions: any) {
    this._transactions = newTransactions
  }

  get rmaRequests(): any {
    return this._rmaRequests
  }

  set rmaRequests(newRMARequests: any) {
    this._rmaRequests = newRMARequests
  }

  get shippingLine(): any {
    return this._shippingLine
  }

  set shippingLine(newShippingLine: any) {
    this._shippingLine = newShippingLine
  }

  get returns(): any {
    return this._returns
  }

  set returns(newReturns: any) {
    this._returns = newReturns
  }

  get statusPageUrl(): string {
    return this._statusPageUrl
  }

  set statusPageUrl(newStatusPageUrl: string) {
    this._statusPageUrl = newStatusPageUrl
  }
}
