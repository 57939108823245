import React from 'react'

import RocketLaunchIcon from '@mui/icons-material/RocketLaunch'
import { Box, Grid, Paper, Typography } from '@mui/material'

const Home = () => {
  const username: string | null = localStorage.getItem('username')
  return (
    <Box
      sx={{
        pt: 5,
      }}
    >
      <Paper
        sx={{
          p: 2,
          margin: 'auto',
          maxWidth: 500,
          flexGrow: 1,
          backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#1A2027' : '#fff'),
        }}
      >
        <Grid container spacing={2} sx={{ textAlign: 'center' }}>
          <Grid item xs={12} sm container>
            <Grid item xs container direction='column' spacing={2}>
              <Grid item xs>
                <Typography gutterBottom variant='h1' component='div' sx={{ fontSize: '2rem' }}>
                  Hello {username}. Welcome to Apollo!
                </Typography>
                <Box>
                  <RocketLaunchIcon className='icon-button' sx={{ fontSize: 60 }} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default Home
