import React, { useEffect, useState } from 'react'

import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
  Theme,
  useTheme,
} from '@mui/material'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'

import { Transaction } from '../../models/Transaction'
import { isCreatedOverThreeMonths } from '../../utils'

import * as Constants from './constants'

const actions = ['Duplicate', 'Refund']

function getStyles(action: string, actionName: string, theme: Theme) {
  return {
    fontWeight:
      actionName === action
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  }
}

const OrderActions = () => {
  const canDuplicateOrder = useSelector((state: any) => state.order.canDuplicateOrder)
  const customer = useSelector((state: any) => state.order.customer)
  const order = useSelector((state: any) => state.order.value)
  const theme = useTheme()
  const [actionName, setAction] = useState<string>('')
  const navigate = useNavigate()
  const { orderName } = useParams()

  useEffect(() => {
    actions.map((action: any) => {
      if (window.location.pathname.includes(action.toLowerCase())) {
        setAction(action)
      }
    })
  }, [])

  const handleChange = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event
    setAction(value)
    if (value === 'Duplicate') {
      navigate(`/orders/${orderName}/duplicate`)
    } else if (value === 'Refund') {
      navigate(`/orders/${orderName}/refund`)
    }
  }

  const isAnInternationalOrder = (country: string) => {
    return country !== undefined && country !== 'United States'
  }

  const hasDuplicateTag = (tags: string) => {
    return tags.includes('CS DUPLICATED ORDER')
  }

  const HasACashPayment = () => {
    let cash = false
    order._transactions.some((transaction: Transaction) => {
      if (transaction.gateway === 'cash') {
        cash = true
      }
    })
    return cash
  }

  const verifyRefundForNormalOrders = () => {
    return (
      order._appName !== Constants.POS &&
      (isAnInternationalOrder(customer._shippingAddress?.country) ||
        hasDuplicateTag(order._tags) ||
        isCreatedOverThreeMonths(order._createdAt))
    )
  }

  const verifyRefundForPosOrders = () => {
    return (
      order._appName === Constants.POS &&
      (order._financialStatus === 'AUTHORIZED' || HasACashPayment())
    )
  }

  const isDisabled = (action: string) => {
    if (action === 'Duplicate') {
      return !canDuplicateOrder
    } else if (
      action === 'Refund' &&
      (order._financialStatus === 'REFUNDED' ||
        verifyRefundForNormalOrders() ||
        verifyRefundForPosOrders() ||
        isAnInternationalOrder(customer._shippingAddress?.country) ||
        hasDuplicateTag(order._tags) ||
        (isCreatedOverThreeMonths(order._createdAt) && order._fulfillmentStatus === 'UNFULFILLED'))
    ) {
      return true
    }
    return false
  }

  return (
    <FormControl sx={{ m: 0, width: 150, mt: 0, display: 'flex' }} size='small'>
      <InputLabel id='order-actions-label'>Action</InputLabel>
      <Select
        labelId='order-actions-label'
        id='order-actions'
        value={actionName}
        label='Action'
        onChange={handleChange}
      >
        {actions.map((action) => (
          <MenuItem
            disabled={isDisabled(action)}
            key={action}
            value={action}
            style={getStyles(action, actionName, theme)}
          >
            {action}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default OrderActions
