import React, { useState } from 'react'

import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
  Theme,
  useTheme,
} from '@mui/material'
import { useSelector } from 'react-redux'

import { isCreatedOverThreeMonths } from '../../../utils'

import * as Constants from './constants'

function getStyles(refund: string, refundName: string, theme: Theme) {
  return {
    fontWeight:
      refundName === refund
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  }
}

type RefundOptionsProps = {
  fulfillmentStatus: string
  setNameRefund: (value: string) => void
}

const RefundOptions = ({ fulfillmentStatus, setNameRefund }: RefundOptionsProps) => {
  const theme = useTheme()
  const [refundName, setRefund] = useState<string>('')
  const order = useSelector((state: any) => state.order.value)
  let refundTypes: any[] = []
  switch (fulfillmentStatus) {
    case 'FULFILLED':
      refundTypes = Constants.fulfilledRefundTypes
      break
    case 'UNFULFILLED':
      refundTypes = Constants.unfulfilledRefundTypes
      break
    case 'PARTIALLY_FULFILLED':
      refundTypes = Constants.partiallyFulfilledRefundTypes
      break
  }

  if (
    isCreatedOverThreeMonths(order._createdAt) &&
    (fulfillmentStatus === 'FULFILLED' || fulfillmentStatus === 'PARTIALLY_FULFILLED')
  ) {
    refundTypes = Constants.refundTypesOverThreeMonths
  }

  const handleChange = (event: SelectChangeEvent<typeof refundName>) => {
    const {
      target: { value },
    } = event
    setRefund(value)
    setNameRefund(value)
  }

  return (
    <FormControl sx={{ m: 0, width: 200, mr: 1, display: 'flex' }} size='small'>
      <InputLabel id='refund-types-label'>Refund Type</InputLabel>
      <Select
        labelId='refund-types-label'
        id='refund-types'
        value={refundName}
        label='Refund Type'
        onChange={handleChange}
      >
        {refundTypes.map((refund: any) => (
          <MenuItem key={refund} value={refund} style={getStyles(refund, refundName, theme)}>
            {refund}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default RefundOptions
