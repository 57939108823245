import React, { Dispatch, SetStateAction, useState } from 'react'

import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
  Theme,
  useTheme,
} from '@mui/material'

import * as Constants from './constants'

const allowedPercentages = ['15', '20', '25', '30', '33', '100']

function getStyles(percent: string, tmpPercent: string, theme: Theme, refundName: string) {
  return {
    fontWeight:
      tmpPercent === percent
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
    display:
      refundName !== Constants.CS_DAMAGED && (percent === '33' || percent === '100')
        ? 'none'
        : 'block',
  }
}

type PercentOptionsProps = {
  setPercent: Dispatch<SetStateAction<number>>
  refundName: string
}

const PercentOptions = ({ setPercent, refundName }: PercentOptionsProps) => {
  const theme = useTheme()
  const [tmpPercent, setTmpPercent] = useState<string>('')

  const handleChange = (event: SelectChangeEvent<string>) => {
    const {
      target: { value },
    } = event
    setTmpPercent(value)
    setPercent(parseInt(value))
  }

  return (
    <FormControl sx={{ m: 0, width: 100, mr: 1, display: 'flex' }} size='small'>
      <InputLabel id='percent-types-label'>Percent</InputLabel>
      <Select
        labelId='percent-types-label'
        id='percent-types'
        value={tmpPercent}
        label='Percent Type'
        onChange={handleChange}
      >
        {allowedPercentages.map((percent) => (
          <MenuItem
            key={percent}
            value={percent}
            style={getStyles(percent, tmpPercent, theme, refundName)}
          >
            {percent} %
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default PercentOptions
