import { Address } from './Address'

export class Customer {
  public _name: string = ''
  public _address: string = ''
  public _phone: string = ''
  public _email: string = ''
  public _customer: any = null
  public _billingAddress: any = Address
  public _shippingAddress: any = Address

  get name(): string {
    return this._name
  }

  set name(newName: string) {
    this._name = newName
  }

  get phone(): string {
    return this._phone
  }

  set phone(newPhone: string) {
    this._phone = newPhone
  }

  set email(newEmail: string) {
    this._email = newEmail
  }

  get email(): string {
    return this._email
  }

  set address(newAddress: string) {
    this._address = newAddress
  }

  get address(): string {
    return this._address
  }

  set customer(newCustomer: any) {
    this._customer = newCustomer
  }

  get customer(): any {
    return this._customer
  }

  set billingAddress(newBillingAddress: any) {
    this._billingAddress = newBillingAddress
  }

  get billingAddress(): any {
    return this._billingAddress
  }
  set shippingAddress(newShippingAddress: any) {
    this._shippingAddress = newShippingAddress
  }

  get shippingAddress(): any {
    return this._shippingAddress
  }
}

type CustomerDict = {
  name: string
  address1: string
  city: string
  country: string
  province: string
  province_code: string
  country_code: string
  zip: string
  phone: string
  [key: string]: any // 👈️ variable key
}

export const getCustomerRequestDict = (customer: CustomerDict) => {
  return {
    id: customer._customer.id,
    email: customer._customer.email,
  }
}
