/* eslint-disable react/prop-types */
import React, { useState } from 'react'

import { Button, Divider, Grid, Link, List, ListItem, Typography } from '@mui/material'

import { SalesOrder } from '../../models/SalesOrder'
import { Transaction } from '../../models/Transaction'
import { currencyFormatter, isCreatedOverTwentyFiveMinutes } from '../../utils'

import CancelledOrderModal from './CancelledOrderModal'
import CapturePaymentModal from './CapturePaymentModal'
import PaymentBadge from './PaymentBadge'
import TaxRatesModal from './TaxRatesModal'
import TransactionAccordion from './TransactionAccordion'

export const style = {
  p: 0,
  width: '100%',
  backgroundColor: 'background.paper',
}

type PaymentCardProps = {
  order: SalesOrder
  customer: any
}

const PaymentCard = ({ order, customer }: PaymentCardProps) => {
  const [isTaxRatesModalVisible, setTaxRatesModalVisibility] = useState<boolean>(false)
  const [isCapturedPaymentModalVisibility, setCapturedPaymentModalVisibility] =
    useState<boolean>(false)
  const [isCancelledOrderModalVisibility, setCancelledOrderModalVisibility] =
    useState<boolean>(false)
  const handleTaxRatesModalVisibility = () => {
    setTaxRatesModalVisibility(!isTaxRatesModalVisible)
  }

  const convertGramsToPounds = (weight: number) => {
    return parseFloat((weight * 0.00220462).toFixed(2))
  }

  const convertStringToDate = (date: string, timeZone: string) => {
    return new Date(date)
      .toLocaleString('en-US', {
        timeZone: timeZone,
        year: 'numeric',
        month: 'long',
        day: '2-digit',
      })
      .toString()
  }

  const TotalLineItemPrice = ({
    text1,
    text2,
    text3,
    transaction,
    xs = 7,
    fontWeight = 400,
  }: any) => {
    return (
      <ListItem>
        <Grid item xs={3}>
          <Typography sx={{ fontWeight: fontWeight }}>{text1}</Typography>
        </Grid>
        <Grid item xs={xs}>
          {transaction ? (
            <TransactionAccordion transaction={transaction} text2={text2} />
          ) : (
            <Typography sx={{ fontWeight: fontWeight }}>{text2}</Typography>
          )}
        </Grid>
        <Grid item xs={2} justifyContent='flex-end'>
          <Typography
            textAlign='right'
            sx={{
              fontWeight: fontWeight,
              color:
                transaction &&
                transaction.kind.toLocaleLowerCase() === 'refund' &&
                transaction.status.toLocaleLowerCase() !== 'success'
                  ? 'red'
                  : '',
            }}
          >
            {text3}
          </Typography>
        </Grid>
      </ListItem>
    )
  }

  const subtotalDetails = () => {
    return order._currentSubtotalLineItemsQuantity > 1
      ? `${order._currentSubtotalLineItemsQuantity} items`
      : `${order._currentSubtotalLineItemsQuantity} item`
  }

  const shippingDetails = () => {
    return order._shippingMethod + ' (' + convertGramsToPounds(order._totalWeight) + ' lb' + ')'
  }

  const paidDetails = () => {
    return order._financialStatus === 'AUTHORIZED'
      ? formattedPrice(order._totalOutstandingSet)
      : order._financialStatus === 'PENDING'
      ? formattedPrice('0.00')
      : formattedPrice(order._totalPriceSet)
  }

  const formattedPrice = (price: string) => {
    return order._currencyCode !== 'USD'
      ? currencyFormatter(customer, order).format(parseFloat(price)) + ' ' + order._currencyCode
      : currencyFormatter(customer, order).format(parseFloat(price))
  }

  const totalDiscount = () => {
    return '-' + formattedPrice(order._currentTotalDiscountsSet)
  }

  const subTotalPrice = () => {
    return (
      parseFloat(order._currentSubtotalPriceSet) + parseFloat(order._currentTotalDiscountsSet)
    ).toString()
  }

  const handleCapturePaymentModalVisibility = () => {
    setCapturedPaymentModalVisibility(!isCapturedPaymentModalVisibility)
  }

  const handleCancelledOrderModalVisibility = () => {
    setCancelledOrderModalVisibility(!isCancelledOrderModalVisibility)
  }

  const ExtraDate = ({ order }: any) => {
    let tmpText = ''
    order._transactions.map((transaction: Transaction) => {
      if (transaction.kind === 'AUTHORIZATION') {
        tmpText =
          '- Expires on ' +
          convertStringToDate(transaction.authorizationExpiresAt, 'America/Los_Angeles')
      }
    })
    return (
      <Grid item>
        <Typography variant='caption' paddingLeft={'4px'}>
          {tmpText}
        </Typography>
      </Grid>
    )
  }

  return (
    <Grid>
      <CapturePaymentModal
        isShown={isCapturedPaymentModalVisibility}
        onClose={handleCapturePaymentModalVisibility}
        order={order}
        customer={customer}
      />
      <CancelledOrderModal
        isShown={isCancelledOrderModalVisibility}
        onClose={handleCancelledOrderModalVisibility}
        order={order}
      />
      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='flex-start'
        sx={{ padding: '16px' }}
      >
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='flex-start'
          sx={{ paddingLeft: '16px' }}
        >
          <Grid item xs={12}>
            <Grid container direction='row' justifyContent='flex-start'>
              <PaymentBadge financialStatus={order._financialStatus} />
              <ExtraDate order={order} />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='flex-start'
          spacing={2}
          sx={{
            margin: '15px auto',
            padding: 'auto',
            maxWidth: 1400,
            flexGrow: 1,
          }}
        >
          <List sx={style}>
            {order._financialStatus === 'PARTIALLY_REFUNDED' ||
            order._financialStatus === 'REFUNDED' ? (
              <>
                <TotalLineItemPrice
                  text1={'Orginal order'}
                  text2={convertStringToDate(order._createdAt, 'America/Los_Angeles')}
                  text3={formattedPrice(order._totalPriceSet)}
                />
                <Divider component='li' />
              </>
            ) : null}
            <TotalLineItemPrice
              text1={'Subtotal'}
              text2={subtotalDetails()}
              text3={formattedPrice(subTotalPrice())}
            />
            {order._currentTotalDiscountsSet !== '0.0' && (
              <TotalLineItemPrice
                text1={'Discount'}
                text2={order._discountCode}
                text3={totalDiscount()}
              />
            )}
            <TotalLineItemPrice
              text1={'Shipping'}
              text2={shippingDetails()}
              text3={formattedPrice(order._totalShippingPriceSet)}
            />
            <TotalLineItemPrice
              text1={'Tax'}
              text2={<Link onClick={handleTaxRatesModalVisibility}>Show tax rates</Link>}
              text3={formattedPrice(order._currentTotalTaxSet)}
            />
            <TotalLineItemPrice
              text1={'Total'}
              text3={formattedPrice(order._currentTotalPriceSet)}
              fontWeight={700}
            />
            <Divider component='li' />
            {order._transactions.length > 0 ? (
              <>
                {order._transactions
                  .filter((transaction: Transaction) => {
                    return transaction.kind !== 'REFUND'
                  })
                  .map((transaction: Transaction) => (
                    <TotalLineItemPrice
                      key={order._transactions.indexOf(transaction)}
                      text1={order._transactions.indexOf(transaction) === 0 && 'Paid'}
                      transaction={transaction}
                      text2={
                        transaction.paymentMethod
                          ? transaction.paymentMethod
                          : transaction.formattedGateway
                      }
                      text3={transaction.amount}
                      xs={8}
                    />
                  ))}
              </>
            ) : (
              <TotalLineItemPrice
                text1={'Paid'}
                text2={order._paymentMethod}
                text3={paidDetails()}
              />
            )}
            {order._financialStatus === 'PARTIALLY_REFUNDED' ||
            order._financialStatus === 'REFUNDED' ? (
              <>
                {order._transactions
                  .filter((transaction: Transaction) => {
                    return transaction.kind === 'REFUND'
                  })
                  .map((transaction: Transaction, index: number) => (
                    <TotalLineItemPrice
                      key={order._transactions.indexOf(transaction)}
                      text1={index === 0 && 'Refunded'}
                      transaction={transaction}
                      text2={`Reason: ${transaction.note ? transaction.note : '—'}`}
                      text3={transaction.amount}
                      xs={8}
                      maxHeight={'20px'}
                    />
                  ))}
                <TotalLineItemPrice
                  text1={<strong>Net payment</strong>}
                  text3={<strong>{formattedPrice(order._netPaymentSet)}</strong>}
                />
              </>
            ) : null}
          </List>
          <Grid container direction='row' justifyContent='flex-end' alignItems='flex-end'>
            {order._fulfillmentStatus !== 'FULFILLED' &&
              !order._cancelledAt &&
              !isCreatedOverTwentyFiveMinutes(order._createdAt) && (
                <Grid item paddingLeft={1}>
                  <Button
                    type='submit'
                    color='primary'
                    size='large'
                    variant='contained'
                    onClick={handleCancelledOrderModalVisibility}
                  >
                    Cancel order
                  </Button>
                </Grid>
              )}
            {order._financialStatus === 'AUTHORIZED' && (
              <Grid item paddingLeft={1}>
                <Button
                  type='submit'
                  color='primary'
                  size='large'
                  variant='contained'
                  onClick={handleCapturePaymentModalVisibility}
                >
                  Capture Payment
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        <TaxRatesModal
          isShown={isTaxRatesModalVisible}
          onClose={handleTaxRatesModalVisibility}
          order={order}
          customer={customer}
        />
      </Grid>
    </Grid>
  )
}

export default PaymentCard
